import axios from "axios";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import auth from "./auth";
import { logOut, softLogout, getReduxState } from "../actions/auth";
import { triggerUxCritialErrors } from "../actions/error";
import storage from "../helpers/storage";
import emailUtil from "../helpers/email/emailUtil";
import { getPartImageURLCookie } from "../components/User/UserManagement/usermanagement.thunk";
import { setImageURLCookie, isImageURLCookieValid, closeMitchelApp } from "../helpers/utils";
import Swal from "sweetalert2";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-company-name": `${process.env.REACT_APP_COMPANY_NAME}`,
    "x-device": `${process.env.REACT_APP_DEVICE}`,
    "x-rapid-version": `${process.env.REACT_APP_RAPID_APP_VERSION}`,
  },
});
instance.interceptors.request.use(
  function (config) {
    let token = storage.get("authToken", "local");
    let pwd = `x^pD&2MdXk^aAYPB`; 
    if (process.env.REACT_APP_ENV === "UAT") {
      pwd = `ZE5uET?8mdDFpy&a`;
    }
    if (process.env.REACT_APP_ENV === "DEV") {
      pwd = `=aG3srF&vy8&?p=Z`;
    }
    const userCredentials = `sms_web_admin:${pwd}`;
    if (_isEmpty(config.headers.Authorization)) {
      if (!_isEmpty(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (config.data) {
      config.dispatch = config.data.dispatch ? config.data.dispatch : null;
      config.data = config.data.dispatch
        ? _omit(config.data, ["dispatch"])
        : config.data;
        if(config.data && config.data.authType && config.data.authType === 'basic') {
          config.headers.Authorization = 'Basic ' + window.btoa(userCredentials);
          config.data = _omit(config.data, ["authType"])
        }
    }

    if (config.params) {
      config.dispatch = config.params.dispatch ? config.params.dispatch : null;
      config.params = config.params.dispatch
        ? _omit(config.params, ["dispatch"])
        : config.params;
        if(config.params.isXAPIKeyAuth) {
          config.headers['x-api-key'] = `${process.env.REACT_APP_X_API_KEY}`;
          config.params = config.params.isXAPIKeyAuth ? _omit(config.params, [ 'isXAPIKeyAuth' ]) : config.params;
        }
    }

    if (
      config.dispatch &&
      !isImageURLCookieValid() &&
      config.headers.Authorization
    ) {
      config.dispatch(getPartImageURLCookie()).then((resp) => {
        setImageURLCookie(resp.payload);
      });
    }
    // This is for Forbidden FIX
    // const state = config.dispatch(getReduxState());
    // if (!state.app.userAuthenticated) {
    // throw new axios.Cancel("User cancelled the request");
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async function (response) {
    if (response.data.errorCode && response.data.errorMessage) {
      const { config, data, status } = response;
      const temp = typeof auth.isAuthFailed;
      if (config.dispatch && !auth.isAuthFailed) {
          if (status !== 401) {
            emailUtil.sendEmail(data, status, config);
          }
          if (!_isEmpty(auth.smsUserSessionData)) {
            if (status === 401 || status === 403) {
              let customMsg = `Session is expired. Please link the RAPID catalog once again.`;
              const payload = {
                body: JSON.stringify(auth.smsUserSessionData),
                fromName: "The Parts House",
                subject: `Mitchel1 Session Expired`,
              };
              emailUtil.sendCustomEmail(payload, config);
              Swal.fire({
                html: `<b>${customMsg}</b>`,
                confirmButtonColor: '#0f5283',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  closeMitchelApp();
                }
              });
            }
          } else {
          triggerUxCritialErrors(data, config.dispatch);
      }
    }
    }
    if (response.data.errorCode && response.data.errorMsg) {
      if (response.config.dispatch) {
        if (response.data.errorCode === "E9999") {
          await softLogout(response.config.dispatch);
          await logOut(response.config.dispatch);
          storage.set("authFailed", true, "local");
          window.location.reload();
        }
      }
    }
    return response.data;
  },
  function (error) {
    if (error.response) {
      const { config, data, status } = error.response;
      const temp = typeof auth.isAuthFailed;
      if (config.dispatch && !auth.isAuthFailed) {
        if (status !== 401) {
          emailUtil.sendEmail(data.errors || data, status, config);
        }
        if (!_isEmpty(auth.smsUserSessionData)) {
          if (status === 401 || status === 403) {
            let customMsg = `Session is expired. Please link the RAPID catalog once again.`;
            const payload = {
              body: JSON.stringify(auth.smsUserSessionData),
              fromName: "The Parts House",
              subject: `Mitchel1 Session Expired`,
            };
            emailUtil.sendCustomEmail(payload, config);
            Swal.fire({
              html: `<b>${customMsg}</b>`,
              confirmButtonColor: '#0f5283',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                closeMitchelApp();
              }
            });
          }
        } else {
        triggerUxCritialErrors(data.errors || data || error, config.dispatch);
        }
      }
      if (status === 401 || status === 403) {
        softLogout(config.dispatch);
        storage.set("authFailed", true, "local");
        // return null;
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);
export default instance;
