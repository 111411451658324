import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

interface SearchInputProps {
  label: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search: () => void;
  validation: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  value,
  onChange,
  search,
  validation,
}) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") search();
  };

  return (
    <TextField
      variant="outlined"
      type="text"
      label={label}
      value={value}
      size="small"
      onKeyDown={handleKeyPress}
      error={!!validation}
      helperText={validation}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              size="small"
              disabled={value ? false : true}
            >
              <SearchIcon onClick={search} fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          fontSize: "1rem", // Adjust the font size for the input
          padding: "4px 8px", // Adjust padding
        },
      }}
      sx={{
        width: "250px", // Adjust the width as needed
        "& .MuiOutlinedInput-root": {
          fontSize: "0.75rem", // Adjust the font size
          padding: "1px 8px", // Adjust padding
          border: "1px solid #efefef",
        },
        "& .MuiInputLabel-root": {
          fontSize: "0.75rem", // Adjust the font size of the label
        },
        "& .MuiSvgIcon-root": {
          fontSize: "1rem", // Adjust the size of the icons
        },
      }}
    />
  );
};

export default SearchInput;
