"use client";
import React, { useEffect } from "react";
import { useState } from "react";
import Video from "./Video";
import ChapterList from "./ChapterList";
import SearchInput from "./SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { getChapters, getSearchChapters } from "./training.thunks";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SearchData from "./SearchData";
import Tooltip from "@mui/material/Tooltip";
import NavbarIconUI from "./NavbarIconUI";
import { createNestedStructure } from "../Helpers/Utils";
import storage from "helpers/storage";

const Training = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const userType = user && user.userDetails && user.userDetails?.userType;
  const [chaptersData, setChaptersData] = useState({});
  const [searchChaptersData, setSearchChaptersData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [validation, setValidation] = useState("");
  const [dataState, setDataState] = useState({
    selectedChapter: [],
    selectedVideo: {},
    menuShowStatus: true,
    showSearch: false,
    searchKey: "",
    searchedString: "",
    page: 1,
    apiCalled: false,
    autoPlay: false,
  });
  const changeDataState = (d: any) => setDataState((p) => ({ ...p, ...d }));

  const searchValidation = (v: string) => {
    const trimmedValue = v.replace(/^\s+/, "");
    return trimmedValue;
  };

  const getParentNames = (node: any) => {
    const names = [];
    while (node) {
      names.unshift(node.ContentTitle);
      node = node.parent;
    }
    return names;
  };

  const findDeepestChild = (node: any) => {
    while (node.children && node.children.length > 0) {
      node = node.children[0];
    }
    return node;
  };

  const findAllDeepestChildren = (node: any, deepestChildren: any[] = []) => {
    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) =>
        findAllDeepestChildren(child, deepestChildren)
      );
    } else {
      deepestChildren.push(node);
    }
  };

  const initializeChapterList = () => {
    let newUser = JSON.parse(storage.get("newUser", "local"));
    if (userType) {
      const payload = { userType: userType };
      try {
        const allChaptersAction = dispatch(getChapters(payload));
        allChaptersAction.then((action: any) => {
          let values = action?.payload?.content || [];
          let res = createNestedStructure(values);
          setChaptersData(res);
          const deepestChild =
            res?.children?.length > 0
              ? findDeepestChild(
                  newUser == true
                    ? res?.children?.find((v) => v?.ContentTitle == "New User")
                    : res?.children?.[0]
                )
              : {};
          changeDataState({
            selectedChapter: getParentNames(deepestChild),
            selectedVideo: deepestChild,
            apiCalled: true,
          });
          storage.set("newUser", false, "local");
        });
      } catch (error) {}
    }
  };

  const handleSearchData = () => {
    {
      dataState.searchKey && changeDataState({ showSearch: true });
    }

    getSearchChapterList();
  };

  const getSearchChapterList = () => {
    if (
      dataState?.searchKey &&
      dataState?.searchKey == dataState?.searchedString &&
      dataState?.showSearch
    )
      changeDataState({ showSearch: true });
    if (userType && dataState?.searchKey) {
      const payload = {
        userType: userType,
        searchKey: dataState?.searchKey.trim(),
        page: dataState?.page,
        size: 10,
      };
      try {
        const allSeaechChaptersAction = dispatch(getSearchChapters(payload));
        allSeaechChaptersAction.then((action: any) => {
          let values = action?.payload?.content || [];
          let data = action?.payload || [];
          setPaginationData(data);
          let res = createNestedStructure(values);
          const deepestChildrenList: any[] | undefined = [];
          if (values?.length > 0)
            findAllDeepestChildren(res, deepestChildrenList);
          setSearchChaptersData(deepestChildrenList);
          changeDataState({
            showSearch: true,
            searchedString: dataState?.searchKey,
          });
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getSearchChapterList();
  }, [dataState?.page]);
  useEffect(() => {
    initializeChapterList();
  }, [userType]);

  // Handle keydown event
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Tab") {
        event.preventDefault(); // Prevent the default tab behavior (moving focus)
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % searchChaptersData.length
        );
      } else if (event.key === "Enter") {
        const selectedVideo = searchChaptersData[currentIndex];
        if (selectedVideo) {
          changeDataState({
            selectedChapter: getParentNames(selectedVideo),
            selectedVideo: selectedVideo,
            showSearch: false,
            autoPlay: true,
          });
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, searchChaptersData, changeDataState]);

  const handleSearchInputChange = (e: any) => {
    const inputChange = e.target.value;

    if (inputChange.length === 0) {
      setValidation("Search input cannot be empty.");
      changeDataState({ searchKey: "" });
    } else if (inputChange.length > 75) {
      setValidation("Maximum 75 characters.");
    } else {
      setValidation("");
      changeDataState({ searchKey: searchValidation(inputChange), page: 1 });
    }
  };

  return (
    <div id="tw">
      <div className="flex flex-col bg-white" style={{ height: "84vh" }}>
        {dataState?.showSearch ? (
          <>
            <div className="flex gap-2 items-center px-4 py-2">
              <div className="flex gap-4 items-center lg:w-1/5 cursor-pointer mt-3">
                <Tooltip title="Go Back">
                  <KeyboardBackspaceIcon
                    className="text-primaryTheme"
                    fontSize="large"
                    // style={{ color:  }}
                    onClick={() =>
                      changeDataState({ showSearch: false, searchKey: "" })
                    }
                  />
                </Tooltip>
                <SearchInput
                  label="Search Videos"
                  changeDataState={changeDataState}
                  value={dataState?.searchKey}
                  onChange={handleSearchInputChange}
                  validation={validation}
                  search={handleSearchData}
                />
              </div>
            </div>
            <SearchData
              searchResult={searchChaptersData}
              changeDataState={changeDataState}
              getParentNames={getParentNames}
              dataState={dataState}
              paginationData={paginationData}
              currentIndex={currentIndex}
              loading={loading}
            />
          </>
        ) : (
          <div className="flex border h-full">
            <div
              className={`transition-all duration-300 ease-in-out ${
                dataState?.menuShowStatus
                  ? "sm:w-full lg:w-1/5   border pt-3"
                  : "w-0 overflow-hidden"
              }`}
            >
              {dataState?.menuShowStatus && (
                <div className="flex flex-col h-full ">
                  <div className="flex gap-4 items-center w-full cursor-pointer px-2 ">
                    <NavbarIconUI
                      changeDataState={changeDataState}
                      dataState={dataState}
                    />
                    <SearchInput
                      label="Search Videos"
                      changeDataState={changeDataState}
                      value={dataState?.searchKey}
                      onChange={handleSearchInputChange}
                      validation={validation}
                      search={handleSearchData}
                    />
                  </div>
                  <div className="overflow-y-auto scroll-smooth scrollbar-custom border mt-2 h-full">
                    <div className="flex flex-col gap-1 py-2 h-full">
                      {Object.keys(chaptersData).length > 0 && (
                        <ChapterList
                          data={chaptersData}
                          dataState={dataState}
                          changeDataState={changeDataState}
                          getParentNames={getParentNames}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`flex-1 overflow-y-auto scroll-smooth scrollbar-custom h-full transition-all duration-300 ease-in-out ${
                dataState?.menuShowStatus ? "w-full lg:w-4/5" : "w-full"
              }`}
              style={{ scrollBehavior: "smooth" }}
            >
              <div className="flex">
                {!dataState?.menuShowStatus && (
                  <div
                    className={`flex border py-6  cursor-pointer transition-all duration-300 ease-in-out ${
                      dataState?.menuShowStatus ? "w-0" : ""
                    }`}
                  >
                    <div className=" px-2 py-1">
                      <NavbarIconUI
                        changeDataState={changeDataState}
                        dataState={dataState}
                      />
                    </div>
                  </div>
                )}
                <div className="w-full">
                  {Object.keys(chaptersData)?.length > 0 ? (
                    <>
                      {Object.keys(dataState?.selectedVideo)?.length > 0 && (
                        <Video
                          dataState={dataState}
                          changeDataState={changeDataState}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {dataState?.apiCalled && (
                        <h4 className="p-4 normal-case flex items-end justify-center gap-2">
                          <Tooltip title="There are no Video's found">
                            <WarningAmberIcon
                              className="h-4 w-4"
                              fontSize="medium"
                              onClick={() =>
                                changeDataState({ showSearch: false })
                              }
                            />
                          </Tooltip>
                          <div>There are no Videos found</div>
                        </h4>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Training;
