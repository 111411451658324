import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { getAllChapters, getAllSearchChapters, getAllSignUpChapters, getPartImageCookieWithoutSignIn } from "./training.service";

export const getChapters = createAsyncThunk("getChapters",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => source.cancel());
    const response = await getAllChapters(payload, { cancelToken: source.token });
    return response.data;
  }
);

export const getSearchChapters = createAsyncThunk("getSearchChapters",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => source.cancel());
    const response = await getAllSearchChapters(payload, { cancelToken: source.token });
    return response.data;
  }
);


export const getSignUpChapters = createAsyncThunk("getSearchChapters",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => source.cancel());
    const response = await getAllSignUpChapters(payload, { cancelToken: source.token });
    return response.data;
  }
);


export const getImageURLCookieWithoutSignIn = createAsyncThunk(
  "getPartImageURLCookie",
  async (payload) => {
    const response = await getPartImageCookieWithoutSignIn(payload);
    return response.data;
  }
);

