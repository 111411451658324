import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import storage from '../helpers/storage';

import HomePage from './Homepage/Homepage';
import ListingWrapper from './Listing';
import Login from './Login';
import IFrame from './IFrame';
import OnlineIdRequestForm from './User/OnlineIdRequestForm';
import VerifyEmail from './Login/VerifyEmail';
import EmailVerified from './Login/EmailVerified';
import app from '../reducers/app';
import ShoppingCart from './ShoppingCart/index';
import AddNewUser from './User/Admin/AddNewUser';
import ChemicalsAndSupplies from './ChemicalsAndSupplies';
import ChemicalsSuppliesListingWrapper from './ChemicalsAndSupplies/Listing';
import OrderDeliveryStatus from './OrderDeliveryStatus';
import StockCheck from './StockCheck';
import UserManagement from './User/UserManagement';
import TestSMS from './User/SMS';
import TestSMSIframe from './SMSIframe';
import TimedoutTransactions from './Admin/TimedoutTransactions';
import ProPacksListing from './Listing/ProPacks';
import CustomJob from './Listing/CustomJob';
import Interchange from './Interchange';
import BuyersGuide from './BuyersGuide';
import JobCreation from './shared/MyGarage/Jobs/Create';
import Report from './shared/MyGarage/Reports/Report';
import TestDebug from './TestDebug';
// import YmmeTest from "./YmmeTest";
import Statements from './shared/MyGarage/Statements';
import PaymentHistory from './shared/MyGarage/Payment';
import DriverManagement from './DriverManagement';
import SessionExpiry from './shared/SessionExpiry';
import CartOrdersDetails from './ShoppingCart/CartOrdersDetails';
import StoreDashboard from './StoreDashboard';
import StoreManagement from './StoreDashboard/storeProfile/StoreManagement';
import OnlineIdRequestMitchell from './User/OnlineIdRequestMitchell';
import CreateReturn from './Returns';
import Checkout from './Checkout/index';
import Payments from './Payments';
import Manager from './StoreDashboard/Manager';
import PaymentsPrint from './PaymentsPrint';
import ProgramAndBenefits from './ProgramAndBenefits';
import StockOrder from './StockOrder';
import PreviewROP from './StockOrder/previewROP';
import CK_PartSearch from "pages/c_and_k";
import PreviousLookUps from "pages/c_and_k/previousLookUp";
import CK_CheckOut from "pages/c_and_k/Checkout";
import AccountDynamicIFramePage from "../components/shared/Header/AccountChangeIframe";
import Training from "./RapidNext/Training/Training";
import SignUpTraining from "./RapidNext/SignUpTrainingVideo/SignUpTraining";

const Router = (props) => {
	return (
		<Switch>
			{/* <Route exact path='/public' component={Public}/>
        <Route path="/protected" component={Protected} /> */}
			<PrivateRoute exact path="/" component={HomePage} />
			<PrivateRoute exact path="/YMMEOnly" component={HomePage} />
			<PrivateRoute exact path="/product-list" component={ListingWrapper} />
			{/* <PrivateRoute exact path="/shopping-cart" component={ShoppingCart} /> */}
			<PrivateRoute exact path="/checkout" component={Checkout} />
			<PrivateRoute exact path="/supplies-catalog" component={ChemicalsAndSupplies} />
			<PrivateRoute exact path="/supplies-list" component={ChemicalsSuppliesListingWrapper} />
			<PrivateRoute exact path="/cart-order-details/:acxNum" component={CartOrdersDetails} />
			<PrivateRoute exact path="/stock-check" component={StockCheck} />
			<PrivateRoute exact path="/buyersguide" component={BuyersGuide} />
			<PrivateRoute exact path="/training" component={Training} />
			<Route exact path="/signup-training"  component={SignUpTraining} />
			<PrivateRoute exact path="/user-management" component={UserManagement} />
			<PrivateRoute exact path="/create-return" component={CreateReturn} />
			<Route exact path="/online-sms-req-form" component={OnlineIdRequestMitchell} />
			{process.env.REACT_APP_ENV === 'DEV' ? <PrivateRoute exact path="/test-sms" component={TestSMS} /> : ''}
			<PrivateRoute exact path="/timedout-txns" component={TimedoutTransactions} />
			<PrivateRoute exact path="/sessionExpiry" component={SessionExpiry} />
			<PrivateRoute exact path="/job-create" component={JobCreation} />
			<PrivateRoute exact path="/job-view" component={CustomJob} />
			<PrivateRoute exact path="/propacks-list" component={ProPacksListing} />
			<PrivateRoute exact path="/interchange" component={Interchange} />
			<PrivateRoute exact path="/statements" component={Statements} />
			<PrivateRoute exact path="/payments" component={Payments} />
			<PrivateRoute exact path="/payments-print" component={PaymentsPrint} noHeader={true} />
      <PrivateRoute exact path="/preview-rop" component={PreviewROP} noHeader={true} />
			<PrivateRoute exact path="/payment-history" component={PaymentHistory} />
			{/* <PrivateRoute exact path="/ymmeTest" component={YmmeDropDowns} /> */}
			<Route exact path="/test-sms-iframe" component={TestSMSIframe} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/smsLogin" render={(props) => <IFrame {...props} />} />
			{process.env.REACT_APP_ENV != 'UAT' && process.env.REACT_APP_ENV != 'DEV' ? (
				<Route exact path="/online-id-req-form" component={OnlineIdRequestForm} />
			) : (
				''
			)}
			<Route exact path="/verify-email" component={VerifyEmail} />
			<Route exact path="/email-verified" component={EmailVerified} />
			<Route exact path="/order-delivery-status/:orderID" component={OrderDeliveryStatus} />
			<Route exact path="/tracking-order" render={(props) => <OrderDeliveryStatus {...props} />} />
			<Route exact path="/report/:reportID" component={Report} />
			<Route exact path="/test" component={TestDebug} />
			<PrivateRoute exact path="/driver-management" component={DriverManagement} />
			<PrivateRoute exact path="/store-user-dashboard" component={StoreDashboard} />
			<PrivateRoute exact path="/manager-dashboard" component={Manager} />
			<PrivateRoute exact path="/store-management" component={StoreManagement} />
			<PrivateRoute exact path="/program-benefits" component={ProgramAndBenefits} />
			<PrivateRoute exact path="/program-benefits/:benifitname" component={ProgramAndBenefits} />
			<PrivateRoute exact path="/stock-order" component={StockOrder} />
			<PrivateRoute exact path="/ck_partsearch" component={CK_PartSearch} />
			<PrivateRoute exact path="/ck_previous-look-ups" component={PreviousLookUps}/>
			<PrivateRoute exact path="/ck_partsearch/ck_checkout" component={CK_CheckOut}/>
			<PrivateRoute path="/:url" component={AccountDynamicIFramePage} />
			{/* <PrivateRoute exact path="/test-gfx" component = {TestGfx}  /> */}
		</Switch>
	);
};
const PrivateRoute = ({ component: Component, ...rest }) => {
	let userToken = storage.get('authToken', 'local');
	return (
		<Route
			{...rest}
			render={(props) =>
				userToken || props.userAuthenticated ? (
					// true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login'
						}}
					/>
				)}
		/>
	);
};
function mapStateToProps(state) {
	return {
		userAuthenticated: state.app.userAuthenticated
	};
}
export default connect(mapStateToProps)(Router);
