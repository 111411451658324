import { getImageURLCookieDetails } from "helpers/utils";
import React, { useEffect, useRef } from "react";
import xlLogo from "../../../static/images/cd_xlp_logo.png";
import LaunchIcon from "@mui/icons-material/Launch";
import Tooltip from "@mui/material/Tooltip";
import { openInNewTab } from "../Helpers/Utils";

interface InputProps {
  dataState?: any;
  changeDataState: (newState: Partial<any>) => void;
}

const Video: React.FC<InputProps> = ({ dataState }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) videoRef.current.load();
  }, [dataState?.selectedVideo]);

  return (
    <div className="flex flex-col p-3">
      <div className="flex" style={{ height: "73vh" }}>
        <video
          autoPlay={dataState?.autoPlay}
          ref={videoRef}
          controls
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
          poster={`${
            dataState?.selectedVideo?.Thumbnail?.path
          }?${getImageURLCookieDetails()}`}
        >
          <source
            src={`${
              dataState?.selectedVideo?.VideoLink?.path
            }?${getImageURLCookieDetails()}`}
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="p-2 flex-col gap-3">
        <div className="font-bold flex gap-2">
          <div>{dataState?.selectedVideo?.ContentTitle}</div>
          <div
            className="cursor-pointer"
            onClick={() =>
              openInNewTab(
                `${
                  dataState?.selectedVideo?.VideoLink?.path
                }?${getImageURLCookieDetails()}`
              )
            }
          >
            <Tooltip title={`Open in new window`}>
              <LaunchIcon className="h-4 w-4" />
            </Tooltip>
          </div>
        </div>

        <div>
          <div className="font-medium flex gap-2">
            {dataState?.selectedVideo?.Notes?.text && (
              <span>
                <b>Notes:</b> {dataState?.selectedVideo?.Notes?.text}
              </span>
            )}
            {dataState?.selectedVideo?.Notes?.path && (
              <a
                className=" cursor-pointer"
                onClick={() =>
                  openInNewTab(
                    `${
                      dataState?.selectedVideo?.Notes?.path
                    }?${getImageURLCookieDetails()}`
                  )
                }
              >
                Learn More...
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
