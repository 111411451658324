import React from "react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

interface InputProps {
  dataState?: any;
  data?: any;
  changeDataState: (newState: Partial<any>) => void;
  getParentNames: (node: Partial<any>) => void;
}
const ChapterList: React.FC<InputProps> = ({
  dataState,
  data,
  changeDataState,
  getParentNames,
}) => {
  const changeSelectedChapter = (e: any) => {
    let condition = dataState?.selectedChapter?.includes(e?.ContentTitle);
    let res = condition
      ? dataState?.selectedChapter?.filter((v: any) => v != e?.ContentTitle)
      : getParentNames(e);
    changeDataState({ selectedChapter: res });
  };

  const returnUI = (children: any) => {
    return (
      <div className="flex flex-col">
        {children?.map((e: any, i: number) => (
          <div key={i}>
            {e?.children?.length > 0 ? (
              <>
                <div
                  className="cursor-pointer pl-2 py-1"
                  onClick={() =>
                    // changeDataState({ selectedChapter: getParentNames(e) })
                    changeSelectedChapter(e)
                  }
                >
                  <FolderOpenIcon />
                  <span className="font-bold">{e?.ContentTitle}</span>
                </div>
                <div
                  className={`pl-3 transition-max-height duration-500 ease-in-out overflow-hidden ${
                    dataState?.selectedChapter?.some(
                      (v: any) => v == e?.ContentTitle
                    )
                      ? "max-h-screen" // Fully expanded
                      : "max-h-0" // Collapsed
                  }`}
                >
                  {returnUI(e?.children)}
                </div>
              </>
            ) : (
              <div
                className={`pl-2 flex gap-2 text-wrap cursor-pointer py-1 
                  ${
                    dataState?.selectedVideo?.ContentTitle == e?.ContentTitle
                      ? " bg-secondaryTheme border-l-4 border-primaryTheme"
                      : "border-l-4 border-white"
                    }`}
                    style={{
                      borderLeft: dataState?.selectedVideo?.ContentTitle == e?.ContentTitle ? "solid 4px #0f5283" : "solid 4px #ffffff",
                    }}
                onClick={() =>
                  changeDataState({ selectedVideo: e, autoPlay: false })
                }
              >
                <div>
                  <OndemandVideoIcon
                    color={
                      dataState?.selectedVideo?.ContentTitle == e?.ContentTitle
                        ? "primary"
                        : "black"
                    }
                  />
                </div>
                {/* {dataState?.selectedVideo?.ContentTitle == e?.ContentTitle ? (
                  <div>
                    <FontAwesomeIcon color="green" icon={faCircleCheck} />
                  </div>
                ) : (
                  <div>
                        <OndemandVideoIcon color={dataState?.selectedVideo?.ContentTitle == e?.ContentTitle ? "primary" : "black"} />
                  </div>
                )} */}
                {e?.ContentTitle}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return <div>{returnUI(data?.children || [])}</div>;
};

export default ChapterList;
