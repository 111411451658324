import { getImageURLCookieDetails, setImageURLCookie } from "helpers/utils";
import React, { useEffect, useRef, useState } from "react";
import { openInNewTab } from "../Helpers/Utils";
import {
  getImageURLCookieWithoutSignIn,
  getSignUpChapters,
} from "../Training/training.thunks";
import { useDispatch } from "react-redux";

const SignUpTraining: React.FC = () => {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [data, setData] = useState({ coockie: false });

  const changeDataState = (d: any) => setData((p) => ({ ...p, ...d }));

  const getVideo = () => {
    const payload = { isSignup: true, isXAPIKeyAuth: true };
    try {
      const allSeaechChaptersAction = dispatch(getSignUpChapters(payload));
      allSeaechChaptersAction.then((action: any) => {
        changeDataState(action?.payload?.content?.[0]);
      });
    } catch (error) {
      console.log(error, "err");
    }
  };

  const getCookie = () => {
    const payload = { isXAPIKeyAuth: true };
    try {
      dispatch(getImageURLCookieWithoutSignIn(payload)).then((resp) => {
        setImageURLCookie(resp.payload);
      });
      changeDataState({ coockie: true });
    } catch (error) {
      console.log(error, "err");
    }
  };

  useEffect(() => {
    data?.coockie && getVideo();
  }, [data?.coockie]);

  useEffect(() => {
    getCookie();
  }, []);

  useEffect(() => {
    if (videoRef.current) videoRef.current.load();
  }, [data]);

  return (
    <div id="tw">
      <div className="flex flex-col p-3">
        <div className="flex" style={{ height: "73vh" }}>
          <video
            ref={videoRef}
            controls
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
            poster={`${data?.Thumbnail?.path}?${getImageURLCookieDetails()}`}
          >
            <source
              src={`${data?.VideoLink?.path}?${getImageURLCookieDetails()}`}
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="p-2 flex-col gap-3">
          <div className="font-bold flex gap-2">
            <div>{data?.ContentTitle}</div>
          </div>

          <div>
            <div className="font-medium flex gap-2">
              {data?.Notes?.text && (
                <span>
                  <b>Notes:</b> {data?.Notes?.text}
                </span>
              )}
              {data?.Notes?.path && (
                <a
                  className="cursor-pointer"
                  onClick={() =>
                    openInNewTab(
                      `${data?.Notes?.path}?${getImageURLCookieDetails()}`
                    )
                  }
                >
                  Learn More...
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpTraining;
