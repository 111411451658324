import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getAllChapters = (data) =>
  httpClient.get(`/utils-service/training/chapters?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getAllSearchChapters = (data) =>
  httpClient.get(`/utils-service/training/search?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getAllSignUpChapters = (data) =>
  httpClient.get(`/utils-service/training/search`, { dispatch: data.dispatch, params: data });

export const getPartImageCookieWithoutSignIn = (data) => httpClient.get(`/user-service/cookie/part-image`,{ dispatch: data.dispatch, params: data });


export default httpClient;
