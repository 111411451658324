/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from "react";
import {
  Accordion,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Spinner
} from "react-bootstrap";
import { isProgrammable, searchWords } from "../../../helpers/constants";
import Highlighter from "react-highlight-words";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import { Container, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import _findIndex from "lodash/findIndex";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import BootstrapTable from "react-bootstrap-table-next";
import ImagesSlider from "../ImagesSlider/index";
import {
  getC2CUrl,
  getBuyersGuideData,
  getBuyersGuideBrandData,
  getLaborGuideData,
  getLaborGroups
} from "../../../actions/parts";
import {
  numbersOnlyRegExp,
  formatTimeAvb,
  formatAmount,
  timeInUTC,
  isInputBlank,
  isValidPositiveNumber,
  isValidAvailableQty,
  distributeAvailability,
  getPreferenceDataFromStorage,
  getImageURLCookieDetails,
  getExtendedTotalPrice,
  getPartPackQty,
  displayQtyChangeMsg,
  hidePrice,
  getShipInfo,
  getDistributionForVehicle,
  distributeAvailability2,
  getSellerInfo,
  isStockOrder,
  enableBlackColorBasedOnStorePref,
  createAvailabilityObject
} from "../../../helpers/utils";
import _find from "lodash/find";
import Interchange from "../../shared/Interchange";
import VectorGfxPopup from "../VectorGfx/index";
import XModal from "../../shared/XModal";
import LaborGuide from "../LaborGuide";
import CoverToCoverPopup from "../CoverToCoverPopup";
import BuyingGuideComponent from "../../shared/BuyingGuide";
import LostSales from "../../ShoppingCart/LostSales";
import linkIcon from "../../../static/images/linkIcon.png";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _sumBy from "lodash/sumBy";
import _isEqual from "lodash/isEqual";
import _uniqBy from "lodash/uniqBy";
import _every from "lodash/every";
import _remove from "lodash/remove";
import { addItemToCart, addItemToCart2 } from "../../../actions/cart";
import { setPartHistory } from "../../shared/MyGarage/mygarage.thunks";
import PricingIcons from "../PricingIcons";
import { getAvailability } from "../../../helpers/availabilityService";
import AvailabilityStoreView from "../../shared/AvailabilityStoreView";
import Warranty from "../../../components/Warranty";

const actionTypes = {
  SELECTED_ADDCART_DATA: "SELECTED_ADDCART_DATA",
  ADDTO_CART_WARNING: "ADDTO_CART_WARNING",
  GLOBAL_LOADER: 'GLOBAL_LOADER',
  CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
};

function TableViewWrapper(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const addItemcartFetching = useSelector((state) => state.cart.addItemcartFetching);
  const mygarage = useSelector((state) => state.mygarage);
  let categoryGroupMp = useSelector((state) => state.parts.categoryGroupMap);
  const cart = useSelector((state) => state.cart);
  const partsAllDetails = useSelector((state) => state.parts.partsAllDetails);
  const candkCheckoutDetails = useSelector((state) =>state.cart.candkCheckoutDetails);
  let [selectedAvailabilityHourss, setselectedAvailabilityHourss] = useState(
    []
  );
  const [orderArray, setOrderArray] = useState([]);
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [partData, setPartData] = useState(null);
  const [rowData, setProductData] = useState({});
  const [showCoverToCover, setshowCoverToCover] = useState(false);
  const [showBuyersGuide, setshowBuyersGuide] = useState(false);
  const [showLaborGuide, setshowLaborGuide] = useState(false);
  const [showInterchange, setshowInterchange] = useState(false);
  const [showVectorGfx, setshowVectorGfx] = useState(false);
  const [showPartDetails, setshowPartDetails] = useState(false);
  const [laborGuideDetails, setLaborGuideDetails] = useState({});
  const [indexValue, setIndex] = useState(null);
  const [showwarrantyDetails,setshowwarrantyDetails] = useState(false);
  const [showLostSales, setshowLostSales] = useState(false);
  const [partsQySum, setpartsQtySum] = useState(0);
  const parts = useSelector((state) => state.parts);
  const [totalOrderQy, settotalOrderQty] = useState(0);
  const [validity, setValidity] = useState({
    doesQtyExist: true,
    isQtyMoreThanOne: true,
    isQtyLessThanAvailable: true,
  });
  const [partTableRows, setPartTableRows] = useState([]);
  const [partTableColumns, setPartTableColumns] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [currentTottalAvailable, setCurrentTotalAvailable] = useState(0);
  const [showMoreAvailability, setShowMoreAvailability] = useState(false);
  const [c2cURL, setC2cURL] = useState(null);
  const imageURLCookie = getImageURLCookieDetails();
  const viewPromotions = useSelector((state) => state.app.viewPromotions);
  const partCatalogFilterSectionEnabled = useSelector(
    (state) => state.parts.partCatalogFilterSectionEnabled
  );
  const [showAvailabilityStoreView, setShowAvailabilityStoreView] = useState(false);
  const [storeData, setStoreData] = useState([]);
  let isMobileEnabled = user?.userDetails?.shipTos[0]?.isMobileEnabled;
  let { rows, rowsMoreDetails, isForCandK } = props;
  const hideAllPrice =  parts  && parts.hidePrice;
  const orderType = useSelector(state => state.cart.orderType);
  const itemLoader = useSelector((state) => state.app.itemLoader);
  const [clickIndex, setClickIndex] = useState('');
  let hideListPrice = hidePrice("Catalog", "listPrice");
  let hideCostPrice = hidePrice("Catalog", "costPrice");
  useEffect(() => {
    constructTablePartData(rows);
  }, [cart]);

  useEffect(() => {
    if (!_isEmpty(partsAllDetails) && !_isEmpty(rows)) {
      let rowsInfo = updateRowsForThumbNail(rows);
      constructTablePartData(rowsInfo);
    }
  }, [partsAllDetails]);

  const updateRowsForThumbNail = (rowsInfo) => {
    let rowsExtended = _cloneDeep(rowsInfo);
    partsAllDetails.map((part) => {
      rowsExtended.map((row) => {
        if (row.part && row.part.description && part && part.thumbnail) {
          if (row.part.description == part.description) {
            row.part.thumbnail = part.thumbnail;
          }
        }
      });
    });
    return rowsExtended;
  };
  const setlaborGuideData = (laborGuideDetail) => {
    setLaborGuideDetails(laborGuideDetail);
  };
  const togglePopup = (popupName, index, showFlag) => {
    let startIndex = 0;
    setIndex(index);
    let details = rows.map((v) => v.rowMoreInfo);
    if (index != undefined || index != null) {
      startIndex = index;
      setPartData(rows[startIndex].part);
      setProductData(details[startIndex]);
    }
    // } else {
    //   startIndex = index;
    //   setPartData(rows[startIndex].part);
    //   setProductData(details[startIndex]);
    // }
    if (showFlag === undefined) {
      showFlag = false;
    }
    let rowData = details[startIndex];
    let partData = rows[startIndex].part;
    // let laborpayload = {
    //   conditions: [],
    // };

    // if (parts.laborGuideConditions) {
    //   laborpayload = parts.laborGuideConditions;
    // }
    const { dispatch } = details[startIndex];

    switch (popupName) {
      case "buyersGuide":
        if (!showBuyersGuide) {
          getBuyersGuideData(partData.partNumber, dispatch, partData.mfgName);
          getBuyersGuideBrandData(partData.partNumber, dispatch);
        }
        getC2CUrl(partData.partNumber, partData.orderNumber, dispatch);
        setshowBuyersGuide(!showBuyersGuide);
        break;
      case "coverToCover":
        getC2CUrl(partData.partNumber, partData.orderNumber, dispatch).then(
          (resp) => {
            setC2cURL(resp.data.slice(5));
          }
        );
        setshowCoverToCover(!showCoverToCover);
        break;
        case "laborGuide":
        //setshowLaborGuide(true);
        if (true) {
          let selectedGroupCategoryInfo = !_isEmpty(rowData.selectedGroupDetails) && _find(rowData.selectedGroupDetails, {
            code: Number(partData.groupNumber),
          });
          let groupData = [];
          if (!_isEmpty(selectedGroupCategoryInfo) && selectedGroupCategoryInfo.categoryId) {
            dispatch({
              payload: {
                globalLoader: true
              },
              type: actionTypes.GLOBAL_LOADER
            });
            getLaborGroups(
              Number(selectedGroupCategoryInfo.categoryId),
              dispatch
            ).then((response) => {
              let { data } = response;
              groupData = _find(data, {
                code: Number(partData.groupNumber),
              });
              if (
                groupData &&
                groupData.laborGroups &&
                groupData.laborGroups.length
              ) {
                let laborItems = [];
                //let conditions = [];
                let laborSpecificConditions = [];
                let laborGroupItemsRecd = 0;
                groupData.laborGroups.forEach((laborGroup, index) => {
                  getLaborGuideData(
                    rowData.selectedVehicle.engineId,
                    groupData.laborGroups[index].textID,
                    rowData.selectedVehicle.makeId,
                    rowData.selectedVehicle.modelId,
                    rowData.selectedVehicle.year,
                    // laborpayload.conditions,
                    dispatch
                  ).then((__response) => {
                    dispatch({
                      payload: {
                        globalLoader: false
                      },
                      type: actionTypes.GLOBAL_LOADER
                    });
                    if (__response && __response.data) {
                      laborGroupItemsRecd++;
                      laborItems.push(...__response.data.laborItems);
                      // conditions.push(...__response.data.conditions);
                      laborSpecificConditions.push(
                        ...__response.data.laborSpecificConditions
                      );



                      console.log(__response.data);
                    }
                    if (__response && __response.data == null) {
                      laborGroupItemsRecd++;
                    }
                    if (laborGroupItemsRecd === groupData.laborGroups.length) {
                      let laborGuideDetail = {
                        laborItems: laborItems,
                        //conditions: conditions,
                        laborSpecificConditions: laborSpecificConditions,
                      };
                      setLaborGuideDetails(laborGuideDetail);
                      //console.log("laborGuideDetail", laborGuideDetail);
                      setshowLaborGuide(true);
                    }
                  }).catch((error) => {
                    dispatch({
                      payload: {
                        globalLoader: false
                      },
                      type: actionTypes.GLOBAL_LOADER
                    });
                    console.error('error', error);
                  });
                });
              } else {
                dispatch({
                  payload: {
                    globalLoader: false
                  },
                  type: actionTypes.GLOBAL_LOADER
                });
              }
            }).catch((error) => {
              dispatch({
                payload: {
                  globalLoader: false
                },
                type: actionTypes.GLOBAL_LOADER
              });
              console.error('error', error);
            });
          }
        }
        //setshowLaborGuide(!showLaborGuide);
        break;
      case "interchange":
        setshowInterchange(true);
        break;
      case "partDetails":
        setshowPartDetails(!showPartDetails);
        break;
      case "vectorGfx":
        setshowVectorGfx(!showVectorGfx);
        break;
      case "imagesSlider":
        setShowImagesSlider(!showImagesSlider);
        break;
      case "lostSales":
        setshowLostSales(!showLostSales);
        break;
        case "warranty":
          if(true){
            setshowwarrantyDetails(true);
          }
          break;
      default:
        break;
    }
  };

  const validateQty = (quantity, availableQuantity, validity) => {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  };

  const handleInputChangeOnMouseLeave = (event, totalAvailqty, index, row) => {
    if (row.qtyBuyInc > 1) {
      let value;
      value = getPartPackQty(row.qtyBuyInc, event.target.value, totalAvailqty);
      if (!_isEmpty(event) && row.qtyBuyInc > 1 && event.target.value != value) {
        value = getPartPackQty(row.qtyBuyInc, event.target.value, totalAvailqty);
        qtyByINCUpdateSuccessMessage();
      }
      setTimeout(() => {
        rows[index].qtytoorder = value;
        constructTablePartData(rows);
      });

      if (value > totalAvailqty) {
        setCurrentTotalAvailable(totalAvailqty + 1);
        rows[index].totalAvailable = totalAvailqty + 1
        rows[index].validationRed = true;
        setTimeout(() => {
          constructTablePartData(rows)
        })
      } else {
        if (row.availabilities.length > 1 && event.target.value > row.availabilities[0].availableQty && !(rows[index].hiddenAvail)) {
          rows[index].hiddenAvail = true;
          setTimeout(() => {
            constructTablePartData(rows)
          })
        } else if (row.availabilities.length > 1 && event.target.value < row.availabilities[0].availableQty && (rows[index].hiddenAvail)) {
          rows[index].hiddenAvail = false;
          setTimeout(() => {
            constructTablePartData(rows)
          })
        }
        else if ((event.target.value < totalAvailqty || _isEmpty(event.target.value)) && (rows[index].validationRed)) {
          rows[index].validationRed = false;
          setTimeout(() => {
            constructTablePartData(rows)
          })
        }
      }

      rows[index].extendedPrice = (getExtendedTotalPrice(row.corePrice, row.yourprice, event.target.value));
      let extendedPriceData = rows[index].extendedPrice;

      if (document.querySelector("#extendedprice-" + index)) {
        let extendedPrice = "$" + formatAmount(extendedPriceData);
        document.querySelector("#extendedpriceText-" + index).style.fontSize = "20px";
        document.querySelector("#extendedpriceText-" + index).innerHTML = extendedPrice;
        setTimeout(() => {
          document.querySelector("#extendedpriceText-" + index).style.fontSize = "12px";
          constructTablePartData(rows);
        }, 2000)
      }
    }

  }
  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const handleInputChange = (event, totalAvailqty, index, row) => {
    let partDetails = rows[index].part || rows[index].altPart || rows[index];
    let value;
    if (!_isEmpty(event)) {
      value = event.target.value;
    } else {
      value = partDetails && partDetails.perCarQty ? partDetails.perCarQty : "";
    }
    rows[index].qtytoorder = value;
    if (value > totalAvailqty) {
      setCurrentTotalAvailable(totalAvailqty + 1);
      rows[index].totalAvailable = totalAvailqty + 1;
      rows[index].validationRed = true;
      setTimeout(() => {
        constructTablePartData(rows);
      });
    } else {
      row.availabilities[0].availableQty =
        row.availabilities[0] &&
          row.availabilities[0].availableDuration &&
          row.availabilities[0].availableDuration !== "Next Business Day"
          ? row.availabilities[0].storeAvailabilities[0].storeQty
          : row.availabilities[0].availableQty;
      if (
        row.availabilities.length > 1 &&
        event.target.value > row.availabilities[0].availableQty &&
        !rows[index].hiddenAvail
      ) {
        rows[index].hiddenAvail = true;
        setTimeout(() => {
          constructTablePartData(rows);
        });
      } else if (
        row.availabilities.length > 1 &&
        event.target.value < row.availabilities[0].availableQty &&
        rows[index].hiddenAvail
      ) {
        rows[index].hiddenAvail = false;
        setTimeout(() => {
          constructTablePartData(rows);
        });
      } else if (
        (event.target.value < totalAvailqty || _isEmpty(event.target.value)) &&
        rows[index].validationRed
      ) {
        rows[index].validationRed = false;
        setTimeout(() => {
          constructTablePartData(rows);
        });
      }
    }
    let extendedPriceData = (getExtendedTotalPrice(row.corePrice, row.yourprice, event.target.value));

    if (row.qtyBuyInc > 1) {
      rows[index].extendedPrice = extendedPriceData;
    } else {
      if (document.querySelector("#extendedprice-" + index)) {
        let extendedPrice = "$" + formatAmount(extendedPriceData);
        document.querySelector("#extendedpriceText-" + index).style.fontSize =
          "20px";
        document.querySelector("#extendedpriceText-" + index).innerHTML =
          extendedPrice;
        setTimeout(() => {
          document.querySelector("#extendedpriceText-" + index).style.fontSize =
            "12px";
        }, 2000);
      }
      rows[index].extendedPrice = extendedPriceData;
    }
  };

  const hideAvailabilityonNextPartFocus = (event, index, row) => {
    rows.map((value, key) => {
      if (key != index && rows[key].hiddenAvail === true) {
        rows[key].hiddenAvail = false;
        setTimeout(() => {
          constructTablePartData(rows);
        });
      }
    });
  };

  const getWhichCart = () => {
    let orginalCart = {};
    if(isForCandK) {
      orginalCart.checkoutDetails = candkCheckoutDetails;
    } else {
      orginalCart = _cloneDeep(cart);
    }  
    return orginalCart
  }


  const constructCartAvailability = (index) => {
    const { selectedStore, sellPartnerId, shipMethod, parts } = props;
    let selectedAvailabilityHours = [];
    let { userDetails } = user;
    let partsQtySum =
      !_isEmpty(rows[index].part) &&
        !_isEmpty(rows[index].part.pricingAvailability) &&
        !_isEmpty(rows[index].part.pricingAvailability.availability)
        ? _sumBy(
          _uniqBy(
            rows[index].part.pricingAvailability.availability,
            "availableDuration"
          ),
          "availableQty"
        )
        : 0;
    let totalOrderQty = totalOrderQy;
    let partDetails = rows[index].part || rows[index].altPart || rows[index];
    let pricingAvailability = partDetails.pricingAvailability;
    let distributionData = {};
    let cloneCart = getWhichCart();
    if (!isForCandK && shipMethod !== getShipInfo(userDetails, parts).shipMethod) {
      cloneCart.checkoutDetails = [];
    }
    distributionData = getDistributionForVehicle(
      rows[index].part.partNumber,
      rows[index].part,
      pricingAvailability.availability,
      Number(rows[index].qtytoorder),
      mygarage.selectedVehicle,
      cloneCart
    );
    distributionData.alreadyAddedItem =
    !isForCandK && shipMethod !== getShipInfo(userDetails, parts).shipMethod
        ? cart.checkoutDetails
        : distributionData.alreadyAddedItem;
    let details = rows.map((v) => v.rowMoreInfo);
    // let selectedVehicle = rowsMoreDetails[index].selectedVehicle;
    // let selectedGroupDetails = rowsMoreDetails[index].selectedGroupDetails;
    let selectedVehicle = details[index].selectedVehicle;
    let selectedGroupDetails = details[index].selectedGroupDetails;
    let categoryGroupMap = categoryGroupMp;
    partsQtySum = _sumBy(
      _uniqBy(pricingAvailability.availability, "availableDuration"),
      "availableQty"
    );
    selectedAvailabilityHourss = [];
    let selectedAvailabilityHoursNewCart = [];
    let categoryId =  Number(
      _find(categoryGroupMap, (c) => {
        return _includes(
          _map(c.groups, "code"),
          Number(partDetails.groupNumber)
        );
      }).code
    );
    selectedAvailabilityHoursNewCart.push(
      createAvailabilityObject({}, pricingAvailability, partDetails, selectedVehicle, selectedStore, sellPartnerId, shipMethod, Number(rows[index].qtytoorder, categoryId))
     // getAvailabilityObject(pricingAvailability, {}, value)
    );
    return selectedAvailabilityHoursNewCart;
    selectedAvailabilityHourss.push(...distributionData.alreadyAddedItem);
    let qtyOrder = Number(rows[index].qtytoorder);
    if (qtyOrder > 0) {
      totalOrderQty = Number(rows[index].qtytoorder);
      let orderAray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
      orderAray.map((item) => {
        selectedAvailabilityHourss.push({
          availabilityHour: item.availableDuration,
          availableQty: item.availableQty,
          cartFlag: "no",
          categoryId: Number(
            _find(categoryGroupMap, (c) => {
              return _includes(
                _map(c.groups, "code"),
                Number(partDetails.groupNumber)
              );
            }).code
          ),
          costPrice: pricingAvailability.costPrice,
          corePrice: pricingAvailability.corePrice,
          coreListPrice: pricingAvailability.coreListPrice,
          desc: partDetails.description,
          descId: partDetails.descriptionID,
          engineId: selectedVehicle.engineId,
          engine: selectedVehicle.engine,
          groupId: Number(partDetails.groupNumber),
          listPrice: pricingAvailability.listPrice,
          makeId: selectedVehicle.makeId,
          make: selectedVehicle.make,
          modelId: selectedVehicle.modelId,
          model: selectedVehicle.model,
          mfgCode: partDetails.lineCodes ? partDetails.lineCodes[0] : null,
          packQty: pricingAvailability.packQty,
          partNum: partDetails.partNumber,
          partType: "Regular",
          qty: item.orderQty,
          qtyPerVeh: partDetails.perCarQty,
          toolTip: JSON.stringify(pricingAvailability.toolTips),
          vehicleInfo: `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`,
          yearId: selectedVehicle.year,
          store: selectedStore,
          sellPartnerId: sellPartnerId,
          shipMethod: shipMethod,
          distStatus: "Completed",
          orderType: "REGULAR"
        });
      });
    }
    return selectedAvailabilityHourss;
  };

  const handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  const renderAvailabilityFormatter = (cell, row, index) => {
    let {selectedStore} = props;
    let proPack, handlePartSelect, proPackKey;
    let partDetails = rows[index].part;
    let partsQtySum = !_isEmpty(
      rows[index].part.pricingAvailability.availability
    )
      ? _sumBy(
        _uniqBy(
          rows[index].part.pricingAvailability.availability,
          "availableDuration"
        ),
        "availableQty"
      )
      : 0;
    let defualtTotalQuantity = true; //this.setDefalutProductQuantity();
    let availabilitySection = 0;
    let totalAvailqty = 0;

    let uniqueData = _uniqBy(
      partDetails.pricingAvailability.availability,
      "availableDuration"
    );
    let enableOtherAvailabilities =
      uniqueData.length > 0 &&
        uniqueData[0].availableQty &&
        uniqueData[0].availableQty >= row.qtypervehicle &&
        (uniqueData[0].code == -38 || uniqueData[0].code == -50)
        ? false
        : true;
    let otherStoreAvailability = 0;
    let hightlightAvailability = 0;
    uniqueData.map((unique, index) => {
      if (unique.code && (unique.code != -38 || unique.code != -50)) {
        otherStoreAvailability += unique.availableQty;
      }
      if (unique.code && (unique.code == -38 || unique.code == -50)) {
        hightlightAvailability += unique.availableQty;
      }
    });
    enableOtherAvailabilities =
      otherStoreAvailability > 0 ? enableOtherAvailabilities : false;
    enableOtherAvailabilities =
      otherStoreAvailability > 0 &&
        row.qtypervehicle == "" &&
        hightlightAvailability == 0
        ? true
        : enableOtherAvailabilities;
    let availabilityLength = 0;
    if (!isEmpty(uniqueData)) {
      if (row.availabilities.length === 0) {
        row.availabilities = uniqueData;
        availabilityLength = row.availabilities.length;
        row.firstCode =
          uniqueData.length > 0 ? uniqueData[0].code : uniqueData[0].code;
        row.secondCode =
          uniqueData.length > 1 ? uniqueData[1].code : uniqueData[0].code;
      } else {
        availabilityLength = row.availabilities.length;
        row.firstCode =
          row.availabilities.length > 0
            ? row.availabilities[0].code
            : row.availabilities[0].code;
        row.secondCode =
          row.availabilities.length > 1
            ? row.availabilities[1].code
            : row.availabilities[0].code;
      }
    }

    // Temporary fix -- Should be removed
    if (
      (!isEmpty(partDetails) &&
        partDetails.pricingAvailability &&
        partDetails.pricingAvailability.availability.length > 0 &&
        row.availabilities[0].availableQty > 0) ||
      (row.availabilities[0] &&
        row.availabilities[0].availableDuration &&
        (row.availabilities[0].availableDuration === "Your Store" ||
          row.availabilities[0].availableDuration === "Store"))
    ) {
      let singleAvailability = row.availabilities[0];
      singleAvailability.availableDuration =
        singleAvailability.availableDuration &&
          singleAvailability.availableDuration === "00:00:00" &&
          singleAvailability.code === 0
          ? "Immediately"
          : singleAvailability.availableDuration;
      totalAvailqty += singleAvailability.availableQty;

      if (getSellerInfo(user.userDetails)){
        return (
        getAvailability(row, singleAvailability, {}, {}, user.userDetails, "Table",onclick, 0, selectedStore)
      )}
      
      return (
        <div className="availability-container">
          <div className="availability-subdiv">
            {/* {(singleAvailability.availableDuration && singleAvailability.availableDuration === 'Next Business Day' || singleAvailability.availableDuration === "Next 2 + Business Days") ?
							(<ul><li>
								<span className="text-black">
									<span className="store-maroon-text">
										<b>{singleAvailability.availableQty}</b>
									</span>
									&nbsp;Available
									&nbsp;
									{singleAvailability.availableDuration}{' '}
								</span>
							</li></ul>
							) : (singleAvailability.availableDuration && singleAvailability.availableDuration === 'Your Store' && singleAvailability.storeAvailabilities[0].storeQty == 0) ?
								(<ul>
									<li>
										<span className="store-maroon-text"><b>{`${singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].storeQty}`}</b></span>
										<span className="text-black">&nbsp;{singleAvailability.availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].store}</b>
										</span>
									</li>
									{(singleAvailability.availableDuration === 'Your Store' && singleAvailability.storeAvailabilities[0].storeQty == 0 && row.availabilities.length > 1 && row.availabilities[1] && (row.availabilities[1].availableDuration === 'Next Business Day' || row.availabilities[1].availableDuration === 'Next 2 + Business Days')) ?
										(<li>
											<span className="text-black">
												<span className="store-maroon-text">
													<b>{row.availabilities[1].availableQty}</b>
												</span>
												&nbsp;Available
												&nbsp;
												{row.availabilities[1].availableDuration}{' '}
											</span>
										</li>) :
										(<li>
											<span className="store-maroon-text"><b>{`${row.availabilities.length > 1 && row.availabilities[1] && row.availabilities[1].storeAvailabilities && row.availabilities[1].storeAvailabilities[0] && row.availabilities[1].storeAvailabilities[0].storeQty}`}</b></span>
											<span className="text-black">&nbsp;{row.availabilities[1].availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{row.availabilities[1].storeAvailabilities && row.availabilities[1].storeAvailabilities[0] && row.availabilities[1].storeAvailabilities[0].store}</b>
											</span>
										</li>)
									}

								</ul>
								) : (<ul>
									<li>
										<span className="store-maroon-text"><b>{`${singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].storeQty}`}</b></span>
										<span className="text-black">&nbsp;{singleAvailability.availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].store}</b>
										</span>
									</li>
								</ul>

								)
						} */}

            {uniqueData.map((unique, index) => {
              return (
                unique.code &&
                (unique.code == -38 || unique.code == -50) && (
                  <ul>
                    <li>
                      <span className="text-black">
                        {unique.availableQty == 0 ? (
                          <span className={enableBlackColorBasedOnStorePref(selectedStore) ? "text-black compact-text-grey-Zero" : "maroonColorTxt compact-text-grey-Zero"}>
                            <b>{unique.availableQty}</b>
                          </span>
                        ) : (
                          <span className={enableBlackColorBasedOnStorePref(selectedStore) ? "text-black compact-text-grey" : "maroonColorTxt compact-text-grey"}>
                            <b>{unique.availableQty}</b>
                          </span>
                        )}
                        &nbsp;at &nbsp;
                        {unique.storeAvailabilities.length > 0 &&
                          unique.storeAvailabilities[0].store &&
                          unique.storeAvailabilities[0].store.length > 13 ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 100 }}
                            overlay={
                              <Tooltip
                                id="toggle-view-full-compact"
                                className="custom-tooltip-availability"
                              >
                                <b>#{unique.storeAvailabilities[0].store}</b>
                              </Tooltip>
                            }
                          >
                            <span>
                              {unique.availableDuration}{" "}
                              {unique.storeAvailabilities.length > 0 &&
                                unique.storeAvailabilities[0].store ? (
                                <span>
                                  <b>
                                    #
                                    {unique.storeAvailabilities[0].store &&
                                      unique.storeAvailabilities[0].store.length >
                                      13
                                      ? unique.storeAvailabilities[0].store.substring(
                                        0,
                                        13
                                      ) + ".."
                                      : unique.storeAvailabilities[0].store}
                                  </b>
                                </span>
                              ) : null}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span>
                            {unique.availableDuration}{" "}
                            {unique.storeAvailabilities.length > 0 &&
                              unique.storeAvailabilities[0].store ? (
                              <span>
                                <b>
                                  #
                                  {unique.storeAvailabilities[0].store &&
                                    unique.storeAvailabilities[0].store.length >
                                    14
                                    ? unique.storeAvailabilities[0].store.substring(
                                      0,
                                      14
                                    ) + ".."
                                    : unique.storeAvailabilities[0].store}
                                </b>
                              </span>
                            ) : null}
                          </span>
                        )}
                      </span>
                    </li>
                  </ul>
                )
              );
            })}
            {enableOtherAvailabilities ? (
              <ul>
                <li>
                  <span className="text-black">
                    <span className="store-maroon-text compact-text-green">
                      <b>{otherStoreAvailability}</b>
                    </span>
                    &nbsp;at Other Stores &nbsp;{" "}
                  </span>
                </li>
              </ul>
            ) : null}
          </div>
          {row.availabilities.length > 0 && otherStoreAvailability > 0 ? (
            row.hiddenAvail ? (
              <Fragment>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 4000 }}
                  show={row.hiddenAvail}
                  overlay={
                    <Tooltip
                      id="toggle-view-full-compact"
                      className="custom-tooltip-availability"
                    >
                      {prepareTooltipAvailability(row.availabilities)}
                    </Tooltip>
                  }
                >
                  <a className="maroonColorTxt info-icon">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </OverlayTrigger>
                {/* <OverlayTrigger
									placement="bottom"
									delay={{ show: 100, hide: 400 }}
									overlay={
									       <Tooltip id="toggle-view-part-compact" className="custom-home-icon-tooltip">
												   {singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities.length > 0 && singleAvailability.storeAvailabilities.map((value, key) => {
												    return (
														<ul>
														 <li>{value.storeQty} Available in location {value.store}</li>
												        </ul>
													)})}   
													
									       </Tooltip>
										}
						         >
							 <button type="button" class="store-icon-availability store-compact-view"><i class="fa fa-home" aria-hidden="true"></i></button>
					     	</OverlayTrigger>  */}
              </Fragment>
            ) : (
              <Fragment>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 4000 }}
                  overlay={
                    <Tooltip
                      id="toggle-view-full-compact"
                      className="custom-tooltip-availability"
                    >
                      {prepareTooltipAvailability(row.availabilities)}
                    </Tooltip>
                  }
                >
                  <a className="maroonColorTxt info-icon">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </OverlayTrigger>
                {/* <OverlayTrigger
									placement="bottom"
									delay={{ show: 100, hide: 400 }}
									overlay={
									       <Tooltip id="toggle-view-part-compact" className="custom-home-icon-tooltip">
												   { singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities.length > 0 && singleAvailability.storeAvailabilities.map((value, key) => {
												    return (
														<ul>
														 <li>{value.storeQty} Available in location {value.store}</li>
												        </ul>
													)})}   
													
									       </Tooltip>
										}
						         >
							     <button type="button" class="store-icon-availability table-view store-compact-view"><i class="fa fa-home" aria-hidden="true"></i></button>
					     	    </OverlayTrigger>  */}
              </Fragment>
            )
          ) : (
            //its a dummy icon for setting some alignment issue
            <a className="disabledIcon info-icon">
              <i class="fa fa-info-circle"></i>
            </a>
          )}
        </div>
      );
    } else {
      return row.yourprice != "" || row.brand != "" ? (
        <div>
          <div className="add_to_cart_out">
            <span>
              <b>Currently out of stock</b>
            </span>
          </div>
        </div>
      ) : (
        <span></span>
      );
    }
  };

  const handleImageClick = (row, rowData, index) => {
    // if (row.part.images && row.part.images.length) {
    if(!showImagesSlider) {
      setIndex(index);
      setPartData(row.part);
      setProductData(rowData);
      setShowImagesSlider(true);
    }
    // }
  };

  const prepareTooltipAvailability = (availabilities) => {
    let returnArray = [];
    availabilities.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    <b>{singleAvailability.availableQty}</b>
                  </span>
                  &nbsp; &nbsp;
                  {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration, true)}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (
              singleAvailability.code &&
              (singleAvailability.code != -38 || singleAvailability.code != -50)
            ) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                        singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    // returnArray.shift();
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };

  const addToCartForCandK = (e, index) => {
    let selectedAvailabilityHourss = constructCartAvailability(index);
    dispatch({
      payload: {
        candkCheckoutDetails: selectedAvailabilityHourss
      },
      type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
    });
    const statusMessageProps = {
      heading: "Item added to cart",
      message: "",
      type: "success",
    };
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }


  const addToCart = (e, index) => {
    setClickIndex(index)
    let selectedAvailabilityHourss = constructCartAvailability(index);
    dispatch({
      payload: {
        selectedAddCartData: _cloneDeep(selectedAvailabilityHourss),
      },
      type: actionTypes.SELECTED_ADDCART_DATA,
    });
    /* show warning popup for differnt order */
    if (isStockOrder(orderType)) {
      dispatch({
        payload: {
          addToCartWarning: true,
        },
        type: actionTypes.ADDTO_CART_WARNING,
      });
    } else {

    let { model, modelId, make, makeId, engine, engineId, year } =
      mygarage.selectedVehicle || {};
    let {
      userDetails: { x2userName, userId, shipTos, billTo },
    } = user;
    let source = "partCategory";
    if (!_isEmpty(selectedAvailabilityHourss)) {
      addItemToCart2(selectedAvailabilityHourss, dispatch, true, user, "parttableview");
      let uniqsah = _uniqBy(selectedAvailabilityHourss, "descId");
      uniqsah.map((s, k) => {
        if (!_isEmpty(s.descId)) {
          const payload = {
            categoryId: Number(
              _find(categoryGroupMp, (c) => {
                return _includes(_map(c.groups, "code"), Number(s.groupId));
              }).code
            ),
            createdBy: x2userName,
            createdDate: timeInUTC(),
            customerId: billTo.billToId,
            engine: engine.trim(),
            engineId,
            groupId: s.groupId,
            lastOrderedDate: timeInUTC(),
            make: make.trim(),
            makeId,
            model: model.trim(),
            modelId,
            orderedFlag: "N",
            partTypeDesc: s.desc,
            partTypeDescId: s.descId,
            searchPartId: "",
            shipToId: shipTos[0].shipToId,
            source,
            updatedBy: x2userName,
            updatedDate: timeInUTC(),
            userFilter: "selected",
            year: year.trim(),
          };
          dispatch(setPartHistory(payload));
        }
      });
    }
   }
  };

  const handlePartDetailsClose = () => {
    setshowPartDetails(!showPartDetails);
  };

  const setDefalutProductQuantity = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedProductQty = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedProductQty = _find(userPreferenceDetail, {
        code: "Product Information",
        text: "Default Order Qty to Qty Per Vehicle",
      });
    }
    return selectedProductQty &&
      selectedProductQty.answer &&
      selectedProductQty.answer.code === "N"
      ? false
      : true;
  };

  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowAvailabilityStoreView(true)
  }
  
  const checkIfMobileEnabled = (tempData,text,isTooltip) =>{
    return(
      isMobileEnabled ? 
      <u className={isTooltip ?"cursor-pointer": "text-color"} onClick={()=>{onclick(tempData)}}>{text}</u> :
    <u>{text}</u>
    )
  }

  const altSupPart = (row) => {
    let part = [];
    row.map((subPart, index) => {
      part.push(subPart.partNumber);
    });
    return part.join(" ,");
  };

  const checkIsPartInCart = (partNum, part) => {
    let str = "";
    let qty = 0;
    cart.cartAllDetails.map((item) => {
      if (item.partNum === partNum && item.mfgCode === part.lineCodes?.[0]) {
        qty += item.qty;
        str = qty + ` in Cart`;
      }
    });
    return str;
  };

  const constructTablePartData = (rows) => {
    setPartTableColumns([]);
    setPartTableRows([]);
    let columns = [
      {
        dataField: "hiddenAvail",
        text: "P/L",
        hidden: true,
      },
      {
        dataField: "validationRed",
        text: "validation",
        hidden: true,
      },
      {
        dataField: "totalAvailable",
        text: "totalAvailable",
        hidden: true,
      },
      {
        dataField: "part",
        text: "Part#",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: partNumberFormatter,
      },
      {
        dataField: "description",
        text: "Description",
        headerStyle: descriptionHeaderStyle,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerAlign: "center",
        formatter: function (cell, row, index) {
          return row.brand == "" ? (
            <span>
              <p className="part-item">{row.items} Item(s)</p>
            </span>
          ) : (
            <div className="compact-table-description-row">
              <div className="col-one">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="description-part-catalog-tooltip"
                      className="tooltip-description-custom"
                    >
                      <span>
                        {!_isEmpty(row.part.pricingAvailability.itemSpecific)
                          ? `Item Specifics: ${row.part.pricingAvailability.itemSpecific};`
                          : ""}
                      </span>
                      <span>
                        {!_isEmpty(row.part.years)
                          ? ` Years: ${row.part.years};`
                          : ""}
                      </span>
                      <span>
                        {!_isEmpty(row.part.position)
                          ? ` Position: ${row.part.position};`
                          : ""}
                      </span>
                      {!_isEmpty(row.part.unansweredSpecifics) &&
                        row.part.unansweredSpecifics.length > 0 && (
                          <span>
                            Don’t know:
                            {row.part.unansweredSpecifics.map(
                              (value, index) => {
                                return (
                                  <span>
                                    {row.part.unansweredSpecifics.length == 1
                                      ? `${value}`
                                      : `${value}; `}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        )}
                      <span>
                        <Highlighter
                          caseSensitive={false}
                          highlightClassName="program-highlightTxt"
                          searchWords={searchWords}
                          autoEscape={true}
                          textToHighlight={
                            !_isEmpty(row.part.miscellaneousTexts)
                              ? ` Description: ${row.part.miscellaneousTexts}`
                              : ""
                          }
                        ></Highlighter>
                        {/* {!_isEmpty(row.part.miscellaneousTexts)
                          ? ` Description: ${row.part.miscellaneousTexts}`
                          : ""} */}
                      </span>
                      {(!_isEmpty(row.part.alternate) ||
                        !_isEmpty(row.part.substitute)) && <br></br>}
                      <span>
                        {!_isEmpty(row.part.alternate)
                          ? `Alternate Part ${altSupPart(row.part.alternate)}`
                          : ""}
                        {!_isEmpty(row.part.substitute)
                          ? ` Superseded Part ${altSupPart(
                            row.part.substitute
                          )}`
                          : ""}
                      </span>
                    </Tooltip>
                  }
                >
                  {/* this section is for  display the description with respect to promotion,filtersection (on/off) ...  restricting the description length based on the tablesize*/}
                  <span className="alt-sub-text">
                    {((row.rowMoreInfo && row.rowMoreInfo.isSubType) ||
                      (row.rowMoreInfo && row.rowMoreInfo.isAltType)) && (
                        <span className="maroonColorTxt pr-1">
                          {row.rowMoreInfo && row.rowMoreInfo.isSubType
                            ? "<SUPERSEDED>"
                            : row.rowMoreInfo && row.rowMoreInfo.isAltType
                              ? "<ALTERNATE>"
                              : ""}
                        </span>
                      )}
                    <span className="desc-part">
                      {`${row.description.substring(0, 20)}`}{" "}
                      {row.description.toLowerCase() !==
                        row.part.pricingAvailability.itemSpecific.toLowerCase()
                        ? ";" + row.part.pricingAvailability.itemSpecific
                        : ""}
                    </span>

                    {!_isEmpty(row.part.alternate) && (
                      <span className="alt-sup-highlightTxt">
                        ;Alternate Part {altSupPart(row.part.alternate)}
                      </span>
                    )}
                    {!_isEmpty(row.part.substitute) && (
                      <span className="alt-sup-highlightTxt">
                        ;Superseded Part {altSupPart(row.part.substitute)}
                      </span>
                    )}

                    {/* <span className="desc-part">{`${row.description.substring(0, 20)}`} { row.description.toLowerCase() !== row.part.pricingAvailability.itemSpecific.toLowerCase()? ";"+row.part.pricingAvailability.itemSpecific: ""} {altSupData(row).length> 0 ? ";"+altSupData(row): ""} </span> */}
                  </span>
                </OverlayTrigger>
              </div>
              <div className="col-two">
                <PricingIcons
                  toolTips={row.part.pricingAvailability.toolTips}
                  isProgrammable={isProgrammable(
                    row.part?.miscellaneousTexts?.toString() || ""
                  )}
                />
              </div>
              {/* {isProgrammable(
                row.part?.miscellaneousTexts?.toString() || ""
              ) && (
                <div className="col-two-icon">
                  <img alt="" src="/public_images/programmable.png" />
                </div>
              )} */}
            </div>
          );
        },
      },
      {
        dataField: "brand",
        text: "Brand",
        headerStyle: brandHeaderStyle,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerAlign: "center",
        formatter: function (cell, row, index) {
          return (
            <div className="">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="brand-part-catalog-tooltip"
                    className="tooltip-description-custom"
                  >
                    <span>{row.brand}</span>
                  </Tooltip>
                }
              >
                <span className="brand-description">
                  {row.brand.length > 8
                    ? `${row.brand.substring(0, 8)}..`
                    : row.brand}
                </span>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        dataField: "links",
        text: "Links",
        headerStyle: linksHeaderStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row, index) {
          let coverToCoverShow =
            rows[index].part.mediaTag && rows[index].part.mediaTag === "Y"
              ? true
              : false;
          return row.brand != "" ? (
            <div className="table-view-links_Container">
              <Fragment>
                <img src={linkIcon} alt="Link" />
                <div>
                  {
                    // (!rowsMoreDetails[index].isAltType) &&
                    // 	(!rowsMoreDetails[index].isSubType) &&
                    user.userDetails &&
                    (user.userDetails.userSecurityGroup === null ||
                      user.userDetails.userSecurityGroup.laborGuide ||
                      user.userDetails.userSecurityGroup.buyersGuide ||
                      user.userDetails.userSecurityGroup.interchange ||
                      user.userDetails.userSecurityGroup.warranty ||
                      user.userDetails.userSecurityGroup
                        .vectorIllustration) && (
                      <ul>
                        {/* {(true)
													&& (
														<li>
															<a href="#" title="Part Details" onClick={() => {
																togglePopup(
																	'partDetails', index)
															}}>Part Details</a>
														</li>
													)} */}
                        {user.userDetails &&
                          (user.userDetails.userSecurityGroup === null ||
                            user.userDetails.userSecurityGroup
                              .vectorIllustration) &&
                          rows[index] &&
                          rows[index].part &&
                          rows[index].part.gfxAvailable && (
                            <li>
                              <a
                                href="#"
                                onClick={() => {
                                  togglePopup("vectorGfx", index);
                                }}
                                title="Part Diagram"
                              >
                                Part Diagram
                              </a>
                            </li>
                          )}
                        {user.userDetails &&
                          (user.userDetails.userSecurityGroup === null ||
                            user.userDetails.userSecurityGroup.interchange) &&
                          row.rowMoreInfo &&
                         // !row.rowMoreInfo.isAltType &&
                          //!row.rowMoreInfo.isSubType && (
                            (<li>
                              <a
                                href="#"
                                onClick={() => {
                                  togglePopup("interchange", index);
                                }}
                                title="Interchange"
                              >
                                Interchange
                              </a>
                            </li>
                          )}
                        {user.userDetails &&
                          (user.userDetails.userSecurityGroup === null ||
                            user.userDetails.userSecurityGroup.buyersGuide) &&
                          row.rowMoreInfo &&
                         // !row.rowMoreInfo.isAltType &&
                         // !row.rowMoreInfo.isSubType && (
                            (<li>
                              <a
                                href="#"
                                onClick={() => {
                                  togglePopup("buyersGuide", index);
                                }}
                                title="Buyers Guide"
                              >
                                Buyers Guide
                              </a>
                            </li>
                          )} 
                        {user.userDetails &&
                          (user.userDetails.userSecurityGroup === null ||
                            user.userDetails.userSecurityGroup.laborGuide) &&
                          row.rowMoreInfo &&
                         // !row.rowMoreInfo.isAltType &&
                          //!row.rowMoreInfo.isSubType && (
                            (<li>
                              <a
                                href="#"
                                onClick={() => {
                                  togglePopup("laborGuide", index);
                                }}
                                title="Labor Guide"
                              >
                                Labor Guide
                              </a>
                            </li>
                          )}
                        {row.rowMoreInfo &&
                          //!row.rowMoreInfo.isAltType &&
                          //!row.rowMoreInfo.isSubType &&
                          coverToCoverShow && (
                            <li>
                              <a
                                href="#"
                                onClick={() => {
                                  togglePopup("coverToCover", index);
                                }}
                                title="Cover to Cover"
                              >
                                Cover to Cover
                              </a>
                            </li>
                          )}
                        {true && (
                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                togglePopup("lostSales", index);
                              }}
                              title="Lost Sales"
                            >
                              Lost Sales
                            </a>
                          </li>
                        )}
                           {user.userDetails &&
                            (user.userDetails.userSecurityGroup === null ||
                              user.userDetails.userSecurityGroup.warranty) &&
                            row.rowMoreInfo &&
                            !row.rowMoreInfo.isAltType &&
                            !row.rowMoreInfo.isSubType && (
                              <li>
                                <a
                                  href="#"
                                  onClick={() => {
                                    togglePopup("warranty", index,true);
  
                                  }}
                                  title=" Warranty"
                                >
                                  Warranty
                                </a>

                              </li>
                            )}
                      </ul>
                    )
                  }
                </div>
              </Fragment>
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "yourprice",
        text: "Your Price",
        hidden: hideCostPrice || hideAllPrice,
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : yourPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row) {
          return row.yourprice != "" ? (
            <div className="">
              {!cart.hideCostPrice && (
                <span className="maroonColorTxt">
                  ${formatAmount(row.yourprice)}
                </span>
              )}
            </div>
          ) : (
            <span>{row.yourprice}</span>
          );
        },
      },
      {
        dataField: "coreprice",
        text: "Core Price",
        hidden: hideCostPrice || hideAllPrice,
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : yourPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row) {
          return row.yourprice != "" ? (
            <div className="">
              {!cart.hideCostPrice && (
                <span className="maroonColorTxt">
                  ${formatAmount(row.corePrice)}
                </span>
              )}
            </div>
          ) : (
            <span>{row.corePrice}</span>
          );
        },
      },
      {
        dataField: "listprice",
        text: "List Price",
        hidden: hideListPrice || hideAllPrice,
        headerStyle: listPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return row.yourprice != "" ? (
            <span className="maroonColorTxt">
              ${formatAmount(row.listprice)}
            </span>
          ) : (
            <span>{row.listPrice}</span>
          );
        },
      },
      {
        dataField: "qtypervehicle",
        text: "Qty Per Vehicle",
        headerStyle: qtyHeaderStyle,
        align: "center",
        //classes: 'bootstrap-table-bold-cell',
        headerAlign: "center",
      },
      {
        dataField: "qtytoorder",
        text: "Qty to Order",
        headerStyle: QtyOrderheaderStyle,
        align: "center",
        headerAlign: "center",
        //classes: 'bootstrap-table-bold-cell qty-to-order',
        formatter: function (cell, row, index) {
          let totalAvailqty = 0;
          let uniqueData = _uniqBy(
            row.part.pricingAvailability.availability,
            "availableDuration"
          );
          uniqueData.map((singleAvailability, key) => {
            if (singleAvailability.availableQty > 0) {
              singleAvailability.availableDuration =
                singleAvailability.availableDuration === "00:00:00" &&
                  singleAvailability.code === 0
                  ? "Immediately"
                  : singleAvailability.availableDuration;
              totalAvailqty += singleAvailability.availableQty;
            }
          });

          return row.description != "" || row.brand != "" ? (
            <Fragment>
              <div className="tableview-order">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    row.validationRed ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">
                          Please enter a quantity less than {row.totalAvailable}
                        </span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  <input
                    className={
                      row.validationRed
                        ? "table_qty_order boder-red"
                        : "table_qty_order"
                    }
                    type="text"
                    name="totalQt"
                    autocomplete="off"
                    defaultValue={row.qtytoorder ? row.qtytoorder : ""}
                    disabled={totalAvailqty == 0}
                    //autoFocus={row.autoFocus ? true : false}
                    onChange={(e) => {
                      handleInputChange(e, totalAvailqty, index, row);
                    }}
                    onMouseLeave={(e) => {
                      handleInputChangeOnMouseLeave(e, totalAvailqty, index, row)
                    }}
                    onBlur={(e) => {
                      handleInputChangeOnMouseLeave(e, totalAvailqty, index, row)
                    }}
                    onKeyDown={(event) => {
                      handleKeyDown(event);
                    }}
                    onFocus={(e) => hideAvailabilityonNextPartFocus(e, index, row)}
                  />
                </OverlayTrigger>
              </div>

              <span class="table-add-to-cart-button">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="add-to-cart-tooltip-chemical"
                      className="tooltip-add-to-cart"
                    >
                      {`Add to Cart`}
                    </Tooltip>
                  }
                >
                  {!isForCandK ? <Button
                    type="button"
                    disabled={totalAvailqty == 0 || row.validationRed}
                    onClick={(e) => addToCart(e, index)}
                    className="btn btn-primary"
                  >
                    {itemLoader && index === clickIndex ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    )}
                  </Button> :
                  <Button
                    type="button"
                    disabled={totalAvailqty == 0 || row.validationRed}
                    onClick={(e) => addToCartForCandK(e, index)}
                    class="btn btn-primary"
                  >
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </Button>}
					</OverlayTrigger>
				</span>
              <span class="addcart-text">
                {" "}
                {!isForCandK && checkIsPartInCart(row.part.partNumber.trim(), row.part)}{" "}
              </span>
            </Fragment>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "autoFocus",
        text: "autoFocus",
        hidden: true,
      },
      {
        dataField: "extendedPrice",
        text: "Total Extended Price",
        hidden: hideCostPrice || hideAllPrice,
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : yourPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row, index) {
          return row.yourprice != "" || row.brand != "" ? (
            <div className="" id={`extendedprice-${index}`}>
              <span className="extdprice" id={`extendedpriceText-${index}`}>
                ${formatAmount(row.extendedPrice)}
              </span>
            </div>
          ) : (
            <span>{row.extendedPrice}</span>
          );
        },
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Total Extended Price </span>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="tooltip-description-custom-corePrice"
                  >
                    <span className="font-weight-bold">
                      Total Extended Price = Qty * (Your Price + Core Price)
                    </span>
                  </Tooltip>
                }
              >
                <a className="whiteColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        dataField: "availabilities",
        text: "Availability",
        headerStyle: availabilityHeaderStyle,
        align: "center",
        headerAlign: "center",
        formatter: renderAvailabilityFormatter,
      },
      ,
      {
        dataField: "groupby",
        hidden: true,
      },
      {
        dataField: "items",
        hidden: true,
      },
      {
        dataField: "sortAvailability",
        hidden: true,
      },
    ];

    // setTimeout(() => {

    setPartTableColumns(columns);
    setPartTableRows(rows);

    // });
  };

  const headerStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    //'width': '90px'
    width: "120px",
    zIndex: 9
  };
  const linksHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "30px",
    zIndex: 9
  };
  const qtyHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "15px",
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "165px",
  };

  const brandHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "70px",
  };

  const listPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "15px",
  };

  const yourPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "15px",
  };

  //QtyOrderheaderStyle

  const QtyOrderheaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "85px",
  };

  const availabilityHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "110px",
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const rowStyleWithColor = (row, rowIndex) => {
    const style = {};
    {
      style.height = "10px";
    }
    return style;
  };

  const partNumberFormatter = (cell, row, index) => {
    let image = row.part.image ? `${row.part.image}?${imageURLCookie}` : "";
    let imagesLength = 0;
    if (!_isEmpty(row.part) && !_isEmpty(row.part.images)) {
      imagesLength = row.part.images.length;
    }
    if (_isEmpty(image)) {
      image = row.part.thumbnail
        ? `data:image/png;base64,${row.part.thumbnail}`
        : "/noImage.jpg";
    }
    return (
      <Fragment>
        <div className="product-image-container float-left">
          <div
            title={imagesLength > 0 ? `${imagesLength} images` : ""}
            onClick={(e) => {
              handleImageClick(row, rowsMoreDetails[index], index);
            }}
          >
            {row.part != "" && row.brand != "" ? (
              <div className="image-thumbnail-container-table">
                <Image
                  className={
                    row.part.image && row.part.image.length > 0
                      ? "image-thumbnail image-cursor cursor-pointer"
                      : "image-thumbnail cursor-pointer"
                  }
                  src={image}
                />
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
        <div className="product_img">
          {row.items != "" && row.brand == "" && row.description == "" ? (
            <div className="product-image-container table-view-margin">
              {row.part.partNumber && row.part.partNumber.length > 0 && (
                <span>
                  <p className="part-num-part-brand">
                    {row.part.partNumber.trim()}
                  </p>
                </span>
              )}
            </div>
          ) : (
            <div className="product-image-container table-view-margin">
              {row.part.partNumber && row.part.partNumber.length > 0 && (
                <p className="part-num-catalog">{row.part.partNumber.trim()}</p>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  };
  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (row.groupby) {
      classes = "rowStyleCss";
    }
    return classes;
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.groupby) {
      style.backgroundColor = "#e3e3e3";
      style.border = "solid 2px #8c99a6";
    }
    return style;
  };
  return (
    <div>
      {partTableRows.length > 0 && partTableColumns.length > 0 ? (
        <Fragment>
          <div className="part-catalog-custom-table">
            <BootstrapTable
              keyField="custId"
              data={partTableRows}
              columns={partTableColumns}
              rowClasses={rowClasses}
              //rowStyle={rowStyleWithColor}
              rowStyle={rowStyle}
            />
            {addItemcartFetching && (
                    <div className="custom_spinner_container fixed">
                    <Spinner className="custom_spinner" animation="border" />
                 </div>
            )}
          </div>
          {showImagesSlider ? (
            <ImagesSlider
              openPopup={showImagesSlider}
              closePopup={togglePopup}
              partDetails={partData}
              setShowImagesSlider={setShowImagesSlider}
            />
          ) : null}
          {showInterchange && (
            <XModal
              show={showInterchange}
              showButtons={false}
              title="Interchange"
              customHeader={
                <React.Fragment>
                  <Modal.Title id="warning-modal-title" className="pull-left">
                    Interchange
                  </Modal.Title>
                  <div
                    className="close-btn"
                    onClick={() => setshowInterchange(false)}
                  >
                    <span className="material-icons">close</span>
                  </div>
                </React.Fragment>
              }
              handleClose={() => setshowInterchange(false)}
            >
              <Interchange
                partNumber={partData.partNumber}
                partBrand={partData.mfgName}
                partType={partData.description}
                partLevel={true}
                catalog={true}
                dualBoxView={false}
                isForCandK={isForCandK}
              />
            </XModal>
          )}
          {showVectorGfx ? (
            <VectorGfxPopup
              engine={rowData.selectedVehicle.engineId}
              group={partData.groupNumber}
              descriptionID={partData.descriptionID}
              make={rowData.selectedVehicle.makeId}
              model={rowData.selectedVehicle.modelId}
              showPopUp={showVectorGfx}
              togglePopup={togglePopup}
              year={rowData.selectedVehicle.year}
              {...(isForCandK ? { selectedGroupDetails: rowData.selectedGroupDetails } : {})}
            //filterPopup={filterPopup}
            />
          ) : null}
           {showLaborGuide && laborGuideDetails.laborItems && (
            <LaborGuide
              partNumber={partData.partNumber}
              openPopup={showLaborGuide}
              closePopup={() => {
                setshowLaborGuide(false);
              }}
              data={laborGuideDetails}
              apiCallStatus={rowData.laborApiCall}
              selectedVehicle={mygarage.selectedVehicle}
              setlaborGuideData={setlaborGuideData}
            />
          )}
          {showCoverToCover ? (
            <CoverToCoverPopup
              openPopup={showCoverToCover}
              url={c2cURL}
              closePopup={togglePopup}
            />
          ) : null}
          {showBuyersGuide ? (
            <XModal
              show={showBuyersGuide}
              showButtons={false}
              title="Buyers Guide"
              customHeader={
                <React.Fragment>
                  <Modal.Title id="warning-modal-title" className="pull-left">
                    Buyers Guide
                  </Modal.Title>
                  <div
                    className="close-btn"
                    onClick={() => togglePopup("buyersGuide")}
                  >
                    <span className="material-icons">close</span>
                  </div>
                </React.Fragment>
              }
              handleClose={() => togglePopup("buyersGuide")}
            >
              <BuyingGuideComponent
                selectedPartNo={partData.partNumber}
                selectedBrand={partData.mfgName}
                partDetails={partData}
                selectedPartType={partData.extendedDescription}
                selectedGroup={rowData.selectedGroupDetails[0].name}
                c2cUrl={partData.c2cUrl}
              />
            </XModal>
          ) : null}
          {showLostSales ? (
            <LostSales
              openPopup={showLostSales}
              closePopup={() => {
                togglePopup("lostSales");
              }}
              selectedPartDetails={[
                {
                  availabilityHour:
                    partData.pricingAvailability.availability &&
                      partData.pricingAvailability.availability.length > 0
                      ? partData.pricingAvailability.availability[0]
                        .availableDuration
                      : null,
                  availableQty:
                    partData.pricingAvailability.availability &&
                      partData.pricingAvailability.availability.length > 0
                      ? partData.pricingAvailability.availability[0]
                        .availableQty
                      : 0,
                  mfgCode: partData.pricingAvailability.mfgCode,
                  costPrice: partData.pricingAvailability.costPrice,
                  desc: partData.description,
                  partNum: partData.partNumber,
                  qty: 0,
                  vehicleInfo: `${rowData.selectedVehicle.year} ${rowData.selectedVehicle.make} ${rowData.selectedVehicle.model} ${rowData.selectedVehicle.engine}`,
                },
              ]}
              dispatch={rowData.dispatch}
              userId={user.userDetails.x2userName}
              source="catalogPage"
              selectedVehicle={rowData.selectedVehicle}
            />
          ) : null}
           {showwarrantyDetails ? (
            <Warranty
              partNumber={partData.partNumber} 
              partData={partData}
              openPopup={showwarrantyDetails}
              selectedVehicle={mygarage.selectedVehicle}
              closePopup={() => {
                setshowwarrantyDetails(false);
              }}
              
            />
          ) : null}
          {showPartDetails && (
            <XModal
              show={showPartDetails}
              handleClose={handlePartDetailsClose}
              title="Part Details"
              handleAction={handlePartDetailsClose}
              isValidValidity={true}
              yesButtonText="Ok"
              noButtonText="Cancel"
              className="part-details-modal"
            >
              <div className="product_detail_lower_section text-center">
                <div className="item_specifics">
                  <span>Item Specifics: </span>
                  <span>{partData.pricingAvailability.itemSpecific}</span>
                </div>
                <div className="per_veh_years_position_container d-flex justify-content-center">
                  <div>
                    <span>Qty per vehicle: </span>
                    <span className="text-black">{partData.perCarQty}</span>
                  </div>
                  <div>
                    <span>Years: </span>
                    <span className="text-black">{partData.years}</span>
                  </div>
                  {partData.position && (
                    <div>
                      <span>Position:</span>
                      <span className="text-black">{partData.position}</span>
                    </div>
                  )}
                </div>
                {!_isEmpty(partData.unansweredSpecifics) &&
                  partData.unansweredSpecifics.length > 0 && (
                    <div className="specific_condition_description">
                      <span>Don’t know: </span>
                      {partData.unansweredSpecifics.map((value, index) => {
                        return (
                          <span className="text-black">
                            {partData.unansweredSpecifics.length == 1
                              ? `${value}`
                              : `${value}; `}
                          </span>
                        );
                      })}
                    </div>
                  )}
                {!_isEmpty(partData.miscellaneousTexts) && (
                  <div className="prod_description d-flex justify-content-center">
                    <span>Description:</span>
                    <p>
                      {partData.miscellaneousTexts
                        ? partData.miscellaneousTexts.toString()
                        : ""}
                    </p>
                  </div>
                )}
                {rowData.appendPart && rowData.partType === "part" && (
                  <div className="col-6 prod_description superseded_alt_parts mx-auto">
                    <p className="maroonColorTxt">
                      {!_isEmpty(rowData.altPartNums)
                        ? `Alternate Part ${rowData.altPartNums}`
                        : ""}
                      {!_isEmpty(rowData.subPartNums)
                        ? `Superseded Part ${rowData.subPartNums}`
                        : ""}
                    </p>
                  </div>
                )}
              </div>
            </XModal>
          )}
          {openWarning && (
            <XModal
              show={openWarning}
              handleClose={handleWarningClose}
              handleAction={handleWarningClose}
              title="Warning"
              className="custom_xmodal"
              isValidValidity={true}
              yesButtonText="Ok"
              noButtonText="Cancel"
            >
              <div className="delete__text text__center">
                <span className="bold">
                  Please enter a quantity less than or equal to{" "}
                  {currentTottalAvailable}
                </span>
              </div>
            </XModal>
          )}
          {showAvailabilityStoreView ?
        (<AvailabilityStoreView
        openPopup={showAvailabilityStoreView}
        closePopup={() => setShowAvailabilityStoreView(false)}
        storeData={storeData}
        />
        )
        : null }
        </Fragment>
      ) : null}
    </div>
  );
}

export default TableViewWrapper;
