import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Accordion, Card } from "react-bootstrap";
import storage from '../../../helpers/storage';
import {userLoginToken, getSMSResults } from "../../../actions/testsms";

const TestSMS = () => {

	const dispatch = useDispatch();	
	const onSubmit = () => {
		dispatch(userLoginToken(loginPayload ===""?JSON.stringify(loginJSON):loginPayload))
				.then((action) => {
					console.log("response: " + JSON.stringify(action.guid));
					setResponse(action);
					storage.set('SMSToken', action.guid, 'local');
					})
					.catch((error) => {	
					  });
	};

	const onSubmitPricing = () => {
		dispatch(getSMSResults(pricingPayload ===""?JSON.stringify(pricingJSON):pricingPayload))
				.then((action) => {
					//console.log("response: " + JSON.stringify(action));
          setResponse();
					setPricingResponse(action)
					})
					.catch((error) => {	
				});
	};

	const [loginPayload, setloginPayload] = useState("");
	const [pricingPayload, setpricingPayload] = useState("");
	const [response, setResponse] = useState();
	const [pricingResponse, setPricingResponse] = useState();

 const loginJSON = {"customer_id": "930038","shipTo_id": "001"};
 const pricingJSON =
 {
  "vin": "1NXBR32E35Z550829",
  "oemPartSKU": [
      {
          "partIdentifier": "220800293",
          "partNumber": "521190Z938"
      },
      {
          "partIdentifier": "220800294",
          "partNumber": "8115002350"
      }       
  ]
};
// 	const refreshPage = ()=>{
//     setTimeout(() => {
//     window.location.reload();
//   }, 5000);
//  }

	return (
    <div className="user-management-container">
      <div className="container">
        <div className="buying__guide__component">
          <Row>
            <Col>
              <div className="align">
                <Form.Label>Enter the Login Payload:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    //maxLength="20"
					onChange={(event) => setloginPayload(event.target.value)}
                    placeholder="Enter Request Body"
                    value={loginPayload ===""?JSON.stringify(loginJSON):loginPayload}
                    autoFocus
                  />
                  <Button
                    onClick={onSubmit}
                    className="search__button"
                    //  disabled={ !partNumber ||_every(validity)}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
		  <Row>
            <Col>
              <div className="align">
                <Form.Label>Enter the Pricing-Availability Payload:</Form.Label>
                <div className="part__search">
				<Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
					onChange={(event) => setpricingPayload(event.target.value)}
                    placeholder="Enter Request Body"
                    value={pricingPayload ===""?JSON.stringify(pricingJSON):pricingPayload}
                    autoFocus
                  />
                  <Button
                    onClick={onSubmitPricing}
                    className="search__button"
                    //  disabled={ !partNumber ||_every(validity)}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
		  <Row>
		  <Col>
		<div style={{background: "whitesmoke"}}>{JSON.stringify(response)}</div>
				</Col>
          </Row>
        </div>
		
		<textarea style={{width: "1300px",height: "333px"}}
          value={JSON.stringify(pricingResponse)}
		  Placeholder={"ResultSet"}
          //onChange={this.handleChange}
        />
      </div>
    </div>
  );
}

export default TestSMS;
