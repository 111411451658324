import React, { useEffect, useRef } from "react";
import { Grid, Skeleton } from "@mui/material";
import { getImageURLCookieDetails } from "helpers/utils";
import PaginationPage from "../Library/PaginationPage";
import { openInNewTab } from "../Helpers/Utils";

interface SearchDataProps {
  searchResult: any[];
  changeDataState: (newState: Partial<any>) => void;
  getParentNames: (node: Partial<any>) => void;
  dataState?: any;
  paginationData: any;
  currentIndex: any;
  loading: boolean;
}

const SearchData: React.FC<SearchDataProps> = ({
  searchResult,
  changeDataState,
  getParentNames,
  dataState,
  paginationData,
  currentIndex,
  loading,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    console.log(searchResult);
  }, [searchResult]);

  const renderSkeletons = () => {
    return Array.from(new Array(10)).map((_, index) => (
      <Grid key={index} item xs={12} sm={6} md={2.4}>
        <Skeleton
          variant="rectangular"
          height={140}
          className="mb-3 rounded-xl"
        />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Grid>
    ));
  };

  const renderContent = () => {
    return searchResult?.map((chapter, index) => (
      <Grid key={index} item xs={12} sm={6} md={2.4} className="">
        <video
          className={`mb-3 rounded-xl cursor-pointer w-full ${currentIndex === index ? "border-4   border-primaryTheme" : ""}`}
          style={{
            border:  currentIndex === index ? "solid 5px #0f5283 " : ""
          }}
          onClick={() =>
            changeDataState({
              selectedChapter: getParentNames(chapter),
              selectedVideo: chapter,
              showSearch: false,
              autoPlay: true,
            })
          }
          ref={videoRef}
          poster={`${
            dataState?.selectedVideo?.Thumbnail?.path
          }?${getImageURLCookieDetails()}`}
        >
          <source
            src={`${
              dataState?.selectedVideo?.VideoLink?.path
            }?${getImageURLCookieDetails()}`}
          />
        </video>
        <p
          onClick={() =>
            changeDataState({
              selectedChapter: getParentNames(chapter),
              selectedVideo: chapter,
              showSearch: false,
            })
          }
          className="text-center text-sm underline cursor-pointer mt-2 text-primaryTheme"
        >
          {chapter?.ContentTitle}
        </p>
        <div className="font-medium text-center ">
          {chapter?.Notes?.text && (
            <span>
              <b>Notes:</b> {chapter?.Notes?.text}
            </span>
          )}
          {chapter?.Notes?.path && (
            <a
              className="ml-2 cursor-pointer"
              onClick={() =>
                openInNewTab(
                  `${chapter?.Notes?.path}?${getImageURLCookieDetails()}`
                )
              }
              href="#"
            >
              Learn More...
            </a>
          )}
        </div>
      </Grid>
    ));
  };

  return (
    <div className=" mx-4 py-2">
      <p className="text-left font-bold text-2xl border-b-4 border-b-[#efefef]">
        {paginationData?.totalItems} Result(s) found for "
        {dataState?.searchedString}"
      </p>
      <Grid container className="border-b-2 border-b-[#efefef]" spacing={2}>
        {loading ? renderSkeletons() : renderContent()}
      </Grid>
      <div className="flex justify-center mt-3">
        {paginationData?.totalPages > 1 && (
          <PaginationPage
            paginationData={paginationData}
            dataState={dataState}
            changeDataState={changeDataState}
          />
        )}
      </div>
    </div>
  );
};

export default SearchData;
