import React, { useEffect, useState , Fragment } from "react";
import {
  Accordion,
  Breadcrumb,
  Card,
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  InputGroup,
  OverlayTrigger, Tooltip
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import _filter from "lodash/filter";
import _every from "lodash/every";
import _isEmpty from "lodash/isEmpty";
import _remove from "lodash/remove";
import _findIndex from "lodash/findIndex";
import _uniqBy from "lodash/uniqBy";

import "./styles.scss";

import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
} from "../../../../../actions/parts";

import {
  addJob,
  getAllPartDescription,
  getAllChemicalDescription,
} from "../../mygarage.thunks";

import { setJobSelected, setJobEventStatus } from "../../mygarage.slice";

import {
  triggerUxCritialErrors,
  triggerCustomUxCriticalError,
} from "../../../../../actions/error";
import FormValidationErrorMessage from "../../../FormValidationErrorMessage/FormValidationErrorMessage";
import XModal from "../../../XModal";
import {
  isJobNameAlphanumeric,
  isMaxLengthLessthan,
  isMinLength,
  timeInUTC,
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnChemicalUSerPref,
  getDefaultDataBasedOnUSerPref,
} from "../../../../../helpers/utils";
import emailUtil from "../../../../../helpers/email/emailUtil";
import JobFilterSection from "./JobFilterSection";
import Placeholder from "../../../Placeholder";
import _cloneDeep from 'lodash/cloneDeep';
import {
  getAllPartCategories,
  getChemicalCategories,
} from "../../../../../actions/parts";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import QueryString from "query-string"

const JobCreation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle;
  const parts = useSelector((state) => state.parts);
  const location = useLocation();
  const userType = user && user.userDetails && user.userDetails?.userType;
  const selectedRecommendedJob = !_isEmpty(parts) && parts.recommendedJobEditJob ;
  const createRecommendedJob = !_isEmpty(parts) && parts.createRecommendedJob;
  const [selectedJobDetails, setSelectedJobDetails] = useState([]);
  const [selectedChemicalDetails, setSelectedChemicalDetails] = useState([]);
  const [recmdJobList, setRecmdJobList] = useState([]);
  const [recjobLoader, setRecJobLoader] = useState(false);
  const [categoryGroupMap, setCategoryGroupMap] = useState([]);
  const [categorySuppliesMap, setCategorySuppliesMap] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [showJobModal, setShowJobModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  let parsed = QueryString.parse(location.search);
  const [jobName, setJobName] = useState(parsed && parsed.recommended === "1" ? selectedRecommendedJob.jobName : null);
  const [jobNameValidity, setJobNameValidity] = useState({
    isJobAlphanumeric: true,
    isJobNameMinLimit: true,
    isJobNameMaxLimit: true,
  });
  const [jobDetailsList, setJobDetailsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedTab(selectedTab);
  },[selectedTab])

  useEffect(() => {
    if (_isEmpty(parts.categoryGroupMap)) {
      getAllPartCategories(dispatch);
    }
    let categoryGroups = parts.categoryGroupMap.map((category) => ({
      ...category,
      selected: false,
      active: false,
      expanded: true,
    }));
    setCategoryGroupMap(categoryGroups);
    if(!_isEmpty(parts.categoryGroupMap) &&
    !_isEmpty(selectedRecommendedJob) && parsed && parsed.recommended === "1" ){
      let selectedJobForEdit = _cloneDeep(selectedRecommendedJob.jobsDetails);
       let sJ = selectedJobForEdit.filter((v)=> v.partType === "catalog");
       let chemical = selectedJobForEdit.filter((v)=> v.partType === "chemicals");
      let catagory =[...parts.categoryGroupMap];
      if(!_isEmpty(sJ)){
      automaticPartGroupSelection(sJ,catagory ,chemical);
      }
    }
  }, [parts.categoryGroupMap]);

  useEffect(() => {
    if (_isEmpty(parts.categorySuppliesMap)) {
      getChemicalCategories(dispatch);
    }
    if (parts.categorySuppliesMap) {
      let categorySupplies = parts.categorySuppliesMap.map((category) => ({
        ...category,
        selected: false,
        active: false,
        expanded: true,
      }));
      setCategorySuppliesMap(categorySupplies);
      if(!_isEmpty(parts.categorySuppliesMap) &&
      !_isEmpty(selectedRecommendedJob) && parsed && parsed.recommended === "1" ){
        let job = [...selectedRecommendedJob.jobsDetails];
        let chemical = job.filter((v)=> v.partType === "chemicals");
        let data = chemical.map((v)=>({
          ...v,
          partTypeId : parseInt(v.partTypeId)
        }))
        let catalog = job.filter((v)=> v.partType === "catalog")
        let catagory =[...parts.categorySuppliesMap];
          if(!_isEmpty(data)){
        automaticChemicalGroupSelection(data ,catagory ,catalog);
           }
      }
    }
  }, [parts.categorySuppliesMap]);


  useEffect(() => {
    if(!_isEmpty(selectedRecommendedJob) && parsed && parsed.recommended === "1" ){
    let catalog   = selectedRecommendedJob.jobsDetails.filter((v)=> v.partType === "catalog")
    let chemical = selectedRecommendedJob.jobsDetails.filter((v)=> v.partType === "chemicals")
    setSelectedJobDetails(catalog);
    setSelectedChemicalDetails(chemical);
    }
  },[parts.recommendedJobEditJob]);

  useEffect(() => {
    setSelectedGroups(selectedGroups);
  });

  useEffect(() => {
    setSelectedCategories(selectedCategories);
  });

  const toggleCategoryAccordion = (event, singleItem) => {
    let matchingCategory = categoryGroupMap.find((data) => {
      return data.code === singleItem.code;
    });
    matchingCategory.expanded = !singleItem.expanded;
    setCategoryGroupMap([...categoryGroupMap]);
  };

  const toggleCategoryGroupAccordion = (event, category, group) => {
    let matchingCategory = categoryGroupMap.find((data) => {
      return data.code === category.code;
    });
    let matchingCategoryGroup = matchingCategory.groups.find((data) => {
      return data.code === group.code;
    });
    matchingCategoryGroup.expanded =
      group.expanded === undefined ? false : !group.expanded;
    setCategoryGroupMap([...categoryGroupMap]);
  };

  const toggleChemicalCategoryAccordion = (event, singleItem) => {
    let matchingCategory = categorySuppliesMap.find((data) => {
      return data.code === singleItem.code;
    });
    matchingCategory.expanded = !singleItem.expanded;
    setCategorySuppliesMap([...categorySuppliesMap]);
  };

  const toggleChemicalCategoryGroupAccordion = (event, category, group) => {
    let matchingCategory = categorySuppliesMap.find((data) => {
      return data.code === category.code;
    });
    let matchingCategoryGroup = matchingCategory.groups.find((data) => {
      return data.id === group.id;
    });
    matchingCategoryGroup.expanded =
      group.expanded === undefined ? false : !group.expanded;
    setCategorySuppliesMap([...categorySuppliesMap]);
  };

  const handleTogglePartCategoryPanel = (event, singleItem) => {
    let matchingCategory = categoryGroupMap.find((data) => {
      return data.code === singleItem.code;
    });
    matchingCategory.active = !singleItem.active;
    matchingCategory.expanded = true;
    setCategoryGroupMap([...categoryGroupMap]);
  };

  const handleToggleChemicalCategoryPanel = (event, singleItem) => {
    let matchingCategory = categorySuppliesMap.find((data) => {
      return data.code === singleItem.code;
    });
    matchingCategory.active = !singleItem.active;
    matchingCategory.expanded = true;
    setCategorySuppliesMap([...categorySuppliesMap]);
  };

  const automaticChemicalGroupSelection =(job ,categoryData ,catelogArray)=>{
    let category = _cloneDeep(categoryData);
    category.map((singleItem)=>{
      singleItem.groups.map((group)=>{
         job.map((g)=>{
        if(g.groupId === +group.id && +g.categoryId === +singleItem.code){
          singleItem.selected = true;
          selectedGroups[`${g.categoryId}-${g.groupId}`] = [];
          getChemicalDescriptions(singleItem, group , true ,job);
        } 
      })
      })
    })
    let finalArray = "";
    let data = category.filter((v) => v.selected);
    if(_isEmpty(catelogArray)){
       finalArray = !_isEmpty(data) &&  data[0] ;
    }else{
      finalArray = !_isEmpty(data) &&  data[data.length -1] ;
    }
    category.map((sl )=>{
      if(!_isEmpty(catelogArray) && sl.selected ){
        sl.selected = false;
      }else
      if(sl.selected && _isEmpty(catelogArray) && !_isEmpty(finalArray) && finalArray.code !== sl.code){
        sl.selected = false;
      }
    })
 
      setCategorySuppliesMap([...category]);
  }
   const automaticPartGroupSelection =(job ,catagoryData,chemical)=>{
    let catagory = _cloneDeep(catagoryData);
    catagory.map((singleItem)=>{
         singleItem.groups.map((group)=>{
         job.map((g)=>{
        if(g.groupId === group.code && g.categoryId === singleItem.code){
          singleItem.selected = true;
          selectedGroups[`${g.categoryId}-${g.groupId}`] = [];
          getPartDescriptions(singleItem, group , true ,job);
        }
      })
      })
    })

    let data = catagory.filter((v) => v.selected);
 
    let finalArray  = !_isEmpty(data)  &&  data[data.length -1] ;
    
    catagory.map((sl )=>{
      if(sl.selected && !_isEmpty(finalArray) && finalArray.code !== sl.code){
        sl.selected = false;
      }
    })

   setCategoryGroupMap([...catagory]);
  }

  const handlePartGroupSelection = (event, partCategory, group) => {
    const isChecked = event.target.checked;
    let isCategorySelected = false;
    let matchingCategory = categoryGroupMap.find((data) => {
      return data.code === partCategory.code;
    });
    if (isChecked) {
      isCategorySelected = true;
      if (!selectedGroups[`${partCategory.code}-${group.code}`]) {
        selectedGroups[`${partCategory.code}-${group.code}`] = [];
        getPartDescriptions(partCategory, group);
        let selectedCatgrs = categoryGroupMap.filter((data) => {
          return data.selected === true;
        });
        selectedCatgrs.map((catg) => {
          catg.expanded = true;
        });
      }
    } else {
      if (selectedGroups[`${partCategory.code}-${group.code}`]) {
        delete selectedGroups[`${partCategory.code}-${group.code}`];
      }
      removeCategoryPartsFromJob(partCategory.code);
      matchingCategory.groups.map((grp) => {
        grp.expanded = true;
        if (
          !isCategorySelected &&
          selectedGroups[`${partCategory.code}-${grp.code}`]
        ) {
          isCategorySelected = true;
        }
      });
    }
    matchingCategory.selected = isCategorySelected;
    setCategoryGroupMap([...categoryGroupMap]);
  };
  const handleRecommendedPartGroupSelection = (event, partCategory, group) => {
    setJobDetailsList([]);
    let category = _cloneDeep(categoryGroupMap);
    let Chemicalcategory = _cloneDeep(categorySuppliesMap);
    const isChecked = event.target.checked;
    let matchingCategory = category.find((data) => {
      return data.code === partCategory.code;
    });
    let gr = matchingCategory.groups.find((v)=>{
      return v.code === group.code;
    })
    setSelectedTab(gr.name);
    if (isChecked) {
      if (!selectedGroups[`${partCategory.code}-${group.code}`]) {
        selectedGroups[`${partCategory.code}-${group.code}`] = [];
        getPartDescriptions(partCategory, group , isChecked );
        category.map((data) => {
      if(data.code !== partCategory.code){
        data.selected = false;
      }else{
        data.selected = true;
      }})

      gr["checked"] = true;
  }
  setCategoryGroupMap(category);
    let Chemicalcategory =_cloneDeep(categorySuppliesMap);
    Chemicalcategory.map((v)=>{
      if(v.selected ){
        v.selected = false;
    }
    })
    setCategorySuppliesMap(Chemicalcategory);
  }
    else {
      if (selectedGroups[`${partCategory.code}-${group.code}`]) {
        delete selectedGroups[`${partCategory.code}-${group.code}`];
      }
    removeCategoryPartsFromJob(partCategory.code);
     matchingCategory.groups.map((grp) => {
      grp.expanded = true;
    });
     gr["checked"] = false;
    _remove(selectedJobDetails, (p) => p.categoryId  === partCategory.code && group.code === p.groupId );
    _remove(recmdJobList, (p) => p.partCategory.code === partCategory.code && group.code === p.group.code );      
    removCatelogeParts(category ,partCategory.code , group.code , Chemicalcategory);
    setRecmdJobList(recmdJobList);
    setSelectedJobDetails(selectedJobDetails);
    setCategoryGroupMap(category);
    setCategorySuppliesMap(Chemicalcategory);
    }
  };

const removCatelogeParts =(category,partCategorCode ,groupCode ,Chemicalcategory , type)=>{
  const partsFiltered = recmdJobList.filter(v => {
    return  v.type === "part";
  });
  const chemicalFiltered = recmdJobList.filter(v => {
    return   v.type === "chemical";
  });
   let partData  = !_isEmpty(partsFiltered) &&  partsFiltered[partsFiltered.length - 1] ;
   let chemical  = !_isEmpty(chemicalFiltered) &&  chemicalFiltered[chemicalFiltered.length - 1] ;
   if(!_isEmpty(partData)){
   selectedGroups[`${partData.partCategory.code}-${partData.group.code}`]  = partData.selectedGroups;
   setJobDetailsList([...partData.jobList])
   setSelectedTab(partData.value);
   }else if(_isEmpty(partData) && !_isEmpty(chemical)){
    selectedGroups[`${chemical.partCategory.code}-${chemical.group.id}`]  = chemical.selectedGroups;
    setJobDetailsList([...chemical.jobList])
    setSelectedTab(chemical.value);
   }
   category.map((v)=>{
    if(v.code === partCategorCode){
      v.selected = false;
    [...v.groups].map((v1)=>{
      if(v1.code === groupCode){
        v1.checked = false;
      } if(!_isEmpty(partData) && v1.code === partData.group.code && partData.type === "part"){
        v1.checked = true;
      }     
    })
  }if(!_isEmpty(partData) &&  v.code === partData.partCategory.code && partData.type === "part"){
    v.selected = true;
  }
  Chemicalcategory.map((v) =>{
    v.selected = false;
    })
  })
  if(_isEmpty(partData) && !_isEmpty(chemical)){
  Chemicalcategory.map((v)=>{
    if(v.code === partCategorCode){
      [...v.groups].map((v1)=>{
      if(!_isEmpty(chemical) && v1.id === chemical.group.id && chemical.type === "chemical"){
        v1.checked = true;
     } })
  }
  if(!_isEmpty(chemical) &&  v.code === chemical.partCategory.code && chemical.type === "chemical"){
    v.selected = true;
  }})}
   setCategoryGroupMap(category);
   setCategorySuppliesMap(Chemicalcategory);
  setRecmdJobList(recmdJobList);
  setSelectedGroups(selectedGroups);
  setSelectedJobDetails(selectedJobDetails)
}
  const getPartDescriptions = (partCategory, group , isChecked ,jobList) => {
    setIsLoading(true);
    const payload = {
      group: `${group.code}`,
      dispatch: dispatch,
    };
    try {
      const getAllPartDescriptionAction = dispatch(
        getAllPartDescription(payload)
      );
      getAllPartDescriptionAction.then((action) => {
        let selectedGroupDescs = [];
        action.payload.map((grp, index) => {
          selectedGroupDescs.push({
            value: grp.id,
            label: grp.name,
          });
        });
        selectedGroups[`${partCategory.code}-${group.code}`] = _uniqBy(
          selectedGroupDescs,
          "value"
        );
        
        if(createRecommendedJob){ 

        !_isEmpty(selectedGroups) &&  Object.keys(selectedGroups).map((key)=> {
          if (selectedGroups[key] !== selectedGroups[`${partCategory.code}-${group.code}`]) {
             delete selectedGroups[key];
          }
        });

        if(selectedGroups[`${partCategory.code}-${group.code}`]){
          let selectedList = !_isEmpty(jobList) && jobList.filter((j)=> {
            return selectedGroups[`${partCategory.code}-${group.code}`].find((item)=> {
              return item.label === j.partTypeDesc && j.partTypeId === item.value;
            })
          })
       recommendedJobArrayPush(isChecked ,group ,partCategory , selectedGroups[`${partCategory.code}-${group.code}`] ,selectedList ,"part" );     
       if(!_isEmpty(selectedList)){
        setJobDetailsList(selectedList); 
       }
      }
      }
        setSelectedGroups(selectedGroups);
        setIsLoading(false);
        checkAllSelectedCategoriesLoaded();
      });
    } catch (error) {}
  };


  const recommendedJobArrayPush = (isChecked ,group ,category , selectedGrp ,jobList , type ) =>{
    if (isChecked ) {
      if(!_isEmpty(recmdJobList)){
        recmdJobList.map((v)=>{
        if((_isEmpty(v.value) || (v.value === ""))){
          v.value = group.name;
          v.type = type;
          v.partCategory = category;
          v.selectedGroups = selectedGrp;
          v.group = group;
        } else
        {
          recmdJobList.push({
           id:Math.random(),
           value :group.name,
           type : type,
           partCategory : category,
           group :group,
           selectedGroups :selectedGrp,
           jobList: jobList ? jobList : "",
         })
        }
      })
    }else{
      recmdJobList.push({
       id:Math.random(),
       value :group.name,
       type : type,
       partCategory : category,
       group :group,
       selectedGroups :selectedGrp,
       jobList: jobList ? jobList : "",
     }) 
    }
  }
   let recommendedJobArr = !_isEmpty(recmdJobList) && _.uniqBy(recmdJobList, "value");
  setRecmdJobList(recommendedJobArr);

  }
  const checkAllSelectedCategoriesLoaded = () => {
    let loading = false;
    Object.keys(selectedGroups).map(function (key, keyIndex) {
      if (selectedGroups[key].length === 0) {
        loading = true;
      }
    });
    setIsLoading(loading);
  };


const removeChemicalParts =(chemcategory,chemicalCategoryCode, groupId , partCategory , type)=>{
  const chemicalJob = recmdJobList.filter(v => {
    return  v.type === "chemical" ;
  });
  const partsFiltered = recmdJobList.filter(v => {
    return  v.type === "part" ;
  });
  let catalog  = !_isEmpty(partsFiltered) &&  partsFiltered[partsFiltered.length - 1] ;
  let chemical  = !_isEmpty(chemicalJob) &&  chemicalJob[chemicalJob.length - 1] ;

  if(!_isEmpty(chemical)){
    selectedGroups[`${chemical.partCategory.code}-${chemical.group.id}`]  = chemical.selectedGroups;
    setJobDetailsList([...chemical.jobList])
    setSelectedTab(chemical.value);
    }else if(_isEmpty(chemical) && !_isEmpty(catalog)){
     selectedGroups[`${catalog.partCategory.code}-${catalog.group.code}`]  = catalog.selectedGroups;
     setJobDetailsList([...catalog.jobList])
     setSelectedTab( catalog.value);
    }

    chemcategory.map((v)=>{
      if(v.code === chemicalCategoryCode){
        v.selected = false;
      [...v.groups].map((v1)=>{
        if(v1.id === groupId){
          v1.checked = false;
        } if(!_isEmpty(chemical) && v1.id === chemical.group.id && chemical.type === "chemical"){
          v1.checked = true;
        }     
      })
    }if(!_isEmpty(chemical) &&  v.code === chemical.partCategory.code && chemical.type === "chemical"){
      v.selected = true;
    }
    partCategory.map((v) =>{
    v.selected = false;
    })
    })
    if(_isEmpty(chemical) && !_isEmpty(catalog)){
      partCategory.map((v)=>{
      if(v.code === chemicalCategoryCode){
        [...v.groups].map((v1)=>{
        if(!_isEmpty(catalog) && v1.code === catalog.group.code && catalog.type === "part"){
          v1.checked = true;
        }     
      })
    }
    if(!_isEmpty(catalog) &&  v.code === catalog.partCategory.code && catalog.type === "part"){
      v.selected = true;
    }
    })
    }
     setCategoryGroupMap(partCategory);
     setCategorySuppliesMap(chemcategory);
     setRecmdJobList(recmdJobList);
     setSelectedGroups(selectedGroups);
     setSelectedChemicalDetails(selectedChemicalDetails) ;
}
  const handleRecommendedChemicalSelection = (event, chemicalCategory, group) => {
    setJobDetailsList([]);
    let chemcategory = _cloneDeep(categorySuppliesMap);
    let partCategory =_cloneDeep(categoryGroupMap);
    const isChecked = event.target.checked;
    let isCategorySelected = false;
    let matchingCategory = chemcategory.find((data) => {
      return data.code === chemicalCategory.code;
    });
    let gr = matchingCategory.groups.find((v)=>{
      return v.id === group.id;
    })
    setSelectedTab(gr.name);
    if (isChecked) {
      isCategorySelected = true;
      if (!selectedGroups[`${chemicalCategory.code}-${group.id}`]) {
        selectedGroups[`${chemicalCategory.code}-${group.id}`] = [];
        getChemicalDescriptions(chemicalCategory, group , isChecked );
        chemcategory.map((data) => {
      if(data.code !== chemicalCategory.code){
        data.selected = false;
      }})
      gr["checked"] = true;
  }
  matchingCategory.selected = isCategorySelected;
  setCategorySuppliesMap([...chemcategory]);
  partCategory.map((v)=>{
    if(v.selected ){
      v.selected = false;
  }
  })
  setCategoryGroupMap(partCategory);
  }else{
    if (selectedGroups[`${chemicalCategory.code}-${group.id}`]) {
      delete selectedGroups[`${chemicalCategory.code}-${group.id}`];
    }

    removeCategoryPartsFromJob(chemicalCategory.code);
    matchingCategory.groups.map((grp) => {
      grp.expanded = true;
    });
    gr["checked"] = false;
    _remove(recmdJobList, (p) => p.partCategory.code === chemicalCategory.code && group.id === p.group.id );      
    _remove(selectedChemicalDetails, (p) => p.categoryId === chemicalCategory.code && group.id === p.groupId);   
    removeChemicalParts(chemcategory ,chemicalCategory.code ,group.id , partCategory);
      setSelectedChemicalDetails(selectedChemicalDetails) ;
     setRecmdJobList(recmdJobList);
     setCategoryGroupMap(partCategory);
     setCategorySuppliesMap(chemcategory);
  }
  };
  const handleChemicalGroupSelection = (event, chemicalCategory, group) => {
    const isChecked = event.target.checked;
    let isCategorySelected = false;
    let matchingCategory = categorySuppliesMap.find((data) => {
      return data.code === chemicalCategory.code;
    });
    if (isChecked) {
      isCategorySelected = true;
      if (!selectedGroups[`${chemicalCategory.code}-${group.id}`]) {
        selectedGroups[`${chemicalCategory.code}-${group.id}`] = [];
        getChemicalDescriptions(chemicalCategory, group ,isChecked);
        let selectedCatgrs = categorySuppliesMap.filter((data) => {
          return data.selected === true;
        });
        selectedCatgrs.map((catg) => {
          catg.expanded = true;
        });
      }
    } else {
      if (selectedGroups[`${chemicalCategory.code}-${group.id}`]) {
        delete selectedGroups[`${chemicalCategory.code}-${group.id}`];
      }
      removeCategoryPartsFromJob(chemicalCategory.code);
      matchingCategory.groups.map((grp) => {
        grp.expanded = true;
        if (
          !isCategorySelected &&
          selectedGroups[`${chemicalCategory.code}-${grp.id}`]
        ) {
          isCategorySelected = true;
        }
      });
    }
    matchingCategory.selected = isCategorySelected;
    setCategorySuppliesMap([...categorySuppliesMap]);
  };

  const getChemicalDescriptions = (chemicalCategory, group  , isChecked ,jobList) => {
    setIsLoading(true);
    const payload = {
      groupCode: [group.id],
      dispatch: dispatch,
    };
    try {
      const getAllChemicalDescriptionAction = dispatch(
        getAllChemicalDescription(payload)
      );
      getAllChemicalDescriptionAction.then((action) => {
        let selectedGroupDescs = [];
        action.payload.items.pagedItems.map((grp, index) => {
          selectedGroupDescs.push({
            value: grp.partDescriptionID,
            label: grp.partDescription,
          });
        });
        selectedGroups[`${chemicalCategory.code}-${group.id}`] = _uniqBy(
          selectedGroupDescs,
          "value"
        );
                
        if(createRecommendedJob){ 

          !_isEmpty(selectedGroups) && Object.keys(selectedGroups).map((key)=> {
           if (selectedGroups[key] !== selectedGroups[`${chemicalCategory.code}-${group.id}`]) {
              delete selectedGroups[key];
           }
         });
         if(selectedGroups[`${chemicalCategory.code}-${group.id}`]){
          let selectedList = !_isEmpty(jobList) && jobList.filter((j)=> {
            return selectedGroups[`${chemicalCategory.code}-${group.id}`].find((item)=> {
              return item.label === j.partTypeDesc ;
            })
          })
         recommendedJobArrayPush(isChecked ,group ,chemicalCategory , selectedGroups[`${chemicalCategory.code}-${group.id}`] , selectedList, "chemical");
        if(!_isEmpty(selectedList)){
         setJobDetailsList(selectedList);  
        }
       }      
       }
        setSelectedGroups(selectedGroups);
        setIsLoading(false);
        checkAllSelectedCategoriesLoaded();
      });
    } catch (error) {}
  };

  const removeCategoryPartsFromJob = (categoryCode) => {
    const multiValue = [...jobDetailsList];

    _remove(multiValue, {
      categoryId: categoryCode,
    });

    setJobDetailsList(multiValue);
  };

  const removePartFromJob = (job) => {
    const multiValue = [...jobDetailsList];

    _remove(multiValue, job);

    setJobDetailsList(multiValue);

   !_isEmpty(recmdJobList) && recmdJobList.map((v)=>{
   !_isEmpty(v.jobList) && v.jobList.map((p)=>{
      if(p.partTypeDesc === job.partTypeDesc){
         _remove(v.jobList, p);
     }
    })
   })
   setRecmdJobList(recmdJobList);
  
  };

  const removeAllPartFromJob = () => {
    setJobDetailsList([]);
    if(createRecommendedJob){
    let category = _cloneDeep(categoryGroupMap);
    let chemical = _cloneDeep(categorySuppliesMap);
      category.map((data) => {
       data.selected = false;
       data.groups.map((v)=>{
        v.checked = false;
       })
    });
    chemical.map((data) => {
      data.selected = false;
      data.groups.map((v)=>{ 
       v.checked = false;
      })
   });
    setRecmdJobList([]);
    setCategoryGroupMap(category);
    setCategorySuppliesMap(chemical)
  }
  };

  const handlePartDescSelection = (
    categoryCode,
    groupCode,
    description,
    type
  ) => {
    const multiValue = [...jobDetailsList];

    let multiValueIndex = _findIndex(multiValue, {
      categoryId: categoryCode,
      groupId: groupCode,
      partTypeId: description.value,
    });
    if (multiValueIndex === -1) {
      multiValue.push({
        categoryId: categoryCode,
        groupId: groupCode,
        jobSearchId: "",
        jobsDetailsId: "",
        partTypeDesc: description.label,
        partTypeId: description.value,
        partType: type === "part" ? "catalog" : "chemicals",
      });
    } else {
      _remove(multiValue, {
        categoryId: categoryCode,
        groupId: groupCode,
        partTypeId: description.value,
      });
    }

    if(createRecommendedJob){
    !_isEmpty(recmdJobList) && recmdJobList.map((v)=>{
      if(v.partCategory.code === categoryCode && (v.group.code === groupCode ||  v.group.id === groupCode)){
          v.jobList = multiValue ;
          setJobDetailsList(multiValue);
      }
     })  
    }
      setJobDetailsList(multiValue);
    
  };

  const handleSaveAsJob = () => {
    setShowJobModal(true);
  };

  const handleAddJob = (recmJob) => {
    validateJobName(jobName, jobNameValidity);
    if (jobDetailsList.length === 0) {
      const errorMsg = {
        heading: `Please add Parts/Chemicals`,
        message: "",
        type: "error",
      };
      triggerCustomUxCriticalError(errorMsg, dispatch);
    } else {
      const {
        userDetails: { x2userName, userId, shipTos, billTo },
      } = user;
      if (_every(jobNameValidity)) {
        const payload = {
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          jobName: jobName,
          recommendedJob: createRecommendedJob ? "Y" : "N" ,
          jobSearchId: ( parsed.recommended === "1" && createRecommendedJob  &&  !_isEmpty(userType) && userType === "IT_INTERNAL") ? selectedRecommendedJob.jobSearchId  : "",
          jobsDetails: ( !_isEmpty(recmJob) && (createRecommendedJob  ||  parsed.recommended === "1")) ? recmJob : jobDetailsList,
          shipToId: shipTos[0].shipToId,
          userTimeZone: "IST", //TODO
          dispatch: dispatch,
        };
        try {
          const addJobAction = dispatch(addJob(payload));
          addJobAction.then((action) => {
            if (action.type && action.type === "addJob/fulfilled") {
              setShowJobModal(false);
              const statusMessageProps = {
                heading: `Job : ${jobName} is created`,
                message: "",
                type: "success",
              };
              triggerCustomUxCriticalError(statusMessageProps, dispatch);
              if (sv && sv.makeId && sv.modelId && sv.engineId) {
                viewSavedJob(recmJob);
              } else {
                const statusMessageProps = {
                  message: "Please select vehicle.",
                  type: "info",
                };
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
                history.push("/");
              }
              setRecJobLoader(false) ;
            }else{
              setRecJobLoader(false) ;
            }
          });
        } catch (error) {
          setRecJobLoader(false) ;
          triggerUxCritialErrors(error, dispatch);
        }
      } else {
        setRecJobLoader(false) ;
        setJobNameValidity(jobNameValidity);
      }
    }
  };

  const viewSavedJob = (recmJob) => {
    const {
      userDetails: { x2userName, userId, shipTos, billTo },
    } = user;
    let JobListData = ( createRecommendedJob && !_isEmpty(userType) && userType === "IT_INTERNAL") ? recmJob : jobDetailsList ;
    const selectedJob = {
      createdBy: x2userName,
      createdDate: timeInUTC(),
      customerId: billTo.billToId,
      jobName: jobName,
      jobSearchId: ( parsed.recommended === "1" && createRecommendedJob  &&  !_isEmpty(userType) && userType === "IT_INTERNAL") ? selectedRecommendedJob.jobSearchId  : "",
      recommendedJob: createRecommendedJob ? "Y" : "N" ,
      jobsDetails: JobListData,
      shipToId: shipTos[0].shipToId,
    };
    dispatch(setJobSelected(selectedJob));
    dispatch(setJobEventStatus({ status: true }));
    dispatch({
      payload: {
        selectedJobDetails: selectedJob,
      },
      type: "JOB_SELECTED",
    });
    const userPreferenceDetail = getPreferenceDataFromStorage();
    const userPrefChemPartByCatDtl =
      getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
    const userPrefPartDtl = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
    if (!_isEmpty(JobListData)) {
      let requestItems = [];
      let requestItemsForChemicals = [];

      JobListData.map((job) => {
        if (job.partType === "catalog") {
          requestItems.push({ groupCode: job.groupId, specificCondition: [] });
        }
        if (job.partType === "chemicals") {
          requestItemsForChemicals.push(job.groupId);
        }
      });
      requestItems = _.uniqBy(requestItems, "groupCode");

      if (!_isEmpty(requestItems)) {
        let groupsSelected = { requestItems };

        let partTypes = _filter(
          jobDetailsList,
          (job) => job.partType === "catalog"
        ).map((item) => {
          return {
            id: item.partTypeId,
            text: item.partTypeDesc,
          };
        });
        dispatch(
          getPartsByMultiGroupOrchestrator(
            sv.year,
            sv.makeId,
            sv.modelId,
            sv.engineId,
            groupsSelected,
            userPrefPartDtl.pageNumber,
            userPrefPartDtl.pageSize,
            userPrefPartDtl.catalogSortBy,
            userPrefPartDtl.catalogOrder,
            { partTypes }
          )
        );
      }
      if (!_isEmpty(requestItemsForChemicals)) {
        dispatch(
          getChemPartByGrp(
            requestItemsForChemicals,
            userPrefChemPartByCatDtl.pageNumber,
            userPrefChemPartByCatDtl.pageSize,
            userPrefChemPartByCatDtl.catalogSortBy,
            userPrefChemPartByCatDtl.catalogOrder
          )
        );
      }
      history.push("/job-view");
    }
  };

  const validateJobName = (name, jobNameValidity) => {
    jobNameValidity.isJobAlphanumeric = isJobNameAlphanumeric(name);
    jobNameValidity.isJobNameMinLimit = isMinLength(name, 1);
    jobNameValidity.isJobNameMaxLimit = isMaxLengthLessthan(name, 50);
  };
  const handleClick = () => {
    recmdJobList.push({
     id:Math.random(),
     type :"",
     value :"",
     value1 :"Please select group",
     partCategory : "",
     group : "",
     jobList: ""
  })
  let data = [...recmdJobList]
  setRecmdJobList(data);
};
  const handleJobNameChange = (e) => {
    const { value } = e.target;
    const jobValue = value.charAt(0);
    let jobString = value;
    if (jobValue === " ") {
      jobString = value.trim();
    }
    setJobName(jobString.toUpperCase());
    validateJobName(jobString, jobNameValidity);
  };

  const handleJobNameBlur = (e) => {
    setJobNameValidity({
      isJobAlphanumeric: true,
      isJobNameMinLimit: true,
      isJobNameMaxLimit: true,
    });
  };

const handleSaveData = ()=>{
  setRecJobLoader(true) ;
  let saveJob =[];
   !_isEmpty(recmdJobList) && 
   recmdJobList.map((v)=>{
    !_isEmpty(v.jobList) && 
    v.jobList.map((p)=>{
      saveJob.push(p)
    })
  })
  if(!_isEmpty(saveJob)){
  handleAddJob(saveJob);
  }else{
    setRecJobLoader(false);
  }
}
const removeParts = (job)=>{
  _remove(recmdJobList ,(j)=> j.id === job.id);
  setRecmdJobList([...recmdJobList]);
}
 const removePartFromRecJob =(job)=>{
  setJobDetailsList([]);
  const category =   [...categoryGroupMap] ;
  const Chemicalcategory =  [...categorySuppliesMap];
  if(job.type === "part"){

  selectedGroups[`${job.partCategory.code}-${job.group.code}`] = _uniqBy(job.selectedGroups,"value"); 
  if (selectedGroups[`${job.partCategory.code}-${job.group.code}`]) {
    delete selectedGroups[`${job.partCategory.code}-${job.group.code}`];
  }
   _remove(recmdJobList, (p) => p.partCategory.code === job.partCategory.code && job.group.code === p.group.code && p.value === job.value);
   _remove(selectedJobDetails, (p) => p.categoryId === job.partCategory.code && job.group.code === p.groupId);
   removCatelogeParts(category,job.partCategory.code ,job.group.code ,Chemicalcategory ,job.type);
}
else if(job.type === "chemical"){
  selectedGroups[`${job.partCategory.code}-${job.group.id}`] = _uniqBy(job.selectedGroups,"value"); 
  if (selectedGroups[`${job.partCategory.code}-${job.group.id}`]) {
    delete selectedGroups[`${job.partCategory.code}-${job.group.id}`];
  }
   _remove(recmdJobList, (p) => p.partCategory.code === job.partCategory.code && job.group.id === p.group.id && p.value === job.value);
   _remove(selectedChemicalDetails, (p) => p.categoryId === job.partCategory.code && job.group.id === p.groupId);
   removeChemicalParts(Chemicalcategory ,job.partCategory.code ,job.group.id, category ,job.type);
  }
  setRecmdJobList(recmdJobList);
  setSelectedChemicalDetails(selectedChemicalDetails);
  setSelectedJobDetails(selectedJobDetails)
  setCategoryGroupMap(category);
  setCategorySuppliesMap(Chemicalcategory);
 }
  
  const recommendedJobList = (job)=>{
    setSelectedTab(job.value);
    const category =   [...categoryGroupMap] ;
    const Chemicalcategory =  [...categorySuppliesMap];
    if(job.type === "part"){
    selectedGroups[`${job.partCategory.code}-${job.group.code}`] = _uniqBy(job.selectedGroups,"value"); 
   !_isEmpty(selectedGroups) &&
    Object.keys(selectedGroups).map((key)=> {
      if (selectedGroups[key] !== selectedGroups[`${job.partCategory.code}-${job.group.code}`]) {
         delete selectedGroups[key];
        } 
      }); 
       !_isEmpty(Chemicalcategory) && Chemicalcategory.map((v)=>{
          if(v.selected || !v.selected){
          v.selected = false;
        }
        }) 
        !_isEmpty(category) && category.map((v)=>{
      if(v.code === job.partCategory.code){
        v.selected = true;
       }else{
        v.selected = false;
       }
    })     
    }
      else
       if(job.type === "chemical"){
        selectedGroups[`${job.partCategory.code}-${job.group.id}`] = _uniqBy(job.selectedGroups,"value"); 
        !_isEmpty(selectedGroups) && 
         Object.keys(selectedGroups).map((key)=> {
          if (selectedGroups[key] !== selectedGroups[`${job.partCategory.code}-${job.group.id}`]) {
             delete selectedGroups[key];
            } }); 
          
             !_isEmpty(category) && category.map((data) => {
              if(data.selected || !data.selected) {
                data.selected = false;
              }
            });

            !_isEmpty(Chemicalcategory) && Chemicalcategory.map((v)=>{
              if(v.code === job.partCategory.code){
              v.selected = true;
            }else{
                v.selected = false;
              }
            }) 
             
      } 
      setSelectedGroups(selectedGroups);
      setJobDetailsList([...job.jobList]);    
      setCategorySuppliesMap([...Chemicalcategory]); 
      setCategoryGroupMap([...category]); 
   
   }  
  const renderNoDataState = () => {
    return (
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">
          No Part/Chemical selected
        </Card.Header>
        <Card.Body>
          <Card.Title>
            Please select from our product and chemicals categories.
          </Card.Title>
        </Card.Body>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Row className="nav-breadcrumb-section breadcrumb-rec" >
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Job</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        { createRecommendedJob  && (
      <Row className="nav-breadcrumb-section1">
      <Col md={5} className="rec_inputTextBox" style={{marginLeft:"300px"}}>
      <label htmlFor="currentPassword">Recommended Job Name : </label>
       <Form.Control
        type="text"
        name="currentPassword"
        id="currentPassword"
        className="rec-input"
        value={jobName}
        onChange={handleJobNameChange}
        onBlur={handleJobNameBlur}
      />
       <FormValidationErrorMessage  
              condition={!jobNameValidity.isJobAlphanumeric && jobNameValidity.isJobNameMinLimit }
              errorMessage={<p className="rec_errorMsg">Job name must be alpha characters only</p>}
            />
            <FormValidationErrorMessage
              condition={!jobNameValidity.isJobNameMinLimit}
              errorMessage={<p className="rec_errorMsg">Job name should have atleast 1 character</p>}
            />
            <FormValidationErrorMessage
              condition={  !jobNameValidity.isJobNameMaxLimit &&jobNameValidity.isJobNameMinLimit }
              errorMessage={<p className="rec_errorMsg">Job name should not exceed 50 characters</p>}
            />
      </Col>
      <Col  style={{marginTop:"7px"}}>
        { recjobLoader ? 
        ( <Button
           className="xl_parts_btn"
          variant="primary"
          disabled
        > <Spinner
            as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
          Loading..
        </Button>)
      
      :   (
          <Button
          disabled={_isEmpty(recmdJobList) || _isEmpty(jobName) || _isEmpty(jobDetailsList) ? true : false}
          className="maroonBtn job_button_text xl_parts_btn"
           onClick={() => handleSaveData() }
           >
          Save Recommended Job
       </Button>)}
       </Col>

 
        </Row>)}
        { createRecommendedJob && (
    <Row className="recJob_dropdown_section">
  {!_isEmpty(recmdJobList) && recmdJobList.map((c) => {
  return (
  <Fragment>
    {!_isEmpty(c.value) ?
    <div className="express-filter">
        <Tabs
        value={selectedTab}
        aria-label="icon position tabs example"
        onChange={() =>{
          setJobDetailsList([]);
          recommendedJobList(c);
          setSelectedTab(c.value);}}
        TabIndicatorProps={{
          style: {
            height: "6px"
           }
          }}          
      >

        <Tab 
         value={c.value}
        style={{ minHeight: "auto", padding: "11px 9px 11px 9px" ,border:"1px solid #8b8b8b" }} 
        label={
        <div>
          <span
          id="close"
          className="recCloseButton"
          onClick={(e) => removePartFromRecJob(c)}
        >
          x
        </span>
        <span    
        onClick={() =>{
         setJobDetailsList([]);
          recommendedJobList(c);
          setSelectedTab(c.value);
        }
          } className="fragment-text" style={{fontSize:"small"}}>
          {c.value}
        </span>
      </div>
          }/> 
      </Tabs>
           

      </div> :
     <>
  <Form.Control
  type="text"
  size="sm"
  defaultValue={c.value}
  value={c.value1 ? c.value1 :c.value}
  autoFocus={false}
  disabled ={true}
  maxLength="150"
  className="job_textBox input__po"
  name="po"
/> 
<span
    id="close"
    className="recJobPartsClose_btn"
    onClick={(e) => removeParts(c)}
  >
    x
  </span></>
}
</Fragment>
);
})}
 {!_isEmpty(recmdJobList) && (
<button className='job_addtext_box' onClick={handleClick}>
<OverlayTrigger
  placement="top"
  delay={{ show: 100, hide: 400 }}
  overlay={<Tooltip>Click to Create new Part Group</Tooltip>}>
<span >+</span>
</OverlayTrigger>
</button>)}</Row>)}
        {jobDetailsList.length > 0 ? (
          <h4 class="text-capitalize">Selected Parts</h4>
        ) : null}
        <Row className={createRecommendedJob ? "job_drop_down_section" : "drop_down_section" }>
          <div class="col-md-11">
            {jobDetailsList.length > 0 ? (
              <div class="col-md-12">
                <div class="selected-parts">
                  <Row>
                    {jobDetailsList.map((job, k) => {
                      return (
                        <Col lg="3" md="3" sm="6" xs="12" className="pb-2 pt-2">
                          <div class="fragment">
                            <div>
                              <span
                                id="close"
                                className="jobPartsClose_btn"
                                onClick={(e) => removePartFromJob(job)}
                              >
                                x
                              </span>
                              <span className="fragment-text">
                                {job.partTypeDesc}
                              </span>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            ) : null}
          </div>

          <div class="col-md-1">
            {(!createRecommendedJob && !_isEmpty(jobDetailsList) ) && (
            <button
              type="button"
              disabled={jobDetailsList.length > 0 ? false : true}
              class="new-search display-prod-btn save-job btn btn-primary mb-1 float-right"
              onClick={(e) => handleSaveAsJob()}
            >
              Save To Job
            </button>)}
            { ((!_isEmpty(recmdJobList) && !_isEmpty(jobDetailsList)) || jobDetailsList.length > 1) ? (
              <button
                type="button"
                class="new-search display-prod-btn save-job btn btn-primary mt-5 float-right"
                onClick={(e) => removeAllPartFromJob()}
              >
                Delete All
              </button>
            ) : (
              ""
            )}
          </div>
        </Row>
        <Row className="product_details_main_container">
          <JobFilterSection
             createRecommendedJob={createRecommendedJob}
            selectedJobDetails={selectedJobDetails}
            selectedChemicalDetails={selectedChemicalDetails}
            togglePartCategoryPanel={handleTogglePartCategoryPanel}
            toggleChemicalCategoryPanel={handleToggleChemicalCategoryPanel}
            partGroupSelection={handlePartGroupSelection}
            handleRecommendedPartGroupSelection={handleRecommendedPartGroupSelection}
            handleRecommendedChemicalSelection={handleRecommendedChemicalSelection}
            chemicalGroupSelection={handleChemicalGroupSelection}
            categoryGroupMap={categoryGroupMap}
            categorySuppliesMap={categorySuppliesMap}
          />

          <Col
            lg="10"
            md="9"
            sm="9"
            xs="12"
            className="right_section partselection_panel"
          >
            <section className="createJobWrapper">
              {_findIndex([...categoryGroupMap], {
                selected: true,
              }) === -1 &&
                _findIndex([...categorySuppliesMap], {
                  selected: true,
                }) === -1 &&
                renderNoDataState()}
              {isLoading ? (
                <Placeholder rows={4} />
              ) : (
                !_isEmpty(categoryGroupMap) &&
                categoryGroupMap.map((partCategory, p) => {
                  return (
                    partCategory.selected && (
                      <Accordion defaultActiveKey="0">
                        <div className="groupAccordionWrapper">
                          <Accordion.Toggle
                            as="div"
                            className="accordionHead"
                            variant="link"
                            eventKey="0"
                            onClick={(e) =>
                              toggleCategoryAccordion(e, partCategory)
                            }
                          >
                            <div
                              class={`groupsWrapper ${
                                partCategory.expanded ? "expanded" : "collapsed"
                              }`}
                            >
                              {partCategory.name}
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <div className="groupsWrapperBody">
                              {!_isEmpty(partCategory.groups) &&
                                partCategory.groups.map(
                                  (partCategoryGroup, g) => {
                                    return (
                                      selectedGroups[
                                        `${partCategory.code}-${partCategoryGroup.code}`
                                      ] && (
                                        <Accordion defaultActiveKey="0">
                                          <Card>
                                            <Accordion.Toggle
                                              as="div"
                                              // onClick={(e) => togglePartCategory()} as={Card.Header}
                                              eventKey="0"
                                              variant="link"
                                              className="accordionHead"
                                              onClick={(e) =>
                                                toggleCategoryGroupAccordion(
                                                  e,
                                                  partCategory,
                                                  partCategoryGroup
                                                )
                                              }
                                            >
                                              <div
                                                class={`groupsWrapper groupWrapper ${
                                                  partCategoryGroup.expanded ===
                                                    undefined ||
                                                  partCategoryGroup.expanded
                                                    ? "expanded"
                                                    : "collapsed"
                                                }`}
                                              >
                                                {partCategoryGroup.name}
                                              </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                              <Card.Body>
                                                <Row className="groupsWrapperBody groupWrapperBody">
                                                  {selectedGroups[
                                                    `${partCategory.code}-${partCategoryGroup.code}`
                                                  ].map((description, k) => {
                                                    return (
                                                      <Col
                                                        lg="4"
                                                        md="4"
                                                        sm="6"
                                                        xs="12"
                                                      >
                                                        <Form.Group>
                                                          <Form.Check
                                                            type="checkbox"
                                                            checked={
                                                              _findIndex(
                                                                [
                                                                  ...jobDetailsList,
                                                                ],
                                                                {
                                                                  categoryId:
                                                                    partCategory.code,
                                                                  groupId:
                                                                    partCategoryGroup.code,
                                                                  partTypeId:
                                                                    description.value,
                                                                }
                                                              ) >= 0
                                                            }
                                                            onChange={(e) => {
                                                              handlePartDescSelection(
                                                                partCategory.code,
                                                                partCategoryGroup.code,
                                                                description,
                                                                "part"
                                                              );
                                                            }}
                                                            label={
                                                              description.label
                                                            }
                                                          />
                                                        </Form.Group>
                                                      </Col>
                                                    );
                                                  })}
                                                </Row>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    )
                  );
                })
              )}
              {isLoading ? (
                <Placeholder rows={4} />
              ) : (
                !_isEmpty(categorySuppliesMap) &&
                categorySuppliesMap.map((chemicalCategory, c) => {
                  return (
                    chemicalCategory.selected && (
                      <Accordion defaultActiveKey="0">
                        <div className="groupAccordionWrapper">
                          <Accordion.Toggle
                            as="div"
                            className="accordionHead"
                            variant="link"
                            eventKey="0"
                            onClick={(e) =>
                              toggleChemicalCategoryAccordion(
                                e,
                                chemicalCategory
                              )
                            }
                          >
                            <div
                              class={`groupsWrapper ${
                                chemicalCategory.expanded
                                  ? "expanded"
                                  : "collapsed"
                              }`}
                            >
                              {chemicalCategory.name}
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <div className="groupsWrapperBody">
                              {!_isEmpty(chemicalCategory.groups) &&
                                chemicalCategory.groups.map(
                                  (chemicalCategoryGroup, g) => {
                                    return (
                                      selectedGroups[
                                        `${chemicalCategory.code}-${chemicalCategoryGroup.id}`
                                      ] && (
                                        <Accordion defaultActiveKey="0">
                                          <Card>
                                            <Accordion.Toggle
                                              as="div"
                                              // onClick={(e) => togglePartCategory()} as={Card.Header}
                                              eventKey="0"
                                              variant="link"
                                              className="accordionHead"
                                              onClick={(e) =>
                                                toggleChemicalCategoryGroupAccordion(
                                                  e,
                                                  chemicalCategory,
                                                  chemicalCategoryGroup
                                                )
                                              }
                                            >
                                              <div
                                                class={`groupsWrapper groupWrapper ${
                                                  chemicalCategory.expanded
                                                    ? "expanded"
                                                    : "collapsed"
                                                }`}
                                              >
                                                {chemicalCategoryGroup.name}
                                              </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                              <Card.Body>
                                                <Row className="groupsWrapperBody groupWrapperBody">
                                                  {selectedGroups[
                                                    `${chemicalCategory.code}-${chemicalCategoryGroup.id}`
                                                  ].map((description, k) => {
                                                    return (
                                                      <Col
                                                        lg="4"
                                                        md="4"
                                                        sm="6"
                                                        xs="12"
                                                      >
                                                        <Form.Group>
                                                          <Form.Check
                                                            type="checkbox"
                                                            checked={
                                                              _findIndex(
                                                                [
                                                                  ...jobDetailsList,
                                                                ],
                                                                {
                                                                  categoryId:
                                                                    chemicalCategory.code,
                                                                  groupId:
                                                                    chemicalCategoryGroup.id,
                                                                  partTypeId:
                                                                    description.value,
                                                                }
                                                              ) >= 0
                                                            }
                                                            onChange={(e) => {
                                                              handlePartDescSelection(
                                                                chemicalCategory.code,
                                                                chemicalCategoryGroup.id,
                                                                description,
                                                                "chemical"
                                                              );
                                                            }}
                                                            label={
                                                              description.label
                                                            }
                                                          />
                                                        </Form.Group>
                                                      </Col>
                                                    );
                                                  })}
                                                </Row>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    )
                  );
                })
              )}
            </section>
          </Col>
        </Row>
        {showJobModal && (
          <XModal
            title="Job Name"
            show={showJobModal}
            className="custom_xmodal"
            handleClose={() => {
              setShowJobModal(false);
              setJobName("");
            }}
            handleAction={handleAddJob}
            yesButtonText="Save"
            noButtonText="Cancel"
            isValidValidity={!_isEmpty(jobName) ? true : false}
          >
            <div className="my-1 col-sm-12">
              <Form.Control
                placeholder="Job Name"
                class="input__default"
                name="jobname"
                maxLength="50"
                value={jobName}
                onChange={handleJobNameChange}
                onBlur={handleJobNameBlur}
              />
            </div>
            <FormValidationErrorMessage
              condition={
                !jobNameValidity.isJobAlphanumeric &&
                jobNameValidity.isJobNameMinLimit
              }
              errorMessage={"Job name must be alpha characters only"}
            />
            <FormValidationErrorMessage
              condition={!jobNameValidity.isJobNameMinLimit}
              errorMessage={"Job name should have atleast 1 character"}
            />
            <FormValidationErrorMessage
              condition={
                !jobNameValidity.isJobNameMaxLimit &&
                jobNameValidity.isJobNameMinLimit
              }
              errorMessage={"Job name should not exceed 50 characters"}
            />
          </XModal>
        )}
      </Container>
    </React.Fragment>
  );
};
export default JobCreation;
