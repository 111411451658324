import { result } from "lodash";
import orderApi from "../services/orderApi";
import { triggerCustomUxCriticalError } from "./error";
import _isEmpty from "lodash/isEmpty";
import email from '../helpers/email/emailUtil';
import storage from "../helpers/storage";
export const actionTypes = {
  CART_VALUES_FETCHED: "CART_VALUES_FETCHED",
  CART_DETAILS_FETCHED: "CART_DETAILS_FETCHED",
  CART_ORDER_PLACED: "CART_ORDER_PLACED",
  CART_API_CALL: "CART_API_CALL",
  SAVE_AS_QUOTE: "SAVE_AS_QUOTE",
  ACXNUM_CART_DATA : "ACXNUM_CART_DATA",
  ACXNUM_CART_DATA_API_CALL : "ACXNUM_CART_DATA_API_CALL",
  TRACK_ORDER_DATA_FETCHED: "TRACK_ORDER_DATA_FETCHED",
  TRACK_ORDER_API_CALL: "TRACK_ORDER_API_CALL",
  TRACK_ADD_TO_CART_STATUS: "TRACK_ADD_TO_CART_STATUS",
  QUOTES_DATA: "QUOTES_DATA",
  CLEAR_QUOTES_DATA: "CLEAR_QUOTES_DATA",
  CART_API_LOADING: "CART_API_LOADING",
  CART_SHIPMETHOD: "CART_SHIPMETHOD",
  ADDTO_CART_WARNING: "ADDTO_CART_WARNING",
  CART_CHECKOUT_FETCHED: "CART_CHECKOUT_FETCHED",
  CART_PLACE_ORDER_WARNING: "CART_PLACE_ORDER_WARNING",
  CART_RESET_TEMER: "CART_RESET_TEMER",
  ADD_API_CALL: "ADD_API_CALL",
  GLOBAL_LOADER: "GLOBAL_LOADER",
  SHIPPING_ADDDRESS_DATA: "SHIPPING_ADDDRESS_DATA",
  SAVE_AS_ADDRESS: "SAVE_AS_ADDRESS",
  DELETE_ADDRESS:"DELETE_ADDRESS",
  ITEM_LOADER: "ITEM_LOADER"
};

export const getCart = (dispatch) => {
  orderApi
    .get(`carts`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          qtyInCart: result.data.itemQty,
          cartCost: result.data.totalCost,
        },
        type: actionTypes.CART_VALUES_FETCHED,
      });
    })
    .catch((error) => {
      console.log(error, "error in get cart api");
    });
};

export const addItemToCart = (selectedPartsObj, dispatch) => {
  orderApi
    .post(`/carts/items`, selectedPartsObj, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      const statusMessageProps = {
        heading: "Item added to cart",
        message: "",
        type: "success",
      };
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      getItemsFromCart(dispatch);
      dispatch({
        payload: {
          selectedAddCartData: [],
         },
         type: actionTypes.SELECTED_ADDCART_DATA,
      });
      dispatch({
        payload: {
          addToCartError: false,
        },
        type: actionTypes.TRACK_ADD_TO_CART_STATUS,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          addToCartError: true,
        },
        type: actionTypes.TRACK_ADD_TO_CART_STATUS,
      });
      if(error && error.errorCode) {
        switch (error.errorCode) {
          case 'E7017':
          case 'E7018':
          case 'E7014':
              dispatch({
               payload: {
                addToCartWarning: true,
              },
              type: actionTypes.ADDTO_CART_WARNING,
              });
            break;
          default:
            break;
        }
      }
    });
};

export const clearCart = (dispatch, cb) => {
  orderApi
    .delete(`/carts`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          resetcarttimer: true,
        },
        type: actionTypes.CART_RESET_TEMER,
      });
      dispatch({
        payload: {
          qtyInCart: 0,
          cartCost: 0,
        },
        type: actionTypes.CART_VALUES_FETCHED,
      });
      dispatch({
        payload: {
          cartDetails: [],
        },
        type: actionTypes.CART_DETAILS_FETCHED,
      });
      dispatch({
        payload: {
          checkoutdetails:  []
        },
        type: actionTypes.CART_CHECKOUT_FETCHED,
      });
      dispatch({
        payload: {
            shipMethod: null,
            cartStore: null,
            sellerPartnerId: null
        },
        type: 'CART_SHIPMETHOD'
      });
      getCart(dispatch);
      getItemsFromCart2(dispatch);
      cb(result);
    })
    .catch((error) => {
      // console.log(error, 'error in clear cart api')
    });
};

export const getItemsFromCart = (dispatch) => {
  dispatch({
    payload: {
      cartFetching: true,
    },
    type: actionTypes.CART_API_CALL,
  });
  orderApi
    .get(`/carts/items`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if(result && result.data && result.data.length > 0) {
        dispatch({
          payload: {
              shipMethod: result.data[0].shipMethod,
              cartStore: result.data[0].store,
              sellerPartnerId: result.data[0].sellPartnerId
          },
          type: 'CART_SHIPMETHOD'
        });
      } else {
        dispatch({
          payload: {
              shipMethod: null
          },
          type: 'CART_SHIPMETHOD'
        });
      }
      getCart(dispatch);
      dispatch({
        payload: {
          cartDetails: result.data || [],
        },
        type: actionTypes.CART_DETAILS_FETCHED,
      });
      dispatch({
        payload: {
          cartFetching: false,
        },
        type: actionTypes.CART_API_CALL,
      });
    })
    .catch((e) => {
      dispatch({
        payload: {
          cartFetching: false,
        },
        type: actionTypes.CART_API_CALL,
      });
    });
};

export const clearCartForStore = (dispatch, cb) => {
  return orderApi
    .delete(`/carts`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      getCart(dispatch);
      getItemsFromCart(dispatch);
      // cb(result);
      dispatch({
        payload: {
          qtyInCart: 0,
          cartCost: 0,
        },
        type: actionTypes.CART_VALUES_FETCHED,
      });
      dispatch({
        payload: {
          cartDetails: [],
        },
        type: actionTypes.CART_DETAILS_FETCHED,
      });
    dispatch({
      payload: {
        checkoutdetails:  []
      },
      type: actionTypes.CART_CHECKOUT_FETCHED,
    });
    dispatch({
      payload: {
          shipMethod: null,
          cartStore: null,
          sellerPartnerId: null
      },
      type: 'CART_SHIPMETHOD'
    });
  })
  .catch((error) => {
    // console.log(error, 'error in clear cart api')
  });
};

export const placeCartOrder = (
  poNum,
  mailNotification,
  toAddress,
  accPartialShip,
  orderComments,
  dispatch
) => {
  dispatch({
    payload: {
      apiLoading: true,
    },
    type: actionTypes.CART_API_LOADING,
  });
  return orderApi
    .post(
      `/orders`,
      {},
      {
        params: {
          dispatch,
          poNum,
          toAddress,
          mailNotification,
          orderComments,
          accPartialShip: !accPartialShip ? "Yes" : "No",
        },
      }
    )
    .then((result) => {
      dispatch({
        payload: {
          orderPlaced: true,
          orderConfirmationNumber: result.data.confNum,
        },
        type: actionTypes.CART_ORDER_PLACED,
      });
      dispatch({
        payload: {
          apiLoading: false,
        },
        type: actionTypes.CART_API_LOADING,
      });
      dispatch({
        payload: {
          cartDetails: [],
        },
        type: actionTypes.CART_DETAILS_FETCHED,
      });
      dispatch({
        payload: {
            shipMethod: null,
            cartStore: null,
            sellerPartnerId: null
        },
        type: 'CART_SHIPMETHOD'
      });
    })
    .catch(() => {
      dispatch({
        payload: {
          apiLoading: false,
        },
        type: actionTypes.CART_API_LOADING,
      });
    });
};

export const deleteItemFromCart = (selectedPartsObj, dispatch) => {
  orderApi
    .put(`/carts/items`, selectedPartsObj, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      getCart(dispatch);
      getItemsFromCart(dispatch);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const saveQuoteForStockOrder = (userName, quoteName, phoneNo, dispatch, mailNotification = false, toAddress = "", poNum, comment,quotePrefix = null) => {
  return orderApi
    .post(
      `/quotes/save/quote`,
      {
        quoteName: quoteName,
        phone: phoneNo,
        userName: userName,
        mailNotification: mailNotification,
        toAddress: toAddress,
        poNumber: poNum,
        comments: comment,
        quotePrefix: quotePrefix
      },
      {
        params: {
          dispatch,
        },
      }
    )
    .then((result) => {
      getCart(dispatch);
      getItemsFromCart(dispatch);
      dispatch({
        payload: {
          quoteSaved: true,
        },
        type: actionTypes.SAVE_AS_QUOTE,
      });

      return result;
    })
    .catch((error) => {
      // console.log(error, 'error in save as quote')
    });
};

export const saveQuote = (userName, quoteName, phoneNo, dispatch) => {
  return orderApi
    .post(
      `/quotes/save?phone=${phoneNo}&quoteName=${quoteName}&userName=${userName}`,
      {},
      {
        params: {
          dispatch,
        },
      }
    )
    .then((result) => {
      getCart(dispatch);
      getItemsFromCart(dispatch);
      dispatch({
        payload: {
          quoteSaved: true,
        },
        type: actionTypes.SAVE_AS_QUOTE,
      });

      return result;
    })
    .catch((error) => {
      // console.log(error, 'error in save as quote')
    });
};

export const editQuoteName = (quoteId, quoteName, dispatch) => {
  return orderApi.put(
    `/quotes/${quoteId}?&quoteName=${quoteName}`,
    {},
    {
      params: {
        dispatch,
      },
    }
  );
};

export const submitLostSales = (userFeedback, dispatch) => {
  orderApi
    .post("/carts/lostsale", userFeedback, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      // const statusMessageProps = {
      //     heading: 'Thank you for the feedback',
      //     message: 'Your cart has been cleared',
      //     type: 'success',
      // }
      // triggerCustomUxCriticalError(
      //     statusMessageProps, dispatch
      // )
    })
    .catch((error) => {
      // console.log(error)
    });
};

export const submitLostSalesFromQuotes = (userFeedback, params, dispatch) => {
  orderApi
    .post(`/carts/lostsale?quoteId=${params.quoteId}`, userFeedback, {
      params: {
        dispatch
      },
    })
};

export const trackOrder = async (orderId, dispatch) => {
  dispatch({
    payload: {
      orderTrackApiOnGoing: true,
    },
    type: actionTypes.TRACK_ORDER_API_CALL,
  });
  orderApi
    .get(`/orders/${orderId}/status`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          orderTrackApiOnGoing: false,
        },
        type: actionTypes.TRACK_ORDER_API_CALL,
      });
      dispatch({
        payload: {
          orderId: orderId,
          trackOrderData: result.data,
        },
        type: actionTypes.TRACK_ORDER_DATA_FETCHED,
      });
    })
    .catch((error) => {
      // console.log("Error in track order api", error)
    });
};

export const quoteDetails = async (orderId, dispatch) => {
  orderApi
    .get(`/quotes/items/${orderId}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      console.log("quotes data -->", result.data);
      dispatch({
        payload: {
          orderId: orderId,
          quoteDetailData: result.data,
        },
        type: actionTypes.QUOTES_DATA,
      });
    })
    .catch((error) => {
      console.log("Error in quotes data api", error);
    });
};

export const clearQuoteDetails = (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_QUOTES_DATA,
  });
};

export const getCartItemsByAcxNum = async (acxNum, dispatch) => {
  dispatch({
    payload: {
      acxNumCartApiCall : true,
    },
    type: actionTypes.ACXNUM_CART_DATA_API_CALL,
  });

  orderApi
    .get(`/carts/items/${acxNum}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          acxNumCartApiCall : false,
        },
        type: actionTypes.ACXNUM_CART_DATA_API_CALL,
      });
      dispatch({
        payload: {
          acxNum : acxNum,
          acxNumCartData : result.data ,
        },
        type: actionTypes.ACXNUM_CART_DATA,
      });
    })
    .catch((error) => {
     console.log("Error in cart history order api", error)
    });
};

export const getCheckoutItems = (dispatch, callCartApi = true) => {
  dispatch({
    payload: {
      cartFetching: true,
    },
    type: actionTypes.CART_API_CALL,
  });
  orderApi
    .get(`carts/checkout/items`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if(result.data && !_isEmpty(result.data.cartItems) && !_isEmpty(result.data.warningMsg)) {
        let warningMessage = result.data && !_isEmpty(result.data.warningMsg) ? result.data.warningMsg : "";
        if(!_isEmpty(warningMessage)){
          const statusMessageProps = {
           heading: "Your cart got refreshed",
           message: warningMessage,
           type: "success",
        };
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
       }
      }
      let showcartRefreshMessage = (result && result.data && !_isEmpty(result.data.cartItems) && result.data.cartItems[0].orderType  !== 'STOCK_ORDER') ? true : false;
      if(callCartApi) {
        //getItemsFromCart2(dispatch, showcartRefreshMessage, "");
        getCart(dispatch);
      }
      if(result && result.data && result.data.cartItems && !_isEmpty(result.data.cartItems)) {
        dispatch({
          payload: {
            checkoutdetails: result.data && result.data.cartItems || []
          },
          type: actionTypes.CART_CHECKOUT_FETCHED,
        });
        /*keeping cart and checkout in same response data */
        dispatch({
          payload: {
            cartDetails: result.data && result.data.cartItems || [],
          },
          type: actionTypes.CART_DETAILS_FETCHED,
        });
        dispatch({
          payload: {
              shipMethod: result.data.cartItems[0].shipMethod,
              cartStore: result.data.cartItems[0].store,
              sellPartnerId: result.data.cartItems[0].sellPartnerId
          },
          type: 'CART_SHIPMETHOD'
        });
      } else {
        dispatch({
          payload: {
            checkoutdetails: result.data && result.data.cartItems || []
          },
          type: actionTypes.CART_CHECKOUT_FETCHED,
        });
        dispatch({
          payload: {
            cartDetails: result.data && result.data.cartItems || []
          },
          type: actionTypes.CART_DETAILS_FETCHED,
        });
        dispatch({
          payload: {
              shipMethod: null
          },
          type: 'CART_SHIPMETHOD'
        });
        dispatch({
          payload: {
            cartDetails: [],
          },
          type: actionTypes.CART_DETAILS_FETCHED,
        });
      }
     // result.data.cartItems.push(result.data.cartItems[0]);
    // if(!callCartApi) {
      dispatch({
        payload: {
          cartFetching: false,
        },
        type: actionTypes.CART_API_CALL,
      });
   // }
    })
    .catch((e) => {
     // getItemsFromCart2(dispatch, false);
      dispatch({
        payload: {
          cartFetching: false,
        },
        type: actionTypes.CART_API_CALL,
      });
    });
};
/* commenting this method content since. similar alredy getting from checkout data response*/
export const getItemsFromCart2 = (dispatch, showToast = true , warningMsg = {} ) => {
  dispatch({
    payload: {
      cartFetching: false,
    },
    type: actionTypes.CART_API_CALL,
  });
  // orderApi
  //   .get(`carts/distribution/items`, {
  //     params: {
  //       dispatch,
  //     },
  //   })
  //   .then((result) => {
  //     result.data.warningMsg = _isEmpty(result.data.warningMsg) ?  warningMsg : result.data.warningMsg;
  //     if(result.data && result.data.cartItems.length > 0 && !_isEmpty(result.data.warningMsg) && showToast) {
  //       let warningMessage = result.data && !_isEmpty(result.data.warningMsg) ? result.data.warningMsg : "No changes were made to your cart. Please continue to place order";
  //       if(warningMessage){
  //         const statusMessageProps = {
  //          heading: "Your cart got refreshed",
  //          message: warningMessage,
  //          type: "success",
  //       };
  //       triggerCustomUxCriticalError(statusMessageProps, dispatch);
  //      }
  //     }
  //     // dispatch({
  //     //   payload: {
  //     //     resetcarttimer: true,
  //     //   },
  //     //   type: actionTypes.CART_RESET_TEMER,
  //     // });
  //     dispatch({
  //       payload: {
  //         cartDetails: result.data && result.data.cartItems || [],
  //       },
  //       type: actionTypes.CART_DETAILS_FETCHED,
  //     });
  //     if(result && result.data && result.data.cartItems && result.data.cartItems.length > 0) {
  //       dispatch({
  //         payload: {
  //             shipMethod:  result.data.cartItems[0].shipMethod,
  //             cartStore:  result.data.cartItems[0].store,
  //             sellPartnerId:  result.data.cartItems[0].sellPartnerId
  //         },
  //         type: 'CART_SHIPMETHOD'
  //       });
  //     } else {
  //       dispatch({
  //         payload: {
  //             shipMethod: null
  //         },
  //         type: 'CART_SHIPMETHOD'
  //       });
  //     }
  //     getCart(dispatch);

  //     dispatch({
  //       payload: {
  //         cartFetching: false,
  //       },
  //       type: actionTypes.CART_API_CALL,
  //     });
  //   })
  //   .catch((e) => {
  //     dispatch({
  //       payload: {
  //         cartFetching: false,
  //       },
  //       type: actionTypes.CART_API_CALL,
  //     });
  //   });
};

export const placeCartOrder2 = (
  poNum,
  mailNotification,
  toAddress,
  accPartialShip,
  orderComments,
  isPlaceOrderDistributed,
  dispatch,
  smsToken,
  deliveryAddressId = "",
  cartTempDeliveryAddress = {},
  sellerNetwork
) => {
  dispatch({
    payload: {
      apiLoading: true,
    },
    type: actionTypes.CART_API_LOADING,
  });
  dispatch({
    payload: {
      cartplaceorderwarning: false,
    },
    type: actionTypes.CART_PLACE_ORDER_WARNING,
  });
  return orderApi
    .post(
      `/orders/placeOrder`,
      !_isEmpty(cartTempDeliveryAddress) ? {deliveryAddress : cartTempDeliveryAddress} : {},
      {
        params: {
          dispatch,
          poNum,
          toAddress,
          mailNotification,
          orderComments,
          accPartialShip: !accPartialShip ? "Yes" : "No",
          isPlaceOrderDistributed,
          smsToken,
          deliveryAddressId,
          sellerNetwork
        },
      }
    )
    .then((result) => {
      if(sellerNetwork && sellerNetwork === "ECOM"){
        console.log("debug log",JSON.stringify(result));
      email.sendEmail({ message: `Place order response - ${JSON.stringify(result)}`}, 200, {
        dispatch,
        URL: 'URL',
        headers: {},
        baseURL: '',
        subject: `DEBUG Purpose - please ignore (place order)`,
      });
    }

      if(result && result.data && result.data.warningMsg == null){
        dispatch({
          payload: {
            orderPlaced: true,
            orderConfirmationNumber: result.data.confNum,
          },
          type: actionTypes.CART_ORDER_PLACED,
        });
        dispatch({
          payload: {
            apiLoading: false,
          },
          type: actionTypes.CART_API_LOADING,
        });
        dispatch({
          payload: {
            cartDetails: [],
          },
          type: actionTypes.CART_DETAILS_FETCHED,
        });
        dispatch({
          payload: {
            checkoutdetails: []
          },
          type: actionTypes.CART_CHECKOUT_FETCHED,
        });
        dispatch({
          payload: {
              shipMethod: null,
              cartStore: null,
              sellerPartnerId: null
          },
          type: 'CART_SHIPMETHOD'
        });
      } else {
        dispatch({
          payload: {
            apiLoading: false,
          },
          type: actionTypes.CART_API_LOADING,
        });
        dispatch({
          payload: {
            cartplaceorderwarning: true,
          },
          type: actionTypes.CART_PLACE_ORDER_WARNING,
        });
        dispatch({
          payload: {
            checkoutdetails: result.data && result.data.cartItems || []
          },
          type: actionTypes.CART_CHECKOUT_FETCHED,
        });
        getItemsFromCart2(dispatch, false);
        getCart(dispatch);
        getShippingAddress(dispatch);
      }
      storage.unset('deliveryaddressLists', 'local');
      dispatch({
        payload: {
          selectedAddressId: "",
          cartTempDeliveryAddress: {}
        },
        type: "SELECTED_ADDRESS_ID",
      });
    })
    .catch(() => {
      dispatch({
        payload: {
          apiLoading: false,
        },
        type: actionTypes.CART_API_LOADING,
      });
    });
};

export const deleteItemFromCart2 = (selectedPartsObj, dispatch, deleteFromCart = false) => {
  orderApi
  .post(`/carts/items/delete`, selectedPartsObj, {
    params: {
      dispatch,
      selectedPartsObj,
      fromPage: deleteFromCart ? "cart": "null"
    },
  })
  .then((result) => {
    dispatch({
      payload: {
        resetcarttimer: true,
      },
      type: actionTypes.CART_RESET_TEMER,
    });
    getCheckoutItems(dispatch, false);
    getCart(dispatch);

  })
  .catch((error) => {
    console.log(error);
  });
};

export const addItemToCart2 = (selectedPartsObj, dispatch, distributionCompleted = true, userData = null, source = null) => {
  let url = selectedPartsObj?.[0]?.isNew 
    ? '/carts/v2/distribution/items' 
    : '/carts/distribution/items';
  //need to remove later
  dispatch({
    payload: {
      itemLoader: true,
    },
    type: actionTypes.ITEM_LOADER,
  });
  const validation = selectedPartsObj.length > 0 && selectedPartsObj.every(item => item.store && item.sellPartnerId && item.shipMethod);
  if(!validation) {
  email.sendEmail({ message: `cart Requestpayload - ${JSON.stringify(selectedPartsObj)} UserInformation - ${JSON.stringify(userData)}` }, 200, {
    dispatch,
    URL: 'URL',
    headers: {},
    baseURL: '',
    subject: `DEBUG Purpose - please ignore (addtoCart debugging) Source -  ${source}`,
  });
  }
  if(!selectedPartsObj?.[0]?.isNew ) {
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch({
    payload: {
      cartFetching: true,
    },
    type: actionTypes.CART_API_CALL,
  });
  dispatch({
    payload: {
      addItemcartFetching: true,
    },
    type: actionTypes.ADD_API_CALL,
  });
}
  dispatch({
    payload: {
      cartDetails: [],
    },
    type: actionTypes.CART_DETAILS_FETCHED,
  });
  orderApi
  .post(url, selectedPartsObj, {
    params: {
      dispatch,
      distributionCompleted,
      fromPage: distributionCompleted ? null : "cart"
    },
   })
  .then((result) => {
    dispatch({
      payload: {
        itemLoader: false,
      },
      type: actionTypes.ITEM_LOADER,
    });
    if(!selectedPartsObj?.[0]?.isNew ) {
    dispatch({
      payload: {
        globalLoader: false,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch({
      payload: {
        addItemcartFetching: false,
      },
      type: actionTypes.ADD_API_CALL,
    });
    dispatch({
      payload: {
        cartFetching: false,
      },
      type: actionTypes.CART_API_CALL,
    });
  }
    getCart(dispatch);
    dispatch({
      payload: {
        resetcarttimer: true,
      },
      type: actionTypes.CART_RESET_TEMER,
    });
    const statusMessageProps = {
      heading: result.data && !_isEmpty(result.data.warningMsg) ? result.data.warningMsg : "Item added to cart",
      message: "",
      type: "success",
    };
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
    dispatch({
      payload: {
        cartDetails: result.data && result.data.cartItems || [],
      },
      type: actionTypes.CART_DETAILS_FETCHED,
    });
    let callCartApi = false;
    getCheckoutItems(dispatch, callCartApi);
    //getItemsFromCart2(dispatch);
    dispatch({
      payload: {
        selectedAddCartData: [],
       },
       type: actionTypes.SELECTED_ADDCART_DATA,
    });
    dispatch({
      payload: {
        addToCartError: false,
      },
      type: actionTypes.TRACK_ADD_TO_CART_STATUS,
    });
  })
  .catch((error) => {
    dispatch({
      payload: {
        itemLoader: false,
      },
      type: actionTypes.ITEM_LOADER,
    });
    const statusMessageProps = {
      heading: error?.errorMessage  || "",       
      type: "error",
    };
    dispatch({
      payload: {
        globalLoader: false,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch({
      payload: {
        addItemcartFetching: false,
      },
      type: actionTypes.ADD_API_CALL,
    });
    dispatch({
      payload: {
        addToCartError: true,
      },
      type: actionTypes.TRACK_ADD_TO_CART_STATUS,
    });
    if(error && error.errorCode) {
      switch (error.errorCode) {
        case 'E7017':
        case 'E7018':
        case 'E7014':
            dispatch({
             payload: {
              addToCartWarning: true,
            },
            type: actionTypes.ADDTO_CART_WARNING,
            });
          break;
        case "E8000":
             triggerCustomUxCriticalError(statusMessageProps, dispatch);
             break;
        default:
          break;
      }
    }
  });

};

export const getCart2 = (dispatch) => {
  orderApi
    .get(`carts`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          qtyInCart: result.data.itemQty,
          cartCost: result.data.totalCost,
        },
        type: actionTypes.CART_VALUES_FETCHED,
      });
    })
    .catch((error) => {
      console.log(error, "error in get cart api");
    });
};
export const getShippingAddress = async (customerId, dispatch) => {
  orderApi
    .get(`/customerShipment/address/${customerId}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      console.log("shipping data -->", result.data);
      dispatch({
        payload: {
          customerId: customerId,
          shippingData: result.data,
        },
        type: actionTypes.SHIPPING_ADDDRESS_DATA,
      });
    })
    .catch((error) => {
      console.log(error, "error in get shipping adddress api");
    });
};
export const deleteAddress = async (addressId,customerId, dispatch) => {
  dispatch({
    payload: {
      globalLoader: true,
    },
    type: actionTypes.GLOBAL_LOADER,
  });
  orderApi
  .post(`/customerShipment/address/delete`, {id:addressId}, {
    params: {
      dispatch,
    },

  })
    .then((result) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      getShippingAddress(customerId,dispatch)
      const addressDeleted = {
        heading: `address is deleted`,
        message: "",
        type: "success",
      };
      triggerCustomUxCriticalError(addressDeleted, dispatch);
    })
    .catch((error) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      console.log(error, "error in deletion");
    });
};

export const saveShippingAddress = (
  customerId,
  companyName,
  contactPersonFirstName,
  contactPersonLastName,
  streetAddress,
  city,
  state,
  country,
  zipCode,
  dispatch
) => {
  return orderApi
    .post(
      `/customerShipment/address/save`,
      {
        customerId: customerId,
        companyName: companyName,
        contactPersonFirstName: contactPersonFirstName,
        contactPersonLastName: contactPersonLastName,
        streetAddress: streetAddress,
        city: city,
        state: state,
        country: country,
        zipCode: zipCode,
      },
      {
        params: {
          dispatch,
        },
      }
    )
    .then((result) => {
      //getShippingAddress(customerId, dispatch);
      console.log("Api call to save address -->", result.data);
      dispatch({
        payload: {
          addressSaved: true,
        },
        type: actionTypes.SAVE_AS_ADDRESS,
      });

      return result;
    })
    .catch((error) => {
      // console.log(error, 'error in save as quote')
    });
};
