import React, { Fragment } from "react";
import {
  Alert,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Button,
  InputGroup,
  Row,
  Spinner,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  validateOnlineIdForm,
  numbersOnlyRegExp,
  alphaCharRegExp,
  noSpecialCharsRegExp,
  decimalNumbersOnlyRegExp,
} from "../../../helpers/utils";
import { registerUser } from "../../../actions/user";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _every from "lodash/every";
import { Redirect } from "react-router-dom";
import storage from "../../../helpers/storage";

import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";

class OnlineIdRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: this.initializeForm(),
      redirectToLogin: false,
      validity: this.intializeValidations(),
      errorPopUp: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    storage.set("authFailed", false, "local");
  }

  initializeForm() {
    this.formObject = {
      firstName: "",
      lastName: "",
      xlpAccNo: "",
      xlpLastInvoiceNo: "",
      xlpLastInvoiceAmount: "",
      email: "",
      confirmEmail: "",
      mobileNo: "",
      countryCode: "+1",
    };
    return this.formObject;
  }

  intializeValidations() {
    this.validity = {
      isFirstNameNotNull: true,
      isFirstnameLengthBelowLimit: true,
      isFirstnameMinLength: true,
      isFirstNameAlphabetic: true,
      isFirstNameValid: true,
      isLastNameNotNull: true,
      isLastnameLengthBelowLimit: true,
      isLastnameMinLength: true,
      isLastNameAlphabetic: true,
      isLastNameValid: true,
      isXlpAccNoNotNull: true,
      isXlpAccNoAlphanumeric: true,
      isXlpAccNoLengthBelowLimit: true,
      isXlpAccNoMinLength: true,
      isValidXlpAccNo: true,
      isXlpLastInvoiceNoAlphanumeric: true,
      isInvoiceAmountNotNull: true,
      isInvoiceNoNotNull: true,
      isXlpLastInvoiceNoLengthBelowLimit: true,
      isXlpLastInvoiceNoMinLength: true,
      isInvoiceAmtLengthBelowLimit: true,
      isInvoiceAmountNumeric: true,
      isValidInvoiceAmount: true,
      isEmailIdNotNull: true,
      isEmailIdLengthBelowLimit: true,
      isEmailValidFormat: true,
      isEmailIdMinLength: true,
      isValidEmail: true,
      isValidConfirmEmail: true,
      isConfirmEmailIdNotNull: true,
      isMobileNoNotNull: true,
      isMobileNoLengthBelowLimit: true,
      isMobileNoMinLength: true,
      isMobileNoNumeric: true,
      isValidMobileNo: true,
    };
    return this.validity;
  }

  componentDidUpdate(prevProps) {
    let { formObject, redirectToLogin, formErrorMessage, errorPopUp } =
      this.state;
    let { dispatch } = this.props;
    let stateChanged = false;
    if (!_isEqual(this.props, prevProps)) {
      if (
        !_isEqual(this.props.userCreationApiCall, prevProps.userCreationApiCall)
      ) {
        stateChanged = true;
      }
      if (!_isEqual(this.props.userCreation, prevProps.userCreation)) {
        if (this.props.userCreation === "success") {
          redirectToLogin = true;
          stateChanged = true;
        }
      }
      if (!_isEqual(this.props.messageFromApi, prevProps.messageFromApi)) {
        formErrorMessage = this.props.messageFromApi.replace(
          /The Parts House/g,
          '<span style="color:#0F5283;"><b>The Parts House</b></span>'
        );
        errorPopUp = true;
        dispatch({
          payload: {
            userCreationSuccess: this.props.userCreation,
            message: "",
            userCreationApiCall: this.props.userCreationApiCall,
          },
          type: "USER_CREATION_COMPLETE",
        });
      }
      if (stateChanged) {
        this.setState({
          ...this.state,
          formObject: {
            ...this.state.formObject,
            ...formObject,
          },
          redirectToLogin,
          formErrorMessage,
          errorPopUp,
        });
      }
    }
  }

  validateForm() {
    let {
      formObject: {
        firstName,
        lastName,
        xlpAccNo,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        email,
        confirmEmail,
        mobileNo,
      },
      validity,
    } = this.state;
    validity = validateOnlineIdForm("firstName", firstName, validity);
    validity = validateOnlineIdForm("lastName", lastName, validity);
    validity = validateOnlineIdForm("xlpAccNo", xlpAccNo, validity);
    validity = validateOnlineIdForm(
      "xlpLastInvoiceNo",
      xlpLastInvoiceNo,
      validity
    );
    validity = validateOnlineIdForm(
      "xlpLastInvoiceAmount",
      xlpLastInvoiceAmount,
      validity
    );
    validity = validateOnlineIdForm("email", email, validity);
    validity = validateOnlineIdForm("confirmEmail", confirmEmail, validity);
    validity.isValidConfirmEmail = _isEqual(email, confirmEmail);
    validity = validateOnlineIdForm("mobileNo", mobileNo, validity);
    this.setState({ ...this.state, validity });
    return _every(validity);
  }

  handleSubmit(e) {
    e.preventDefault();
    let {
      formObject: {
        firstName,
        lastName,
        xlpAccNo,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        email,
        mobileNo,
        countryCode,
      },
    } = this.state;
    let { dispatch } = this.props;
    if (this.validateForm()) {
      let userObj = {
        email: email,
        firstName: firstName,
        invoiceAmount: xlpLastInvoiceAmount,
        invoiceNumber: xlpLastInvoiceNo,
        lastName: lastName,
        mobileNumber: `${countryCode}${mobileNo}`,
        userType: "CUSTOMER_ADMIN",
        xlPartAccountNumber: xlpAccNo,
      };
      registerUser(userObj, dispatch);
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    let { formObject, validity } = this.state;
    const formValue = value.charAt(0);
    let str;
    if (formValue === " ") {
      str = value.trim();
    } else {
      str = value;
      if (name === "confirmEmail") {
        validity.isValidConfirmEmail = _isEqual(formObject.email, str);
        validity = validateOnlineIdForm(name, str, validity);
      } else {
        validity = validateOnlineIdForm(name, str, validity);
      }
    }
    formObject[name] =
      name === "xlpAccNo" || name === "xlpLastInvoiceNo"
        ? str.toUpperCase()
        : str;
    this.setState({
      formObject: {
        ...this.state.formObject,
        ...formObject,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      ...this.state,
    });
  }
  changeCountryCode = (key, e) => {
    let {
      target: { name },
    } = e;
    this.setState({
      ...this.state,
      formObject: { ...this.state.formObject, countryCode: name },
    });
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event, name) => {
    const {
      formObject: {
        firstName,
        lastName,
        mobileNo,
        xlpAccNo,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        email,
      },
    } = this.state;
    switch (name) {
      case "firstName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (firstName && firstName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "lastName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (lastName && lastName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "mobileNo":
        if (
          (numbersOnlyRegExp(event.key) === false ||
            event.keyCode === 32 ||
            (mobileNo && mobileNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "xlpAccNo":
        if (
          (noSpecialCharsRegExp(event.key) ||
            event.keyCode === 32 ||
            (xlpAccNo && xlpAccNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "xlpLastInvoiceNo":
        if (
          (noSpecialCharsRegExp(event.key) ||
            event.keyCode === 32 ||
            (xlpLastInvoiceNo && xlpLastInvoiceNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "xlpLastInvoiceAmount":
        if (
          (decimalNumbersOnlyRegExp(event.key) === false ||
            event.keyCode === 32 ||
            (xlpLastInvoiceAmount && xlpLastInvoiceAmount.length >= 9)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "email":
        if (
          (event.keyCode === 32 || (email && email.length >= 50)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {
      formObject: {
        countryCode,
        firstName,
        lastName,
        xlpAccNo,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        email,
        confirmEmail,
      },
      validity,
      redirectToLogin,
      formErrorMessage,
      errorPopUp,
    } = this.state;

    if (redirectToLogin) {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
    return (
      <Fragment>
        <div className="onlineReqFormContaier">
          <Container>
            <Row></Row>
          </Container>
          <Modal size="lg" show={errorPopUp} className="thank_you_modal">
            <Modal.Body>
              {formErrorMessage && (
                <p className="error-message">
                  {ReactHtmlParser(formErrorMessage)}
                </p>
              )}
              <Button
                onClick={() => {
                  this.setState({ errorPopUp: false });
                }}
              >
                ok
              </Button>
            </Modal.Body>
          </Modal>
          <div className="onlineReqFormContaierWrapper">
            <h2>online id request form</h2>
            <p>Please enter your information in the form below.</p>
            <p>
              <strong>Need help to fill the form? Click</strong>{" "}
              <a
                // href={process.env.REACT_APP_REGISTRATION_DOCUMENT}
                 href="/signup-training"
                target="_blank"
                // download={true}
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
            <Form className="onlineReqForm" onSubmit={this.handleSubmit}>
              <label htmlFor="firstName">First Name</label>
              <Form.Control
                type="text"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "firstName");
                }}
                name="firstName"
                id="firstName"
                maxLength="16"
                value={firstName}
                //  placeholder="Enter Your First Name"
              />
              <FormValidationErrorMessage
                condition={!validity.isFirstNameNotNull}
                errorMessage="Please enter your First Name"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isFirstnameMinLength &&
                  validity.isFirstNameNotNull &&
                  validity.isFirstNameAlphabetic
                }
                errorMessage="First Name should be atleast 2 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isFirstnameLengthBelowLimit &&
                  validity.isFirstNameNotNull
                }
                errorMessage="First Name should not exceed 16 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isFirstNameAlphabetic && validity.isFirstNameNotNull
                }
                errorMessage="First name must contain alpha characters only"
              />
              <label htmlFor="lastName">Last Name</label>
              <Form.Control
                type="text"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "lastName");
                }}
                name="lastName"
                id="lastName"
                value={lastName}
                maxLength="16"
                //  placeholder="Enter Your Last Name"
              />
              <FormValidationErrorMessage
                condition={!validity.isLastNameNotNull}
                errorMessage="Please enter your Last Name"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isLastnameMinLength && validity.isLastNameAlphabetic
                }
                errorMessage="Last Name should be atleast 2 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isLastnameLengthBelowLimit &&
                  validity.isLastNameAlphabetic
                }
                errorMessage="Last Name should not exceed 16 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isLastNameAlphabetic && validity.isLastNameNotNull
                }
                errorMessage="Last name must contain alpha characters only"
              />
              <label htmlFor="xlpAccNo">
                Your{" "}
                <span className="tphblueColorTxt">
                  <b>The Parts House</b>{" "}
                </span>
                Customer Number
              </label>
              <Form.Control
                type="text"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "xlpAccNo");
                }}
                name="xlpAccNo"
                id="xlpAccNo"
                value={xlpAccNo}
                maxLength="10"
                //  placeholder="Enter your XL Parts Accounts No"
              />
              <FormValidationErrorMessage
                condition={!validity.isXlpAccNoNotNull}
                errorMessage="Please enter your Customer number"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpAccNoMinLength && validity.isXlpAccNoNotNull
                }
                errorMessage="Customer number should be atleast 1 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpAccNoAlphanumeric && validity.isXlpAccNoNotNull
                }
                errorMessage="Customer number must be alphanumeric characters only"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpAccNoLengthBelowLimit &&
                  validity.isXlpAccNoNotNull
                }
                errorMessage="Customer number should not exceed 10 characters"
              />
              <label htmlFor="xlpLastInvoiceNo">
                Last{" "}
                <span className="tphblueColorTxt">
                  <b>The Parts House</b>{" "}
                </span>{" "}
                Invoice Number
              </label>{" "}
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip`}>
                    Enter last Sales Invoice Number received from The Parts
                    House. This cannot be a credit invoice.
                  </Tooltip>
                }
              >
                <span class="material-icons">info</span>
              </OverlayTrigger>
              <Form.Control
                type="text"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "xlpLastInvoiceNo");
                }}
                name="xlpLastInvoiceNo"
                id="xlpLastInvoiceNo"
                value={xlpLastInvoiceNo}
                maxLength="10"
                //  placeholder="Enter Your Last XL Parts Invoice Number"
              />
              <FormValidationErrorMessage
                condition={!validity.isInvoiceNoNotNull}
                errorMessage="Please enter your Invoice Number"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpLastInvoiceNoMinLength &&
                  validity.isInvoiceNoNotNull &&
                  validity.isXlpLastInvoiceNoLengthBelowLimit
                }
                errorMessage="Invoice number should be atleast 10 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpLastInvoiceNoLengthBelowLimit &&
                  validity.isXlpLastInvoiceNoMinLength
                }
                errorMessage="Invoice number should not exceed 10 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isXlpLastInvoiceNoAlphanumeric &&
                  validity.isXlpLastInvoiceNoMinLength
                }
                errorMessage="Invoice number must contain alphanumeric characters only"
              />
              <label htmlFor="xlpLastInvoiceAmount">
                Last{" "}
                <span className="tphblueColorTxt">
                  <b>The Parts House</b>{" "}
                </span>{" "}
                Invoice Amount
              </label>
              <Form.Control
                type="text"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "xlpLastInvoiceAmount");
                }}
                name="xlpLastInvoiceAmount"
                id="xlpLastInvoiceAmount"
                value={xlpLastInvoiceAmount}
                maxLength="9"
                //  placeholder="Enter Your Last XL Parts Invoice Amount"
              />
              <FormValidationErrorMessage
                condition={!validity.isInvoiceAmountNotNull}
                errorMessage="Please enter your Invoice Amount"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isInvoiceAmtLengthBelowLimit &&
                  validity.isInvoiceAmountNotNull &&
                  validity.isInvoiceAmountNumeric
                }
                errorMessage="Invoice Amount should not exceed 9 numbers"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isInvoiceAmountNumeric &&
                  validity.isInvoiceAmountNotNull
                }
                errorMessage="Invoice Amount must be numeric only"
              />
              <label htmlFor="email">Email</label>
              <Form.Control
                type="email"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "email");
                }}
                name="email"
                id="email"
                value={email}
                maxLength="50"
                //  placeholder="Enter Your E-mail"
              />
              <FormValidationErrorMessage
                condition={!validity.isEmailIdNotNull}
                errorMessage="Please enter your Email"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isEmailIdLengthBelowLimit &&
                  validity.isEmailIdNotNull &&
                  validity.isEmailValidFormat
                }
                errorMessage="Email should not exceed 50 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isEmailIdMinLength &&
                  validity.isEmailIdNotNull &&
                  validity.isEmailValidFormat
                }
                errorMessage="Email should be atleast 7 characters"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isEmailValidFormat && validity.isEmailIdNotNull
                }
                errorMessage="Email must be in username@domainname.com format"
              />
              <label htmlFor="confirmEmail">Confirm Email</label>
              <Form.Control
                type="email"
                onChange={this.handleInputChange}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "email");
                }}
                name="confirmEmail"
                id="confirmEmail"
                value={confirmEmail}
                maxLength="50"
                autocomplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />
              <FormValidationErrorMessage
                condition={!validity.isValidConfirmEmail}
                errorMessage="Email and confirm email should be same"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isConfirmEmailIdNotNull &&
                  validity.isValidConfirmEmail
                }
                errorMessage="Please confirm Email"
              />
              <label htmlFor="mobileNo">
                <span className="maroonColorTxt">Phone Number </span>
                {/* Number - Please ensure this is a cell phone number; a code will
                be sent to this number to finish registration */}
              </label>
              <InputGroup>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={countryCode}
                  id="input-group-dropdown-1"
                  onSelect={(key, e) => {
                    this.changeCountryCode(key, e);
                  }}
                >
                  <Dropdown.Item name="+91">+91</Dropdown.Item>
                  <Dropdown.Item name="+1">+1</Dropdown.Item>
                </DropdownButton>
                <Form.Control
                  type="text"
                  onChange={this.handleInputChange}
                  name="mobileNo"
                  id="mobileNo"
                  maxLength="11"
                  onKeyDown={(event) => this.handleKeyDown(event, "mobileNo")}
                  //  placeholder="Mobile #"
                />
              </InputGroup>
              {/* <span id="cursor" class="blink error-message">
                <span className="material-icons">arrow_upward</span>
                Make sure this is a mobile number
              </span> */}
              <FormValidationErrorMessage
                condition={!validity.isMobileNoNotNull}
                errorMessage="Please enter your Cell Number"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isMobileNoNumeric && validity.isMobileNoNotNull
                }
                errorMessage="Cell number must be numeric only"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isMobileNoLengthBelowLimit &&
                  validity.isMobileNoNotNull &&
                  validity.isMobileNoNumeric
                }
                errorMessage="Cell number should not exceed 10 numbers"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isMobileNoMinLength &&
                  validity.isMobileNoNumeric &&
                  validity.isMobileNoLengthBelowLimit &&
                  validity.isMobileNoNotNull
                }
                errorMessage="Cell number should be atleast 10 numbers"
              />
              {!this.props.userCreationApiCall ? (
                <Button
                  type="submit"
                  className="xlp-submit-btn"
                  disabled={!_every(validity)}
                >
                  SUBMIT
                </Button>
              ) : (
                <Button className="xlp-submit-btn" variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </Button>
              )}
            </Form>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userCreation: state.user.userCreationSuccess,
    userCreationApiCall: state.user.userCreationApiCall,
    messageFromApi: state.user.messageFromAPi,
  };
}
export default connect(mapStateToProps)(OnlineIdRequestForm);
