/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Dropdown, Overlay, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _ from "lodash";
import { fetchHelpMenuItems } from "./helpmenu.thunk";
import { getImageURLCookieDetails } from "../../../helpers/utils";

import { Fragment } from "react";

import "./styles.scss";
import { useHistory } from "react-router-dom";

const HelpMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [helpMenuItems, setHelpMenuItems] = useState([]);

  const initialFetchHelpMenuItems = () => {
    const payload = {
      dispatch: dispatch,
    };
    try {
      const helpMenuItemsAction = dispatch(fetchHelpMenuItems(payload));
      helpMenuItemsAction.then((action) => {
        if (action.payload) {
          //skip release note item
          const helpItems = action.payload.filter((item) => {
            return (
              item.linkURL &&
              item.linkURL.text &&
              item.linkURL.text !== "PopupHelp"
            );
          });
          setHelpMenuItems(helpItems);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    initialFetchHelpMenuItems();
  }, []);


  const openHelp = (e, helpUrl, helpLinkType, helpMenuItem) => {
    if (helpMenuItem.linkURL.text == "Video Library") {
      history.push('/training')
    } else {
      window.open(
        `${helpUrl}?${getImageURLCookieDetails()}`,
        helpLinkType,
        "width=largeImage.stylewidth,height=largeImage.style.height,resizable=1"
      );
    }
  }

  return (
    <Fragment>
      <Dropdown className="user-dropdown help-dropdown d-flex align-items-center justify-content-center">
        <Dropdown.Toggle as="a" className="help-icon-wrapper d-flex">
          <span className="material-icons help-icon">help</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {helpMenuItems &&
            helpMenuItems.map((helpMenuItem, index) => {
              let helpUrl =
                helpMenuItem.linkURL && helpMenuItem.linkURL.url
                  ? helpMenuItem.linkURL.url
                  : "";
              let headingText =
                _isEmpty(helpMenuItem.heading) && helpMenuItem.linkURL.text
                  ? helpMenuItem.linkURL.text
                  : "";
              let helpLinkType = !_isEmpty(helpMenuItem.linkType)
                ? helpMenuItem.linkType
                : "";
              return (
                <Fragment>
                  {!_isEmpty(helpMenuItem.heading) && (
                    <Fragment>
                      {!_isEmpty(helpMenuItem.linkURL) &&
                        index > 0 &&
                        helpMenuItem.heading !==
                          helpMenuItems[index - 1].heading && (
                          <Fragment>
                            <Dropdown.Item className="help-header">
                              {helpMenuItem.heading}
                            </Dropdown.Item>{" "}
                            <Dropdown.Item>
                              <a
                                class="p-4"
                                onClick={(e) =>
                                  openHelp(e, helpUrl, helpLinkType,helpMenuItem)
                                }
                              >
                                {helpMenuItem.linkURL.text}
                              </a>
                            </Dropdown.Item>{" "}
                          </Fragment>
                        )}
                      {!_isEmpty(helpMenuItem.linkURL) &&
                        index > 0 &&
                        helpMenuItem.heading ===
                          helpMenuItems[index - 1].heading && (
                          <Fragment>
                            <Dropdown.Item>
                              <a
                                class="p-4"
                                onClick={(e) =>
                                  openHelp(e, helpUrl, helpLinkType,helpMenuItem)
                                }
                              >
                                {helpMenuItem.linkURL.text}
                              </a>
                            </Dropdown.Item>{" "}
                          </Fragment>
                        )}
                      {helpMenuItem.heading === "" ||
                        (index < helpMenuItems.length - 1 &&
                          helpMenuItem.heading !==
                            helpMenuItems[index + 1].heading && (
                            <Fragment>
                              <Dropdown.Divider />
                            </Fragment>
                          ))}
                    </Fragment>
                  )}
                  {_isEmpty(helpMenuItem.heading) && (
                    <Fragment>
                      <Dropdown.Item>
                        <a onClick={(e) => openHelp(e, helpUrl, helpLinkType,helpMenuItem)}>
                          {headingText}
                        </a>
                      </Dropdown.Item>{" "}
                      <Dropdown.Divider />
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default HelpMenu;
