import _ from "lodash";
import moment from "moment";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";
import _cloneDeep from "lodash/cloneDeep";
import storage from "./storage";
import jwt_decode from "jwt-decode";
import Catalog from "../static/js/Content/scripts/catalog-v3.0.0";
import { useLocation } from "react-router-dom";

const jwt = require("jsonwebtoken");

const formatAmount = (amt) => {
  return Number(amt).toFixed(2);
};

const formatAmountPrograms = (amt) => {
  return Number(amt).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const deliveryPickupWarning = (userDetails, cartInfo, store, shipMethods) => {
  let Warning = false;
  if (shipMethods != "0001" || shipMethods != "0002") {
    if (
      (cartInfo &&
        cartInfo.checkoutDetails &&
        cartInfo.checkoutDetails.length > 0 &&
        cartInfo.checkoutDetails[0].shipMethod &&
        cartInfo.checkoutDetails[0].shipMethod != null &&
        cartInfo.checkoutDetails[0].shipMethod != shipMethods) ||
      (cartInfo &&
        cartInfo.checkoutDetails &&
        cartInfo.checkoutDetails.length > 0 &&
        cartInfo.checkoutDetails[0].store &&
        cartInfo.checkoutDetails[0].store != null &&
        cartInfo.checkoutDetails[0].store != store)
    ) {
      Warning = true;
    }
  } else {
    let defaultStore =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? userDetails.shipTos[0].location.storeNumber
        : "";
    if (
      cartInfo &&
      cartInfo.checkoutDetails &&
      cartInfo.checkoutDetails.length > 0 &&
      cartInfo.checkoutDetails[0].store &&
      cartInfo.checkoutDetails[0].store != null &&
      cartInfo.checkoutDetails[0].store != defaultStore
    ) {
      Warning = true;
    }
  }
  return Warning;
};

export const handleKeyDown = (event) => {
  const qtyvalue = event.target.value;
  if (
    (numbersOnlyRegExp(event.key) === false ||
      (qtyvalue && qtyvalue.length >= 4) ||
      event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};

export const handleKeyDownFloat = (event) => {
  const qtyvalue = event.target.value;
  if (
    (numbersOnlyRegExp(event.key) === false || event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};

const themeColor = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#84bae2",
    primary50: "#0F5283",
    primary: "#0F5283",
  },
});

export const handleKeyDownAlphabet = (event) => {
  const qtyvalue = event.target.value;
  if (
    (hasAlphabetsOnly(event.key) === false ||
      (qtyvalue && qtyvalue.length >= 4) ||
      event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};

export const handleKeyDownReturns = (event) => {
  const qtyvalue = event.target.value;
  if (
    (isAlphanumericHyphen(event.key) === false || event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};

const handleOtherKeys = (code) => {
  // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
  return (
    code !== 8 &&
    code !== 9 &&
    code !== 37 &&
    code !== 38 &&
    code !== 39 &&
    code !== 40 &&
    code !== 46
  );
};

const getPriceType = (priceType) => {
  let userPreferenceDetail = getPreferenceDataFromStorage();
  switch (priceType) {
    case "listPrice":
      let listPrice = userPreferenceDetail.find((item) => {
        return item.text === "Show List Price" && item.code === "Price";
      });
      return listPrice;
    case "costPrice":
      let costPrice = userPreferenceDetail.find((item) => {
        return item.text === "Show Cost Price" && item.code === "Price";
      });
      return costPrice;
    default:
      break;
  }
};

export const hidePrice = (pageName, priceType) => {
  let bool = false;
  let userPreferenceDetail = getPreferenceDataFromStorage();
  let price = getPriceType(priceType);
  let page = userPreferenceDetail.find((item) => {
    return item.text === "Show Price in Page" && item.code === "Price";
  });

  if (
    price &&
    price.answer &&
    (page.multiAnswer.length === 0 ||
      page.multiAnswer.some(
        (item) => item.code === pageName || item.code === "All"
      ) ||
      page.multiAnswer.some((item) => item === pageName || item === "All"))
  ) {
    if (price.answer.code === "N") {
      bool = true;
    }
  }
  return bool;
};

const getExtendedTotalPrice = (corePrice, costPrice, qty) => {
  let ExtendedCostPrice = corePrice * qty;
  let ExtendedListPrice = costPrice * qty;
  let ExtendedTotalPrice = ExtendedCostPrice + ExtendedListPrice;
  return ExtendedTotalPrice.toFixed(2);
};

const displayQtyChangeMsg = () => {
  let msg = `Quantity updated as per quantity in Case`;
  return msg;
};

const getPartPackQty = (qtyBuyInc, qty, partsQtySum) => {
  let totalMul = 0;
  let total = partsQtySum + 1;
  if (total > qty && qty !== 0) {
    for (let i = 1; i < total; i++) {
      totalMul = qtyBuyInc * i;
      if (totalMul > partsQtySum) {
        return (totalMul = totalMul - qtyBuyInc);
      }
      if (qty <= totalMul) {
        break;
      }
    }
    return totalMul;
  } else {
    return qty;
  }
};

const getShipInfo = (userDetails, cartInfo) => {
  let { shipTos } = userDetails;
  let shipMethod =
    cartInfo && cartInfo.cartShipMethod && cartInfo.cartShipMethod != null
      ? cartInfo.cartShipMethod
      : shipTos && shipTos[0] && shipTos[0].shipMethod && shipTos[0].shipMethod;
  let storeNumber =
    cartInfo && cartInfo.cartStore && cartInfo.cartStore != null
      ? cartInfo.cartStore
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.storeNumber &&
        shipTos[0].location.storeNumber;
  let sellerPartnerId =
    cartInfo && cartInfo.sellerPartnerId && cartInfo.sellerPartnerId != null
      ? cartInfo.sellerPartnerId
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerPartnerId &&
        shipTos[0].location.sellerPartnerId;
  return {
    shipMethod: shipMethod,
    storeNumber: storeNumber,
    sellerPartnerId: sellerPartnerId,
  };
};
/* use this method for getting shipdata instead of using getshipInfo methos writurn in utils note: part of code cleanup*/
const getCartShipDetails = (userDetails, checkoutData) => {
  let { shipTos } = userDetails;
  let shipMethod =
  !_isEmpty(checkoutData) && checkoutData[0].shipMethod
      ? checkoutData[0].shipMethod
      : shipTos && shipTos[0] && shipTos[0].shipMethod && shipTos[0].shipMethod;
  let storeNumber =
  !_isEmpty(checkoutData) && checkoutData[0].store
  ? checkoutData[0].store
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.storeNumber &&
        shipTos[0].location.storeNumber;
  let sellerPartnerId =
  !_isEmpty(checkoutData) && checkoutData[0].sellPartnerId
  ? checkoutData[0].sellPartnerId
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerPartnerId &&
        shipTos[0].location.sellerPartnerId;
  return {
    shipMethod: shipMethod,
    storeNumber: storeNumber,
    sellerPartnerId: sellerPartnerId,
  };
}

const getUserDefaultShipInfo = (userDetails) => {
  let { shipTos } = userDetails;
  let shipMethod =
    shipTos && shipTos[0] && shipTos[0].shipMethod
      ? shipTos[0].shipMethod
      : null;
  let storeNumber =
    shipTos &&
    shipTos[0] &&
    shipTos[0].location &&
    shipTos[0].location.storeNumber
      ? shipTos[0].location.storeNumber
      : null;
  let sellerPartnerId =
    shipTos &&
    shipTos[0] &&
    shipTos[0].location &&
    shipTos[0].location.sellerPartnerId
      ? shipTos[0].location.sellerPartnerId
      : null;
  let storeName =
    shipTos &&
    shipTos[0] &&
    shipTos[0].location &&
    shipTos[0].location.storeName
      ? shipTos[0].location.storeName
      : null;
  return {
    shipMethod: shipMethod,
    storeNumber: storeNumber,
    sellerPartnerId: sellerPartnerId,
    storeName: storeName,
  };
};

const getMetabaseEmbedURL = (locationURL) => {
  // you will need to install via 'npm install jsonwebtoken' or in your package.json
  //need change the code
  let jwt = require("jsonwebtoken");
  let reportData = JSON.parse(storage.get(locationURL, "local"));
  let metabaseTokenInfo = JSON.parse(storage.get("metabaseInfo", "local"));
  let metabaseConfig = reportData.configData?.config;
  let METABASE_SITE_URL = metabaseConfig.site_url
    ? metabaseConfig.site_url
    : "https://metabase-dev.xlparts.com";
  let secretKeyExpireTime =
    metabaseTokenInfo != null && metabaseTokenInfo.expTime
      ? metabaseTokenInfo.expTime
      : Math.round(Date.now() / 1000) + 10 * 60;
  let METABASE_SECRET_KEY =
    metabaseTokenInfo != null && metabaseTokenInfo.secretKey
      ? metabaseTokenInfo.secretKey
      : "";
  const reportInfo = JSON.parse(storage.get(locationURL, "local"));
  let userTypes = reportInfo?.configData?.user_types;
  // you will need to install via 'npm install jsonwebtoken' or in your package.json
  let isVisible = userTypes.some((item) => item === reportInfo.loginUserType);
  let dateString = "#bordered=true&titled=true";
  if (
    reportInfo?.configData?.question[0]?.date_setting &&
    !_isEmpty(reportInfo?.configData?.question[0]?.date_setting)
  ) {
    dateString = getDateInfo(reportInfo?.configData?.question[0]?.date_setting);
  }
  if (isVisible) {
    var payload = {
      resource: {
        dashboard: reportInfo?.configData?.question[0]?.dashboard_no,
      },
      params: {},
      exp: secretKeyExpireTime, // 10 minute expiration
    };

    var token = jwt.sign(payload, METABASE_SECRET_KEY);
    var iframeUrl =
      METABASE_SITE_URL + "/embed/dashboard/" + token + dateString;
  }

  return iframeUrl;
};

const getDateInfo = (dateinfo) => {
  /* for last 7 days */
  const last7days = new Date().setDate(new Date().getDate() - 6);
  let todayDate = new Date().toISOString();
  let minus7Days = new Date(last7days).toISOString();
  let _startDate = moment(minus7Days).format("YYYY-MM-DD");
  let _endDate = moment(todayDate).format("YYYY-MM-DD");
  let firstDayOfCurrentMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  let firstDayOfCurrentMonthString = new Date(
    firstDayOfCurrentMonth
  ).toISOString();
  let firstDateOfCurrentMonth = moment(firstDayOfCurrentMonthString).format(
    "YYYY-MM-DD"
  );
  let requestParams = dateinfo.params;
  switch (dateinfo.defaultDate) {
    case "currentMonth":
      return (
        "?" +
        `${requestParams[0].name}` +
        "=" +
        `${firstDateOfCurrentMonth}` +
        "&" +
        `${requestParams[1].name}` +
        "=" +
        `${_endDate}` +
        "#bordered=true&titled=true"
      );
    case "last7Days":
      return (
        "?" +
        `${requestParams[0].name}` +
        "=" +
        `${_startDate}` +
        "&" +
        `${requestParams[1].name}` +
        "=" +
        `${_endDate}` +
        "#bordered=true&titled=true"
      );
    default:
      return "#bordered=true&titled=true";
  }
};

const isTokenRefreshRequired = (token) => {
  let decodedJwtToken = jwt_decode(token);
  const fiveMinBeforExp = new Date(decodedJwtToken.exp * 1000 - 5 * 60000);
  let now = new Date();
  return moment(fiveMinBeforExp).isBefore(now);
};

const isMetabaseTokenRefreshRequired = (metaData) => {
  let expTime = Math.round(Date.now() / 1000) + 10 * 60;
  let secretkey = metaData && metaData.data ? metaData.data : "";
  let metaBaseInfo = { TokenexpTime: expTime, secretKey: secretkey };
  storage.set("metabaseInfo", metaBaseInfo || {}, "local");
};

const getImageURLCookieDetails = () => {
  const urlCookie = storage.get("imageURLCookie", "local") || "{}";
  const imageURLCookie = JSON.parse(urlCookie);
  const imageUrlCookieDetails = `Policy=${imageURLCookie["CloudFront-Policy"]}&Signature=${imageURLCookie["CloudFront-Signature"]}&Key-Pair-Id=${imageURLCookie["CloudFront-Key-Pair-Id"]}`;
  return imageUrlCookieDetails;
};

export const getImageURLCookie = () => {
  const urlCookie = storage.get("imageURLCookie", "local") || "{}";
  const imageURLCookie = JSON.parse(urlCookie);
  return imageURLCookie;
};
const isSmsUser = () => {
  const jwtToken = storage.get("authToken", "local") || "";
  if (_isEmpty(jwtToken)) {
    return false;
  } else {
    let decodedJwtToken = jwt_decode(jwtToken);
    const isSmsUser =
      decodedJwtToken &&
      decodedJwtToken.user_details &&
      decodedJwtToken.user_details.userType === "SMS_WEB";
    return isSmsUser;
  }
};

const setImageURLCookie = (cookieData) => {
  storage.set("imageURLCookie", cookieData || {}, "local");
};

const setSMSUserSessionDetails = (sessionData) => {
  storage.set("smsUserSessionData", sessionData || {}, "local");
};

const hideReleaseNotes = () => {
  storage.set("showReleaseNotes", false, "local");
};

const isImageURLCookieValid = () => {
  try {
    let isValid = false;
    const imageURLCookie = getImageURLCookie();
    if (!imageURLCookie["CloudFront-Policy"]) {
      return false;
    }
    const cloudFrontPolicy = atob(
      imageURLCookie["CloudFront-Policy"]
        .replace("-", "+")
        .replace("~", "/")
        .replace("_", "=")
    );
    const decodedCFPolicy = JSON.parse(cloudFrontPolicy);
    if (
      decodedCFPolicy["Statement"] &&
      decodedCFPolicy["Statement"].length > 0 &&
      decodedCFPolicy["Statement"][0]["Condition"] &&
      decodedCFPolicy["Statement"][0]["Condition"]["DateLessThan"] &&
      decodedCFPolicy["Statement"][0]["Condition"]["DateLessThan"][
        "AWS:EpochTime"
      ]
    ) {
      const CFPolicyExpiryEpoch =
        decodedCFPolicy["Statement"][0]["Condition"]["DateLessThan"][
          "AWS:EpochTime"
        ];
      const CFPolicyExpiryDate = new Date(
        CFPolicyExpiryEpoch * 1000 - 5 * 60000
      ); // 5 mins before expiry
      let now = new Date();
      isValid = moment(now).isBefore(CFPolicyExpiryDate);
    }
    return isValid;
  } catch (error) {
    return false;
  }
};

const showReleaseNotes = () => {
  const showReleaseNotes = storage.get("showReleaseNotes", "local");
  return showReleaseNotes === "true";
  // return false;
};

const buildQueryUrl = (payload) => {
  const params = [];

  for (const k in payload) {
    if (payload.hasOwnProperty(k) && k != "dispatch") {
      const element = payload[k];
      params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
    }
  }
  return params.join("&");
};

const populateSearchDropdownOptions = (listData) => {
  if (!_.isEmpty(listData)) {
    return getDropDownOptionFromApiData(
      listData,
      (item) => (item.vehicleMake ? item.vehicleMake : item.code),
      (item) => `${item.name}`,
      (item) => false,
      (item) => item.code
    );
  }
  return null;
};
const populateAvailabilityOptions = (availability) => {
  if (!_.isEmpty(availability)) {
    return getDropDownOptionFromApiData(
      availability,
      (item) => item.availableDuration,
      (item) =>
        `In ${item.availableDuration} hour (${item.availableQuantity}) Available`,
      (item) => false
    );
  }
  return null;
};

const getDropDownOptionFromApiData = (
  data,
  getValue,
  textFormat,
  isDisabled,
  extraField,
  getId
) => {
  const options = [];
  data.map((item) => {
    const eachOption = {
      disabled: isDisabled(item),
      id: getValue(item),
      label: textFormat(item),
      // type: item.type,
      value: getValue(item),
    };
    if (!_.isEmpty(extraField)) {
      extraField.map((field) => {
        eachOption[field.key] = field.getValue(item);
      });
    }
    options.push(eachOption);
  });
  return options;
};
// Check if input is blank
const isInputBlank = (input) => {
  return input === "" || input === null || input === undefined;
};
// Check if input is a valid positive number
const isValidPositiveNumber = (number) => {
  return number > 0 && !isNaN(number);
};
const isValidAvailableQty = (number, availableQuantity) => {
  return number <= availableQuantity && !isNaN(number);
};
// const isValidLength = (value, reqLength = 1) => {
//     return value.length > reqLength;
// };
const isLengthLessthan = (str, maxLimit) => {
  return str && str.length <= maxLimit;
};
const isMaxLengthLessthan = (str, maxLimit) => {
  return _.isEmpty(str) || str.length <= maxLimit;
};
const isNumberLessthan = (value, maxLimit) => {
  const strVal = value.toString();
  return strVal && strVal.length <= maxLimit;
};
const hasNumberMinLength = (value, minLimit) => {
  const strVal = value.toString();
  return strVal && strVal.length >= minLimit;
};
const hasMinLength = (str, minLimit) => {
  return str && str.length >= minLimit;
};
const isMinLength = (str, minLimit) => {
  return !_.isEmpty(str) && str.length >= minLimit;
};
const hasAlphabetsOnly = (str) => {
  return /^[A-Za-z\s.]+$/.test(str);
};
const isAlphanumericHyphen = (str) => {
  return /^[a-zA-Z0-9-]+$/.test(str);
};
const isAlphanumericHyphenwithdotAndForwardSlash = (str) => {
  return /^[a-zA-Z0-9-./]+$/.test(str);
};
const isAlphanumeric = (str) => {
  return /^[a-zA-Z0-9]+$/.test(str);
};
const isJobNameAlphanumeric = (str) => {
  return /^[a-zA-Z0-9_\s-]+$/.test(str);
};
const hasSpecialChars = (str) => {
  return /^[!@#\$%\^\&*\)\(+=._-]+$/g.test(str);
};
const hasFormatForPassword = (str) => {
  var expression = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*<>{}(),;':])(?=.{8,})"
  );
  return expression.test(str);
};
const isNumeric = (str) => {
  return /^[0-9]+$/.test(str);
};
const limitDecimalPoint = (value) => {
  return /^\d+(\.\d{1,2})?$/.test(value);
};
const hasNumericDecimal = (value) => {
  return /^[0-9]\d*(\.\d+)?$/.test(value);
};
const hasWhiteSpace = (str) => {
  return /^\S{1,}$/.test(str);
};
const hasAlphabetHyphen = (str) => {
  var expression = new RegExp("^[A-Za-z0-9_-]*$");
  return expression.test(str);
};
// const hasInputInteger = (value) => {
//     var expression = new RegExp("^[1-9]\d*$");
//     console.log(expression);
//     return(expression.test(value));
// }
const hasAlphaSpecialIntChar = (value) => {
  return /^[ A-Za-z0-9_@./#&!()$*%/<>?,;:`~{}|^"+='-]*$/.test(value);
};
const hasQuoteAlphaSpecialIntChar = (value) => {
  return /^[^-\s][ A-Za-z0-9_@./#&!()$*%/<>\\[\]?,;:`~{}|^"+='-]*$/.test(value);
};
const hasNumericSpecialIntChar = (value) => {
  return /^[0-9_@./#&!()$*%/<>?,;`~{}|^"+='-]*$/.test(value);
};
const returnNumbersOnlyRegExp = (value) => {
  return /^[0-9-u-vU-V]+$/.test(value);
};
const numbersOnlyRegExp = (value) => {
  return /^[^a-zA-Z*|\":<>[\]{}`?!#%^*_+\\()';@&$.,/=-]+$/.test(value);
};
const decimalNumbersOnlyRegExp = (value) => {
  return /^[^a-zA-Z*|\":<>[\]{}`?!#%^*_+\\()';@&$,/=-]+$/.test(value);
};
const alphaCharRegExp = (value) => {
  return /^[^0-9*|\":<>[\]{}`!#?%^*_+\\()';@&$.,/=]+$/.test(value);
};
const noSpecialCharsRegExp = (value) => {
  const noSpecialChar = /^[*|\":<>[\]{}`!#?%^_+\\()';@&$.,/=-]+$/.test(value);
  return noSpecialChar;
};

const validateOnlineIdForm = (field, value, validity) => {
  const emailRegex = new RegExp(
    //  /^[a-zA-Z0-9]+([\w\.\+\-\_])*([a-zA-Z0-9])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,}$/
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  switch (field) {
    case "firstName":
      validity.isFirstNameNotNull = !isInputBlank(value);
      validity.isFirstnameLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isFirstnameMinLength = isMinLength(value, 2);
      validity.isFirstNameAlphabetic = hasAlphabetsOnly(value);
      validity.isFirstNameValid = _.every(
        _.pick(validity, [
          "isFirstNameNotNull",
          "isFirstnameLengthBelowLimit",
          "isFirstnameMinLength",
          "isFirstNameAlphabetic",
        ])
      );
      break;
    case "lastName":
      validity.isLastNameNotNull = !isInputBlank(value);
      validity.isLastnameLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isLastnameMinLength = isMinLength(value, 2);
      validity.isLastNameAlphabetic = hasAlphabetsOnly(value);
      validity.isLastNameValid = _.every(
        _.pick(validity, [
          "isLastNameNotNull",
          "isLastnameLengthBelowLimit",
          "isLastnameMinLength",
          "isLastNameAlphabetic",
        ])
      );
      break;
    // case 'title':
    //     validity.isValidTitle = !isInputBlank(value)
    //     break;
    case "xlpAccNo":
      validity.isXlpAccNoNotNull = !isInputBlank(value);
      validity.isXlpAccNoAlphanumeric = isAlphanumeric(value);
      validity.isXlpAccNoLengthBelowLimit = isMaxLengthLessthan(value, 10);
      validity.isXlpAccNoMinLength = isMinLength(value, 1);
      validity.isValidXlpAccNo = _.every(
        _.pick(validity, [
          "isXlpAccNoNotNull",
          "isXlpAccNoAlphanumeric",
          "isXlpAccNoLengthBelowLimit",
          "isXlpAccNoMinLength",
        ])
      );
      break;
    // case 'companyName':
    //     validity.isValidCompanyName = !isInputBlank(value);
    //     break;
    case "xlpLastInvoiceNo":
      validity.isInvoiceNoNotNull = !isInputBlank(value);
      validity.isXlpLastInvoiceNoAlphanumeric = isAlphanumeric(value);
      validity.isXlpLastInvoiceNoLengthBelowLimit = isMaxLengthLessthan(
        value,
        10
      );
      validity.isXlpLastInvoiceNoMinLength = isMinLength(value, 10);
      break;
    case "xlpLastInvoiceAmount":
      validity.isInvoiceAmountNotNull = !isInputBlank(value);
      validity.isInvoiceAmtLengthBelowLimit = isMaxLengthLessthan(value, 9);
      validity.isInvoiceAmountNumeric = hasNumericDecimal(value);
      validity.isValidInvoiceAmount = _.every(
        _.pick(validity, [
          "isInvoiceAmountNotNull",
          "isInvoiceAmtLengthBelowLimit",
          "isInvoiceAmountNumeric",
        ])
      );
      break;
    // case 'address':
    //     validity.isValidAddress = !isInputBlank(value);
    //     break;
    case "email":
      validity.isEmailIdNotNull = !isInputBlank(value);
      validity.isEmailIdLengthBelowLimit = isMaxLengthLessthan(value, 50);
      validity.isEmailIdMinLength = isMinLength(value, 7);
      validity.isEmailValidFormat = !_.isEmpty(value)
        ? emailRegex.test(value)
        : true;
      validity.isValidEmail = _.every(
        _.pick(validity, [
          "isEmailIdNotNull",
          "isEmailIdLengthBelowLimit",
          "isEmailIdMinLength",
          "isEmailValidFormat",
        ])
      );
      break;
    case "confirmEmail":
      validity.isConfirmEmailIdNotNull = !isInputBlank(value);
      break;
    // case 'phoneNo':
    //     validity.isValidPhoneNo = !isInputBlank(value);
    //     break;
    case "mobileNo":
      validity.isMobileNoNotNull = !isInputBlank(value);
      validity.isMobileNoLengthBelowLimit = isMaxLengthLessthan(value, 10);
      validity.isMobileNoMinLength = isMinLength(value, 10);
      validity.isMobileNoNumeric = isNumeric(value);
      validity.isValidMobileNo = _.every(
        _.pick(validity, [
          "isMobileNoNotNull",
          "isMobileNoLengthBelowLimit",
          "isMobileNoMinLength",
          "isMobileNoNumeric",
        ])
      );
      break;
    // case 'comments':
    //     validity.isValidComments = !isInputBlank(value);
    //     break;
    case "username":
      validity.isUserNameNotNull = !isInputBlank(value);
      validity.isUserNameLengthBelowLimit = isMaxLengthLessthan(value, 50);
      validity.isUserNameMinLength = isMinLength(value, 7);
      validity.isUserNameValidFormat = !_.isEmpty(value)
        ? emailRegex.test(value)
        : true;
      validity.isValidUserName = _.every(
        _.pick(validity, [
          "isUserNameNotNull",
          "isUserNameLengthBelowLimit",
          "isUserNameMinLength",
          "isUserNameValidFormat",
        ])
      );
      break;
    case "password":
      validity.isPasswordNotNull = !isInputBlank(value);
      // validity.doesPwdhaveFormat = !_.isEmpty(value) ? hasFormatForPassword(value) : false;
      // validity.isValidPassword = _.every(
      //   _.pick(validity, [
      //     "isPasswordNotNull",
      //     'doesPwdhaveFormat',
      //   ])
      // );
      validity.doesPwdhaveFormat = !_.isEmpty(value) ? value : false;
      validity.isValidPassword = _.every(
        _.pick(validity, ["isPasswordNotNull"])
      );
      break;
    case "newPassword":
      validity.isPasswordNotNull = !isInputBlank(value);
      // validity.doesPwdhaveFormat = !_.isEmpty(value)
      //   ? hasFormatForPassword(value)
      //   : false;
      // validity.isValidPassword = _.every(
      //   _.pick(validity, ["isPasswordNotNull", "doesPwdhaveFormat"])
      // );
      validity.doesPwdhaveFormat = !_.isEmpty(value) ? value : false;
      validity.isValidPassword = _.every(
        _.pick(validity, ["isPasswordNotNull"])
      );
      break;
    case "confirmPassword":
      validity.isConfirmPasswordNotNull = !isInputBlank(value);
      break;
    case "smsConfirmationCode":
      validity.isConfirmationNotNull = !isInputBlank(value);
      break;
    case "smsConfirmationCodeNew":
      validity.isNewConfirmationNotNull = !isInputBlank(value);
      break;
    case "verificationCode":
      validity.isConfirmationNotNull = !isInputBlank(value);
      break;
    case "userId":
      validity.isuserIdNoNotNull = !isInputBlank(value);
      validity.isuserIdNoAlphanumeric = isAlphanumeric(value);
      validity.isuserIdNoLengthBelowLimit = isMaxLengthLessthan(value, 10);
      validity.isuserIdNoMinLength = isMinLength(value, 1);
      validity.isValiduserIdNo = _.every(
        _.pick(validity, [
          "isuserIdNoNotNull",
          "isuserIdNoAlphanumeric",
          "isuserIdNoLengthBelowLimit",
          "isuserIdNoMinLength",
        ])
      );
      break;
    default:
      break;
  }
  return validity;
};

const validateUserRegForm = (field, value, validity) => {
  const emailRegex = new RegExp(
    // /^[a-zA-Z0-9]+([\w\.\+\-\_])*([a-zA-Z0-9])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,}$/
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  switch (field) {
    case "firstName":
      validity.isFirstNameNotNull = !isInputBlank(value);
      validity.isFirstnameLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isFirstnameMinLength = isMinLength(value, 2);
      validity.isFirstNameAlphabetic = hasAlphabetsOnly(value);
      validity.isFirstNameValid = _.every(
        _.pick(validity, [
          "isFirstNameNotNull",
          "isFirstnameLengthBelowLimit",
          "isFirstnameMinLength",
          "isFirstNameAlphabetic",
        ])
      );
      break;
    case "companyName":
      validity.isCompanyNameNotNull = !isInputBlank(value);
      validity.isCompanynameLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isCompanynameMinLength = isMinLength(value, 5);
      validity.isCompanyNameAlphabetic = hasAlphabetsOnly(value);
      validity.isCompanyNameValid = _.every(
        _.pick(validity, [
          "isCompanyNameNotNull",
          "isCompanynameLengthBelowLimit",
          "isCompanynameMinLength",
          "isCompanyNameAlphabetic",
        ])
      );
      break;

    case "address2":
      validity.isAddress2NotNull = !isInputBlank(value);
      validity.isAddress2LengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isAddress2MinLength = isMinLength(value, 5);
      validity.isAddress2Alphabetic = hasAlphabetsOnly(value);
      validity.isAddress2Valid = _.every(
        _.pick(validity, [
          "isAddress2NotNull",
          "isAddress2LengthBelowLimit",
          "isAddress2MinLength",
          "isAddress2Alphabetic",
        ])
      );
      break;
    case "address1":
      validity.isAddress1NotNull = !isInputBlank(value);
      validity.isAddress1LengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isAddress1MinLength = isMinLength(value, 5);
      validity.isAddress1Alphabetic = hasAlphabetsOnly(value);
      validity.isAddress1Valid = _.every(
        _.pick(validity, [
          "isAddress1NotNulll",
          "isAddress1LengthBelowLimit",
          "isAddress1MinLength",
          "isAddress1Alphabetic",
        ])
      );
      break;
    case "city":
      validity.iscityNotNull = !isInputBlank(value);
      validity.iscityLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.iscityMinLength = isMinLength(value, 5);
      validity.iscityAlphabetic = hasAlphabetsOnly(value);
      validity.iscityValid = _.every(
        _.pick(validity, [
          "iscityNotNulll",
          "iscityLengthBelowLimit",
          "iscityMinLength",
          "iscityAlphabetic",
        ])
      );
      break;
    case "zipCode":
      validity.iszipCodeNotNull = !isInputBlank(value);
      validity.iszipCodeLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.iszipCodeMinLength = isMinLength(value, 5);
      validity.iszipCodeisNumeric = isNumeric(value);
      validity.iszipCodeValid = _.every(
        _.pick(validity, [
          "iszipCodeNotNull",
          "iszipCodeLengthBelowLimit",
          "iszipCodeMinLength",
          "iszipCodeisNumeric",
        ])
      );
      break;
    case "state":
      validity.isStateMinLength = isMinLength(value, 2);
      validity.stateValidity = _.every(_.pick(validity, ["isStateMinLength"]));
      break;
    case "lastName":
      validity.isLastNameNotNull = !isInputBlank(value);
      validity.isLastnameLengthBelowLimit = isMaxLengthLessthan(value, 16);
      validity.isLastnameMinLength = isMinLength(value, 2);
      validity.isLastNameAlphabetic = hasAlphabetsOnly(value);
      validity.isLastNameValid = _.every(
        _.pick(validity, [
          "isLastNameNotNull",
          "isLastnameLengthBelowLimit",
          "isLastnameMinLength",
          "isLastNameAlphabetic",
        ])
      );
      break;
    case "xlpAccNo":
      validity.isXlpAccNoNotNull = !isInputBlank(value);
      validity.isXlpAccNoAlphanumeric = isAlphanumeric(value);
      validity.isXlpAccNoLengthBelowLimit = isMaxLengthLessthan(value, 10);
      validity.isXlpAccNoMinLength = isMinLength(value, 1);
      validity.isValidXlpAccNo = _.every(
        _.pick(validity, [
          "isXlpAccNoNotNull",
          "isXlpAccNoAlphanumeric",
          "isXlpAccNoLengthBelowLimit",
          "isXlpAccNoMinLength",
        ])
      );
      break;
    // case 'title':
    //     validity.isValidTitle = !isInputBlank(value)
    //     break;
    case "email":
      validity.isEmailIdNotNull = !isInputBlank(value);
      validity.isEmailIdLengthBelowLimit = isMaxLengthLessthan(value, 50);
      validity.isEmailIdMinLength = isMinLength(value, 7);
      validity.isEmailValidFormat = !_.isEmpty(value)
        ? emailRegex.test(value)
        : true;
      validity.isValidEmail = _.every(
        _.pick(validity, [
          "isEmailIdNotNull",
          "isEmailIdLengthBelowLimit",
          "isEmailIdMinLength",
          "isEmailValidFormat",
        ])
      );
      break;
    case "confirmEmail":
      validity.isConfirmEmailIdNotNull = !isInputBlank(value);
      break;
    case "mobileNo":
      validity.isMobileNoNotNull = !isInputBlank(value);
      validity.isMobileNoLengthBelowLimit = isNumberLessthan(value, 10);
      validity.isMobileNoMinLength = hasNumberMinLength(value, 10);
      validity.isMobileNoNumeric = isNumeric(value);
      validity.isValidMobileNo = _.every(
        _.pick(validity, [
          "isMobileNoNotNull",
          "isMobileNoLengthBelowLimit",
          "isMobileNoMinLength",
          "isMobileNoNumeric",
        ])
      );
      break;
    case "pin":
      validity.isPinNotNull = !isInputBlank(value);
      validity.ispinLengthBelowLimit = isNumberLessthan(value, 4);
      validity.isPinMinLength = hasNumberMinLength(value, 4);
      validity.isPinNoNumeric = isNumeric(value);
      validity.isValidpin = _.every(
        _.pick(validity, [
          "isPinNotNull",
          "ispinLengthBelowLimit",
          "isPinMinLength",
          "isPinNoNumeric",
        ])
      );
      break;

    case "store":
      validity.isStoreNotNull = !isInputBlank(value);
      validity.isStoreLengthBelowLimit = isNumberLessthan(value, 4);
      validity.isStoreMinLength = hasNumberMinLength(value, 4);
      validity.isStoreNoNumeric = isNumeric(value);
      validity.isValidstore = _.every(
        _.pick(validity, [
          "isPinNotNull",
          "isStoreLengthBelowLimit",
          "isStoreMinLength",
          "isStoreNoNumeric",
        ])
      );
      break;
    case "locationsSelected":
      validity.isLocationNotNull = !_isEmpty(value);
      break;
    case "userType":
      validity.isUserTypeNotNull = !isInputBlank(value);
      break;
    case "DefaultlocationSelected":
      validity.isDefaultlocationSelected = !isInputBlank(value);
      break;
    case "userId":
      validity.isuserIdNoNotNull = !isInputBlank(value);
      validity.isuserIdNoAlphanumeric = isAlphanumeric(value);
      validity.isuserIdNoLengthBelowLimit = isMaxLengthLessthan(value, 10);
      validity.isuserIdNoMinLength = isMinLength(value, 1);
      validity.isValiduserIdNo = _.every(
        _.pick(validity, [
          "isuserIdNoNotNull",
          "isuserIdNoAlphanumeric",
          "isuserIdNoLengthBelowLimit",
          "isuserIdNoMinLength",
        ])
      );
      break;
    default:
      break;
  }
  return validity;
};

const validateSearchWrapper = (field, value, validity) => {
  switch (field) {
    case "year":
      validity.isYearLengthBelowLimit = isNumberLessthan(value, 4);
      validity.isYearMinLength = hasNumberMinLength(value, 1);
      validity.isYearNumeric = isNumeric(value);
      validity.isYearValid = _.every(
        _.pick(validity, [
          "isYearNotNull",
          "isYearLengthBelowLimit",
          "isYearMinLength",
          "isYearNumeric",
        ])
      );
      break;
    case "make":
      validity.isMakeLengthBelowLimit = isNumberLessthan(value, 25);
      validity.isMakeMinLength = hasMinLength(value, 1);
      validity.isMakeAlphabetic = hasAlphabetHyphen(value);
      validity.isMakeValid = _.every(
        _.pick(validity, [
          "isMakeNotNull",
          "isMakeLengthBelowLimit",
          "isMakeMinLength",
          "isMakeAlphabetic",
        ])
      );
      break;
    case "model":
      validity.isModelAlphaSpecialChar = hasAlphaSpecialIntChar(value);
      validity.isModelLengthBelowLimit = isNumberLessthan(value, 15);
      validity.isModelMinLength = hasMinLength(value, 1);
      validity.isModelValid = _.every(
        _.pick(validity, [
          "isModelNotNull",
          "isModelAlphaSpecialChar",
          "isModelLengthBelowLimit",
          "isModelMinLength",
        ])
      );
      break;
    case "engine":
      validity.isEngineAlphaSpecialChar = hasAlphaSpecialIntChar(value);
      validity.isEngineLengthBelowLimit = isNumberLessthan(value, 15);
      validity.isEngineMinLength = hasMinLength(value, 1);
      validity.isEngineValid = _.every(
        _.pick(validity, [
          "isEngineNotNull",
          "isEngineAlphaSpecialChar",
          "isEngineLengthBelowLimit",
          "isEngineMinLength",
        ])
      );
      break;
    default:
      break;
  }
  return validity;
};

const validateState = (field, value, stateValidity) => {
  switch (field) {
    case "state":
      stateValidity.isStateAlphanumeric = isAlphanumeric(value);
      stateValidity.isStateMinLength = hasMinLength(value, 2);
      break;
    default:
      break;
  }
  return stateValidity;
};

const populateUserDropDownOptions = (listData) => {
  if (!_.isEmpty(listData)) {
    return getDropDownOptionFromApiData(
      listData,
      (item) => item.shipToId,
      (item) => `${item.shipToId} ${item.address}`,
      (item) => false,
      (item) => item.shipToId
    );
  }
  return null;
};

export const distributeStoreAvailability = (single, sqty) => {
  let storeArr = [];
  single.storeAvailabilities.map((store) => {
    if (store.storeQty < sqty && sqty !== 0) {
      storeArr.push({
        store: store.store,
        storeQty: store.storeQty,
      });
      sqty = sqty - store.storeQty;
    } else if (store.storeQty >= sqty && sqty !== 0) {
      storeArr.push({
        store: store.store,
        storeQty: sqty,
      });
      sqty = 0;
    }
  });
  return { storeArr, sqty };
};
const handleKeyDownReturnsLineItems = (event) => {
  const qtyvalue = event.target.value;
  if (
    (isAlphanumericHyphenwithdotAndForwardSlash(event.key) === false ||
      event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};
const distributeAvailability = (orderQty, availability) => {
  let ordArr = [];
  let qty = orderQty;
  let storeQty = orderQty;
  if (Number(qty) >= 0) {
    availability.map((single) => {
      if (qty !== 0) {
        if (single.availableQty < qty) {
          let { storeArr, sqty } = distributeStoreAvailability(
            single,
            storeQty
          );
          storeQty = sqty;
          ordArr.push({
            orderQty: single.availableQty,
            availableDuration: single.availableDuration,
            availableQty: single.availableQty,
            storeAvailability: storeArr,
          });
          qty = qty - single.availableQty;
        } else if (single.availableQty >= qty) {
          let { storeArr, sqty } = distributeStoreAvailability(
            single,
            storeQty
          );
          storeQty = sqty;
          ordArr.push({
            orderQty: qty,
            availableDuration: single.availableDuration,
            availableQty: single.availableQty,
            storeAvailability: storeArr,
          });
          qty = 0;
        }
      }
    });
  }
  return ordArr;
};

// const distributeAvailability = (orderQty, availability) => {
//   let ordArr = [];
//   let qty = orderQty;
//   if (Number(qty) >= 0) {
//     availability.map((single) => {
//       if (qty !== 0) {
//         if (single.availableQty < qty) {
//           ordArr.push({
//             orderQty: single.availableQty,
//             availableDuration: single.availableDuration,
//             availableQty: single.availableQty,
//           });
//           qty = qty - single.availableQty;
//         } else if (single.availableQty >= qty) {
//           ordArr.push({
//             orderQty: qty,
//             availableDuration: single.availableDuration,
//             availableQty: single.availableQty,
//           });
//           qty = 0;
//         }
//       }
//     });
//   }
//   return ordArr;
// };

// for distributing availability in defferent stores (TPH)
const distributeAvailabilityByStore = (orderQty, availability) => {
  let ordArr = [];
  let qty = orderQty;
  if (Number(qty) >= 0) {
    availability.map((single) => {
      if (qty !== 0) {
        if (single.availableQty) {
          if (single.availableQty < qty) {
            ordArr.push({
              isStoreAvailability: false,
              orderQty: single.availableQty,
              availableDuration: single.availableDuration,
              availableQty: single.availableQty,
              store: null,
            });
            qty = qty - single.availableQty;
          } else if (single.availableQty >= qty) {
            ordArr.push({
              isStoreAvailability: false,
              orderQty: qty,
              availableDuration: single.availableDuration,
              availableQty: single.availableQty,
              store: null,
            });
            qty = 0;
          }
        } else {
          if (single.storeQty < qty) {
            ordArr.push({
              isStoreAvailability: true,
              orderQty: single.storeQty,
              availableDuration: single.storeTime,
              availableQty: single.storeQty,
              store: single.store,
            });
            qty = qty - single.storeQty;
          } else if (single.storeQty >= qty) {
            ordArr.push({
              isStoreAvailability: true,
              orderQty: qty,
              availableDuration: single.storeTime,
              availableQty: single.storeQty,
              store: single.store,
            });
            qty = 0;
          }
        }
      }
    });
  }
  return ordArr;
};

const constructReqItemsForGroups = (groups) => {
  let reqItems = [];
  if (!_.isEmpty(groups)) {
    reqItems = groups.map((group) => ({
      groupCode: group.code,
      specificCondition: [],
    }));
  }
  return reqItems;
};

const getRequestPayloadForOrchestratorAPI = (
  selectedGroups,
  catalogRequestGroups = [],
  specificConditions = []
) => {
  if (
    catalogRequestGroups &&
    catalogRequestGroups.requestItems &&
    catalogRequestGroups.requestItems.length > 0
  ) {
    return selectedGroups.map((sg) => {
      let specificCondition = [];
      specificConditions.forEach((sc) => {
        sc.specificCondition.forEach((psc) => {
          catalogRequestGroups.requestItems.forEach((cr) => {
            cr.specificCondition.forEach((_sc) => {
              if (_sc.code === psc.code) {
                specificCondition = [{ code: psc.code, answer: _sc.answer }];
              }
            });
          });
        });
      });

      return { groupCode: sg.code, specificCondition };
    });
  } else {
    return selectedGroups.map((group) => {
      return {
        groupCode: group.code,
        specificCondition: [],
      };
    });
  }
};

const constructReqItemsForCategories = (categories) => {
  let requestItems = [];
  if (!_.isEmpty(categories)) {
    categories.map((category) => {
      let arr = constructReqItemsForGroups(category.groups);
      requestItems = [...requestItems, ...arr];
    });
  }
  return requestItems;
};

const getDefaultDataBasedOnUSerPref = (userDetails) => {
  let catalogSortBy = "partType";
  let catalogOrder = "ASC";
  let pageSize = 25;
  let pageNumber = 1;
  let currentSort = "Part type (A to Z)";
  let filter = {};
  if (!_isEmpty(userDetails)) {
    let selectedViewType = {};
    selectedViewType = _find(userDetails, {
      code: "Parts Catalog",
      text: "Sort By",
    });
    let possibleAnswerForPartSort =
      selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
    let defaultPartSort = possibleAnswerForPartSort.find((v) => v.default);
    let pageSizeView = {};
    pageSizeView = _find(userDetails, {
      code: "Product Information",
      text: "Maximum items to display per page",
    });
    let availabilityFilterView = {};
    availabilityFilterView = _find(userDetails, {
      code: "Product Information",
      text: "Stock Availability",
    });
    let availFilter = [];
    const availFilterView =
      availabilityFilterView &&
      availabilityFilterView.answer &&
      availabilityFilterView.answer.code &&
      availabilityFilterView.answer.code;
    availFilter = availFilterView ? [availFilterView] : [];
    pageSize =
      (pageSizeView &&
        pageSizeView.answer &&
        pageSizeView.answer.code &&
        pageSizeView.answer.code) ||
      pageSize;
    let sortDetails =
      selectedViewType &&
      selectedViewType.answer &&
      selectedViewType.answer.code &&
      selectedViewType.answer.code.split(" ");
    catalogSortBy =
      (sortDetails && sortDetails.length > 0 && sortDetails[0]) ||
      catalogSortBy;
    catalogOrder =
      (sortDetails && sortDetails.length > 0 && sortDetails[1]) || catalogOrder;
    currentSort =
      (selectedViewType &&
        selectedViewType.answer &&
        selectedViewType.answer.text &&
        selectedViewType.answer.text) ||
      (defaultPartSort && defaultPartSort.text && defaultPartSort.text) ||
      currentSort;
    catalogSortBy =
      (selectedViewType &&
        selectedViewType.answer &&
        selectedViewType.answer.code &&
        selectedViewType.answer.code) ||
      (defaultPartSort && defaultPartSort.code && defaultPartSort.code) ||
      catalogSortBy;
    filter =
      availFilter && availFilter.length > 0 && !availFilter.includes("all")
        ? availFilter
        : filter;
  }
  return {
    pageNumber,
    pageSize,
    catalogSortBy,
    catalogOrder,
    currentSort,
    filter,
  };
};

const getDefaultDataBasedOnChemicalUSerPref = (userDetails) => {
  let catalogSortBy = "partType";
  let catalogOrder = "ASC";
  let pageSize = 25;
  let pageNumber = 1;
  let currentSort = "Part type (A to Z)";
  let filter = [];
  if (!_isEmpty(userDetails)) {
    let selectedViewType = {};
    selectedViewType = _find(userDetails, {
      code: "Chemicals Catalog",
      text: "Sort By",
    });
    let possibleAnswerForChemicalSort =
      selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
    let defaultChemicalSort = possibleAnswerForChemicalSort.find(
      (v) => v.default
    );
    let pageSizeView = {};
    pageSizeView = _find(userDetails, {
      code: "Product Information",
      text: "Maximum items to display per page",
    });

    let availabilityFilterView = {};
    availabilityFilterView = _find(userDetails, {
      code: "Product Information",
      text: "Stock Availability",
    });
    let availFilter = [];
    const availFilterView =
      availabilityFilterView &&
      availabilityFilterView.answer &&
      availabilityFilterView.answer.code &&
      availabilityFilterView.answer.code;
    availFilter = availFilterView ? [availFilterView] : availFilter;

    pageSize =
      (pageSizeView &&
        pageSizeView.answer &&
        pageSizeView.answer.code &&
        pageSizeView.answer.code) ||
      pageSize;
    let sortDetails =
      selectedViewType &&
      selectedViewType.answer &&
      selectedViewType.answer.code &&
      selectedViewType.answer.code.split(" ");
    catalogSortBy =
      (sortDetails && sortDetails.length > 0 && sortDetails[0]) ||
      (
        defaultChemicalSort &&
        defaultChemicalSort.code &&
        defaultChemicalSort.code
      ).split(" ")[0] ||
      catalogSortBy;
    catalogOrder =
      (sortDetails && sortDetails.length > 0 && sortDetails[1]) ||
      (
        defaultChemicalSort &&
        defaultChemicalSort.code &&
        defaultChemicalSort.code
      ).split(" ")[1] ||
      catalogOrder;
    currentSort =
      (selectedViewType &&
        selectedViewType.answer &&
        selectedViewType.answer.text &&
        selectedViewType.answer.text) ||
      (defaultChemicalSort &&
        defaultChemicalSort.text &&
        defaultChemicalSort.text) ||
      currentSort;
    filter = availFilter && !availFilter.includes("all") ? availFilter : filter;
  }
  return {
    pageNumber,
    pageSize,
    catalogSortBy,
    catalogOrder,
    currentSort,
    filter,
  };
};

export const timeInUTC = () => {
  return moment.utc().format();
};

export const timeInCST = () => {
  var today = new Date(),
    time = today.toLocaleDateString() + "\n\n" + today.toLocaleTimeString();
  return time;
};

export const SupplierRegion = (userDetails, storeDetails) => {
  let location = userDetails?.shipTos?.[0]?.location;
  let stores = {
    defaultStore: {
      sellerPartnerId: location?.sellerPartnerId,
      storeNumber: location?.storeNumber,
      storeName: location?.storeName,
    },
    pickupStores: storeDetails?.map(
      ({ storeNum: storeNumber, sellerPartID: sellerPartnerId, ...rest }) => ({
        storeNumber,
        sellerPartnerId,
        ...rest,
      })
    ),
    deliveryStores: [],
  };
  let isTrue = getSellerInfo(userDetails);
  return { isTrue, stores };
};

const getDefaultPreference = (code, text) => {
  let preferenceData = getPreferenceDataFromStorage();
  let selectedViewType = _find(preferenceData, {
    code: code,
    text: text,
  });
  let defaultAnswer =
    (selectedViewType &&
      selectedViewType.answer &&
      selectedViewType.answer.code &&
      selectedViewType.answer.code) ||
    null;
  let possibleAnswerData =
    selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
  defaultAnswer =
    defaultAnswer == null
      ? possibleAnswerData && possibleAnswerData.find((v) => v.default).code
      : defaultAnswer;
  return (defaultAnswer && defaultAnswer) || null;
};

const getPreferenceDataFromStorage = () => {
  const userPrefereces = storage.get("userPrefereces", "local") || "{}";
  return JSON.parse(userPrefereces);
};

const updateUserPreferenceToStore = (data) => {
  storage.set("userPrefereces", data, "local");
};

const formatTimeAvb = (time) => {
  let timeArr = time.split(":");
  if (timeArr.length > 1) {
    return Number(_head(timeArr)) !== 0
      ? `${Number(_head(timeArr))} Hours`
      : `${Number(timeArr[1])} Minutes`;
  } else {
    return time;
  }
};

const isNonHomePage = (pathname) => {
  if (!_isEmpty(window.location.pathname)) {
    if (pathname === "/tracking-order") {
      return false;
    } else if (pathname === "/manager-dashboard") {
      return false;
    } else if (pathname === "/store-user-dashboard") {
      return false;
    } else if (pathname === "/store-management") {
      return false;
    }
    return true;
  }
};
const getDeviceInfo = () => {
  var module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = "",
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };

  return module.init();
};

const getstoreName = (allStores, cartStore, cartShipMethod, userDetails) => {
  let storeData =
    cartShipMethod == "0002" || cartShipMethod == "0001"
      ? allStores.pickupStores
      : allStores.deliveryStores;
  let defaultstore = !_isEmpty(allStores.defaultStore)
    ? allStores.defaultStore
    : "";
  let defaultstoreName =
    userDetails.shipTos[0] &&
    userDetails.shipTos[0].location &&
    userDetails.shipTos[0].location.storeName;
  if (defaultstore.storeNumber && defaultstore.storeNumber == cartStore) {
    defaultstoreName = defaultstore.storeName;
  } else if (!_isEmpty(storeData)) {
    storeData.map((v) => {
      if (v.storeNumber == cartStore) {
        defaultstoreName = v.storeName;
      }
    });
  }
  return defaultstoreName;
};

const getDistributionForVehicle = (
  partNum,
  part,
  availability = [],
  totalQty = 0,
  selectedVehicle,
  cart
) => {
  let totalcount = 0;
  let vehicleInfo = `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`;
  let distAvailability = _cloneDeep(availability);
  let existingVehicleQty = 0;
  let existingNonVehicleQty = 0;
  let cartParts = [];
  let selectedpart = _cloneDeep(part);
  selectedpart.mfgCode =
    (part.lineCodes && typeof part.lineCodes === "string" && part.lineCodes) ||
    part.lineCodes?.[0] ||
    part.mfgCode;
  distAvailability.map((avalue) => {
    avalue.availQty = avalue.availableQty;
  });
  let alreadyAddedForVehicle =
    !_isEmpty(cart.checkoutDetails) &&
    cart.checkoutDetails.filter((item) => {
      return (
        item.vehicleInfo.replace(/\s+/g, "") ===
          vehicleInfo.replace(/\s+/g, "") &&
        item.partNum === partNum &&
        item.mfgCode === selectedpart.mfgCode
      );
    });
  let nonVehicleInfo = "Non-Vehicle Specific";
  let alreadyAddedForNonVehicle =
    !_isEmpty(cart.checkoutDetails) &&
    cart.checkoutDetails.filter((item) => {
      return (
        item.vehicleInfo.replace(/\s+/g, "") ===
          nonVehicleInfo.replace(/\s+/g, "") &&
        item.partNum === partNum &&
        item.mfgCode === selectedpart.mfgCode
      );
    });
  alreadyAddedForVehicle.length > 0 &&
    alreadyAddedForVehicle.map((item) => {
      existingVehicleQty += item.qty;
    });
  alreadyAddedForNonVehicle.length > 0 &&
    alreadyAddedForNonVehicle.map((item) => {
      existingNonVehicleQty += item.qty;
    });
  let checkoutDetails = _cloneDeep(
    !_isEmpty(cart.checkoutDetails) &&
      cart.checkoutDetails.filter((item) => {
        return (
          item.vehicleInfo.replace(/\s+/g, "") !==
            vehicleInfo.replace(/\s+/g, "") &&
          item.partNum === partNum &&
          item.mfgCode === selectedpart.mfgCode
        );
      })
  );
  !_isEmpty(checkoutDetails) &&
    checkoutDetails.map((item) => {
      if (item.partNum === partNum && item.mfgCode === selectedpart.mfgCode) {
        let orderAray = distributeAvailability2(item.qty, distAvailability);
        orderAray.map((orderItem) => {
          let cloneItem = _cloneDeep(item);
          cloneItem.availabilityHour = orderItem.availableDuration;
          cloneItem.availableQty = orderItem.availableQty;
          cloneItem.qty = orderItem.orderQty;
          cloneItem.distStatus = "Completed";
          cartParts.push(cloneItem);
        });
        distAvailability = distributeAvailabilityBucket(
          distAvailability,
          item.qty
        ).availability;
      }
    });
  !_isEmpty(cart.checkoutDetails) &&
    cart.checkoutDetails.map((item) => {
      if (item.partNum !== partNum) {
        cartParts.push(item);
      }
    });
  totalcount = Number(totalQty) + existingVehicleQty;
  return {
    alreadyAddedItem: cartParts,
    filteredAvailability: distAvailability,
    totalQty: totalcount,
  };
};

const getDistributionForNonVehicle = (
  partNum,
  part,
  availability = [],
  totalQty = 0,
  cart
) => {
  //let vehicleInfo = `${mygarage.selectedVehicle.year} ${mygarage.selectedVehicle.make} ${mygarage.selectedVehicle.model} ${mygarage.selectedVehicle.engine}`;
  let partInfo = _cloneDeep(part);
  let distAvailability = _cloneDeep(availability);
  let existingVehicleQtyCount = 0;
  let existingNonVehicleCount = 0;
  let cartParts = [];
  let allExistingDistributedData = [];
  distAvailability.map((avalue) => {
    avalue.availQty = avalue.availableQty;
  });
  let vehicleInfo = "Non-Vehicle Specific";
  partInfo.mfgCode =
    partInfo.mfgCode ||
    (partInfo.brands && partInfo.brands[0] && partInfo.brands[0].code);
  let alreadyAddedForNonVehicle = cart.checkoutDetails.filter((item) => {
    return (
      item.vehicleInfo.replace(/\s+/g, "") ===
        vehicleInfo.replace(/\s+/g, "") &&
      item.partNum === partNum &&
      (item.mfgCode === partInfo.mfgCode || partInfo.mfgCode === undefined)
    );
  });
  let alreadyAddedForVehicle = cart.checkoutDetails.filter((item) => {
    return (
      item.vehicleInfo.replace(/\s+/g, "") !==
        vehicleInfo.replace(/\s+/g, "") &&
      item.partNum === partNum &&
      (partInfo.mfgCode === undefined || item.mfgCode === partInfo.mfgCode)
    );
  });
  alreadyAddedForVehicle.length > 0 &&
    alreadyAddedForVehicle.map((item) => {
      existingVehicleQtyCount += item.qty;
    });
  alreadyAddedForNonVehicle.length > 0 &&
    alreadyAddedForNonVehicle.map((item) => {
      existingNonVehicleCount += item.qty;
    });
  let checkoutDetails = _cloneDeep(
    cart.checkoutDetails.filter((item) => {
      return (
        item.partNum !== partNum ||
        (item.partNum === partNum &&
          partInfo.mfgCode !== undefined &&
          item.mfgCode !== partInfo.mfgCode)
      );
    })
  );
  allExistingDistributedData.push(...alreadyAddedForVehicle);
  allExistingDistributedData.map((item) => {
    if (
      item.partNum === partNum &&
      (item.mfgCode === partInfo.mfgCode || partInfo.mfgCode === undefined)
    ) {
      let orderAray = distributeAvailability2(item.qty, distAvailability);
      orderAray.map((orderItem) => {
        let cloneItem = _cloneDeep(item);
        cloneItem.availabilityHour = orderItem.availableDuration;
        cloneItem.availableQty = orderItem.availableQty;
        cloneItem.distStatus = "Completed";
        cloneItem.qty = orderItem.orderQty;
        cartParts.push(cloneItem);
      });
      distAvailability = distributeAvailabilityBucket(
        distAvailability,
        item.qty
      ).availability;
    }
  });
  cartParts.push(...checkoutDetails);
  totalQty = Number(totalQty) + Number(existingNonVehicleCount);
  return {
    alreadyAddedItem: cartParts,
    filteredAvailability: distAvailability,
    totalQty: totalQty,
  };
};

const distributeAvailabilityBucket = (distAvailability, qty) => {
  distAvailability.length > 0 &&
    distAvailability.map((single) => {
      if (qty !== 0) {
        if (single.availQty < qty) {
          qty = qty - single.availQty;
          single.availQty = 0;
        } else if (single.availQty >= qty) {
          single.availQty = single.availQty - qty;
          qty = 0;
        }
      }
    });
  return {
    availability: distAvailability.filter((item) => {
      return item.availQty > 0;
    }),
  };
};

const hidePriceUsingUserType = (userType) => {
  switch (userType) {
    case "VISITOR":
      return true;
    default:
      break;
  }
};
const closeMitchelApp = () => {
  const apiKey = "apikey-12345-key";
  const catalogSdk = new Catalog(apiKey, false);
  catalogSdk.cancelRequest();
};

const returnHandleKeyDown = (event) => {
  const qtyvalue = event.target.value;
  if (
    (returnNumbersOnlyRegExp(event.key) === false ||
      (qtyvalue && qtyvalue.length >= 10) ||
      event.keyCode === 32) &&
    handleOtherKeys(event.keyCode)
  ) {
    event.preventDefault();
  }
};

const distributeAvailability2 = (orderQty, availability) => {
  let ordArr = [];
  let qty = orderQty;
  if (Number(qty) >= 0) {
    availability.map((single) => {
      if (qty !== 0) {
        if (single.availQty < qty) {
          ordArr.push({
            orderQty: single.availQty,
            availableDuration: single.availableDuration,
            availableQty: single.availableQty,
          });
          qty = qty - single.availQty;
        } else if (single.availQty >= qty) {
          ordArr.push({
            orderQty: qty,
            availableDuration: single.availableDuration,
            availableQty: single.availableQty,
          });
          qty = 0;
        }
      }
    });
  }
  ordArr = _filter(ordArr, (b) => b.orderQty > 0);
  return ordArr;
};

const getUserShipInfo = (userDetails) => {
  let { shipTos } = userDetails;
  let shipMethod =
    shipTos && shipTos[0] && shipTos[0].shipMethod && shipTos[0].shipMethod
      ? shipTos[0].shipMethod && shipTos[0].shipMethod
      : "";
  let storeNumber =
    shipTos &&
    shipTos[0] &&
    shipTos[0].location &&
    shipTos[0].location.storeNumber &&
    shipTos[0].location.storeNumber
      ? shipTos[0].location.storeNumber
      : "";
  let sellerPartnerId =
    shipTos &&
    shipTos[0] &&
    shipTos[0].location &&
    shipTos[0].location.sellerPartnerId &&
    shipTos[0].location.sellerPartnerId
      ? shipTos[0].location.sellerPartnerId
      : "";
  return {
    shipMethod: shipMethod,
    storeNumber: storeNumber,
    sellerPartnerId: sellerPartnerId,
  };
};

const getSellerInfo = (userDetails) => {
  let isBuckeNameDisplay = false;
  let customerNumber = userDetails.xlPartAccountNumber
    ? userDetails.xlPartAccountNumber
    : "";
  let sellerNetwork = userDetails?.shipTos?.[0].location?.sellerNetwork;
  let preferenceCustomerList = [];
  const userPreferenceDetail = getPreferenceDataFromStorage();
  let peferenceSeller = {};
  if (!_isEmpty(userPreferenceDetail)) {
    peferenceSeller = _find(userPreferenceDetail, {
      code: "User Management",
      text: "pilot-sellerID",
    });
  }
  if (!_isEmpty(peferenceSeller) && !_isEmpty(sellerNetwork)) {
    if (!_isEmpty(peferenceSeller.possibleAnswer)) {
      let isAvailable = peferenceSeller.possibleAnswer.find((item) => {
        if(item.code === sellerNetwork) {
          isBuckeNameDisplay = true;
        }
      });
      if (!_isEmpty(isAvailable)) {
        preferenceCustomerList = isAvailable.text.split(";");
      }
    }
    if (
      preferenceCustomerList.some((x) => x === customerNumber) ||
      preferenceCustomerList.includes("all")
    ) {
      isBuckeNameDisplay = true;
    }
  }
  isBuckeNameDisplay =  isPuertorico(userDetails, isBuckeNameDisplay);
  return isBuckeNameDisplay;
};

const setDefalutProductQuantity = () => {
  const userPreferenceDetail = getPreferenceDataFromStorage();
  let selectedProductQty = {};
  if (!_isEmpty(userPreferenceDetail)) {
    selectedProductQty = _find(userPreferenceDetail, {
      code: "Product Information",
      text: "Default Order Qty to Qty Per Vehicle",
    });
  }
  return selectedProductQty &&
    selectedProductQty.answer &&
    selectedProductQty.answer.code === "N"
    ? false
    : true;
};
export const getPartsPreferredBrands = (supplier) => {
  const text = `Preferred Brands-${supplier}`;
  const userPref = getPreferenceDataFromStorage();
  if (!_isEmpty(userPref)) {
    let PartsPrefBrands = null;
    userPref.forEach((pref) => {
      if (
        !_isEmpty(pref) &&
        pref.code === "Parts Catalog" &&
        pref.text === text
      ) {
        PartsPrefBrands = pref;
      }
    });
    return PartsPrefBrands;
  } else {
    return [];
  }
};

export const getChemicalsPreferredBrands = () => {
  const userPref = getPreferenceDataFromStorage();
  let chemicalsPrefBrands = [];
  userPref.forEach((pref) => {
    if (pref.code === "Chemicals Catalog" && pref.text === "Preferred Brands") {
      chemicalsPrefBrands = pref;
    }
  });

  return chemicalsPrefBrands;
};

export const getUserPreferenceItem = (itemText, code) => {
  const userPref = getPreferenceDataFromStorage();
  let items = [];
  if (!_isEmpty(userPref)) {
    const item = _.find(userPref, (uf) => uf.text === itemText);

    if (item) {
      const answer = _.find(item.possibleAnswer, (a) => a.code === code);
      if (answer) {
        items = answer.text.split(";");
      }
    }
  }
  return items;
};

export const printLogo = (userDetails) => {
  if (userDetails && userDetails?.billTo?.mclSupplier === "CD") {
    return "/public_images/cd_print_logo.png";
  }
  return "/public_images/xlp_print_logo.png";
};

const isStockOrder = (orderType) => {
  if (orderType === "STOCK_ORDER" || orderType === "stock_order") {
    return true;
  }
  return false;
};

export const SPECIAL_CHARACTERS = [
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
  '"',
  "'",
  "~",
  "`",
  ";",
  ":",
  ">",
  "<",
];
export const currencyFormatter = (num) => {
  const modified =
    num && typeof num === "string"
      ? num.replace("$", "").replace(/,/g, "").trim()
      : num;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(modified || 0);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getYearfromDate = (currentDate) => {
  const year = moment(currentDate).year();

  return year;
};

export const isPuertorico = (userDetails, isBuckeNameDisplay) => {
  let sellerNetwork = userDetails?.shipTos?.[0].location?.sellerNetwork;
  if(sellerNetwork === 'Puertorico') {
    isBuckeNameDisplay = true;
  }
  return isBuckeNameDisplay;
}

export const getNonDetailedBucket = (bucketName) => {

  let isNonDetailedBucket = false;

  if (
     bucketName === "Next Business Day" ||
     bucketName === "Next 2 + Business Days" ||
     bucketName === "Next 2 Weeks" ||
     bucketName === "Next 3 Business Days" ||
     bucketName === "Next 5 Business Days" ||
     bucketName === "null" ||
     bucketName === "Immediately" ||
     bucketName === "Same Day" ||
     bucketName === null
  ) {
     isNonDetailedBucket = true;
    }
  return isNonDetailedBucket

};

export const enableBlackColorBasedOnStorePref = (selectedStore) => {
  let defaultBlack = false;
  let nonMaroonColorStores = {};
  nonMaroonColorStores = getUserPreferenceItem('color-preference', 'store-list');
  let foundStore = nonMaroonColorStores.find((item) => {
    return item === selectedStore;
  });
  if (
    foundStore !== undefined &&
    foundStore === selectedStore
  ) {
    defaultBlack = true;
  } else {
    defaultBlack = false;
  }
  return defaultBlack
}

const getConsolidatedCartData = (cartAllDetails) => {
  let cartConsolidateArr = [];
  if (!_isEmpty(cartAllDetails)) {
    const cartList = cartAllDetails.map((obj) => ({ ...obj }));
    cartList.map((one) => {
      let matched = false;
      if (_isEmpty(cartConsolidateArr)) {
        cartConsolidateArr.push(one);
      } else {
        matched = cartConsolidateArr.findIndex((two, index) => {
          return (
            two.partNum === one.partNum &&
            two.mfgCode === one.mfgCode &&
            two.desc === one.desc
          );
        });
        if (matched >= 0) {
          cartConsolidateArr[matched].qty =
            cartConsolidateArr[matched].qty + one.qty;
        } else {
          cartConsolidateArr.push(one);
        }
      }
    });
  }
  return cartConsolidateArr;
}
export const convertValue = (val) => {
  return val > 99 ? `$${Number(val / 1000).toFixed(2)}k` : `$${val}`;
};

// user defined constants for Programs & Benefits:
export const USERDEF = {
  ACD_GM_PARTNER_PERKS: "userDef8",
  MTA_PSN: "userDef7",
  PRONTO: "userDef9",
};

export const formatAmountProgramsPoints = (amt) => {
  return Number(amt).toLocaleString("en-US");
};

export const formatMonth = (monthvalue) => {
  const monthNames = [
    "monthStart",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let valueArray = monthvalue.split("-");
  return monthNames[Number(valueArray[0])] + "-" + valueArray[1];
};

export const getMonth = (monthvalue) => {
  const monthNames = [
    "monthStart",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let valueArray = monthvalue.split("-");
  return monthNames[Number(valueArray[0])];
};

export const getMonthDaysLeft = () => {
  //returns number of days left in month
  let date = new Date();
  return (
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() -
    date.getDate()
  );
};

export const renderInvoiceLink = ({
  invoiceDate,
  customerNumber,
  invoiceNumber,
}) => {
  const year = getYearfromDate(invoiceDate);
  const oneFlowLink = `https://oneflow.xlparts.com/XLP-SE/WebDoc.php?ACTION=get_trans_doc&CAB_NAME=AR_VISION_${year}&DOC=INV&CUSTNUM=${customerNumber}&INV=${invoiceNumber}`;

  return oneFlowLink;
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export const getCategoryFromGroupId = (groupId, categoryGroupMap) => {
  const _category = _.find(categoryGroupMap, c => {
     return _.find(c.groups, g => g.code === Number(groupId))
   });

   const _group = _.find(_category.groups, g => g.code === Number(groupId));

   const category = Object.assign({}, _category);
   const group = Object.assign({}, _group);

   return {category, group};
 }
 export const vehicleSelectPrompt = (isSelected, dispatch, isVehicleSelected, triggerCustomUxCriticalError) => {
  if (_isEmpty(isVehicleSelected) && isSelected) {
    let errorMessageProps = {
      heading: 'Please select a vehicle from YMME search or select a vehicle from My Garage, or select a vehicle from search',
      message: "",
      type: "info",
    };
    triggerCustomUxCriticalError(errorMessageProps, dispatch);
    return;
  }
}

export const getTotalPriceForCNK = (price, candkCheckoutDetails) => {
  let totalExtendedPrice = 0;
  if (candkCheckoutDetails && candkCheckoutDetails.length > 0) {
    totalExtendedPrice = candkCheckoutDetails.reduce((total, item) => {
      // Parse the string returned by getExtendedTotalPrice to a float
      return total + parseFloat(getExtendedTotalPrice(item.corePrice, item.costPrice, item.qty));
    }, 0);
  }
  let totalPrice = price + totalExtendedPrice;
  return totalPrice;
 // return currencyFormatter(totalPrice.toFixed(2)); 
};

export const getCnKPartsSum = (currentPrice, partsPrice) => {
  const total = parseFloat(currentPrice) + parseFloat(partsPrice);
  return currencyFormatter(total.toFixed(2));
};


export const createAvailabilityObject = (item = {}, pricingAvailability, partDetails, selectedVehicle, selectedStore, sellPartnerId, shipMethod, qty = 0, categoryId) => {
  // Find the category ID based on the partDetails group number
  // const categoryId = Number(
  //   _find(categoryGroupMap, (c) => {
  //     return _includes(_map(c.groups, "code"), Number(partDetails.groupNumber));
  //   }).code
  // );

  // Create and return the availability object
  return {
    availabilityHour: item.availableDuration || "",
    availableQty: item.availableQty || "",
    cartFlag: "no",
    categoryId: categoryId,
    corePrice: pricingAvailability.corePrice,
    coreListPrice: pricingAvailability.coreListPrice,
    costPrice: pricingAvailability.costPrice,
    desc: partDetails.description,
    descId: partDetails.descriptionID,
    engineId: selectedVehicle.engineId,
    engine: selectedVehicle.engine,
    groupId: Number(partDetails.groupNumber),
    listPrice: pricingAvailability.listPrice,
    makeId: selectedVehicle.makeId,
    make: selectedVehicle.make,
    modelId: selectedVehicle.modelId,
    model: selectedVehicle.model,
    mfgCode: partDetails.lineCodes ? partDetails.lineCodes[0] : null,
    packQty: pricingAvailability.packQty,
    partNum: partDetails.partNumber,
    partType: "Regular",
    qty: item.orderQty || qty,
    qtyBuyInc: pricingAvailability.qtyBuyInc,
    qtyPerVeh: partDetails.perCarQty,
    toolTip: JSON.stringify(pricingAvailability.toolTips),
    vehicleInfo: `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`,
    yearId: selectedVehicle.year,
    store: selectedStore,
    sellPartnerId: sellPartnerId,
    shipMethod: shipMethod,
    distStatus: "Completed",
    orderType: "REGULAR",
    isNew: true
  };
}



export {
  isStockOrder,
  getSellerInfo,
  themeColor,
  handleOtherKeys,
  setDefalutProductQuantity,
  getUserShipInfo,
  getDistributionForVehicle,
  getDistributionForNonVehicle,
  distributeAvailability2,
  displayQtyChangeMsg,
  getPartPackQty,
  getExtendedTotalPrice,
  getDefaultPreference,
  getUserDefaultShipInfo,
  getstoreName,
  getShipInfo,
  deliveryPickupWarning,
  isMetabaseTokenRefreshRequired,
  getMetabaseEmbedURL,
  updateUserPreferenceToStore,
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnChemicalUSerPref,
  getDefaultDataBasedOnUSerPref,
  constructReqItemsForCategories,
  constructReqItemsForGroups,
  distributeAvailability,
  distributeAvailabilityByStore,
  populateAvailabilityOptions,
  formatAmount,
  buildQueryUrl,
  populateSearchDropdownOptions,
  populateUserDropDownOptions,
  isInputBlank,
  isValidPositiveNumber,
  isValidAvailableQty,
  validateOnlineIdForm,
  isNonHomePage,
  isNumeric,
  isLengthLessthan,
  hasMinLength,
  isAlphanumeric,
  hasSpecialChars,
  hasWhiteSpace,
  validateUserRegForm,
  validateSearchWrapper,
  hasNumericDecimal,
  hasNumericSpecialIntChar,
  hasAlphaSpecialIntChar,
  hasQuoteAlphaSpecialIntChar,
  isJobNameAlphanumeric,
  validateState,
  hasNumberMinLength,
  isNumberLessthan,
  isMaxLengthLessthan,
  isMinLength,
  numbersOnlyRegExp,
  alphaCharRegExp,
  noSpecialCharsRegExp,
  decimalNumbersOnlyRegExp,
  formatTimeAvb,
  hidePriceUsingUserType,
  getRequestPayloadForOrchestratorAPI,
  getDeviceInfo,
  getImageURLCookieDetails,
  isImageURLCookieValid,
  setImageURLCookie,
  isTokenRefreshRequired,
  showReleaseNotes,
  returnHandleKeyDown,
  hideReleaseNotes,
  isSmsUser,
  setSMSUserSessionDetails,
  closeMitchelApp,
  formatAmountPrograms,
  handleKeyDownReturnsLineItems,
  getConsolidatedCartData,
  getCartShipDetails,
};
