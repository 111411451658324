export const openInNewTab = (link: any) =>
  window.open(
    link,
    "_blank",
    "width=largeImage.stylewidth,height=largeImage.style.height,resizable=1"
  );

export const createNestedStructure = (data: any[]) => {
  if (data?.length === 0) return {};

  const rootPath = data?.[0]?.fullpath?.split("/")?.filter(Boolean)[0] || "";
  const root = { ContentTitle: rootPath, children: [] };

  data?.forEach((item) => {
    const parts = item?.fullpath?.split("/")?.filter(Boolean) || [];
    parts?.pop();
    let currentLevel = root;
    parts?.slice(1)?.forEach((part: any) => {
      let existingNode: any = currentLevel.children.find(
        (child) => child?.ContentTitle === part
      );
      if (!existingNode) {
        existingNode = {
          ContentTitle: part,
          children: [],
          parent: currentLevel,
        };
        currentLevel.children.push(existingNode);
      }
      currentLevel = existingNode;
    });

    currentLevel.children.push({
      ...item,
      link: `${item.fullpath}${item.VideoLink?.path}`,
      children: [],
      parent: currentLevel, // Store reference to parent
    });
  });

  const sortChildren = (node: any) => {
    if (node.children && node.children.length > 0) {
      node.children.sort((a: any, b: any) =>
        a.ContentTitle.localeCompare(b.ContentTitle)
      );
      node.children.forEach((child: any) => sortChildren(child)); // Sort recursively
    }
  };

  sortChildren(root);

  return root;
};
