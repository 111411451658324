import React from "react";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import { PaginationItem } from "@mui/material";

interface PaginationProps {
  changeDataState: (newState: Partial<any>) => void;
  paginationData: any;
  dataState?: any;
}

const PaginationPage: React.FC<PaginationProps> = ({
  paginationData,
  dataState,
  changeDataState,
}) => {
  return (
    <>
      <Pagination
        count={paginationData?.totalPages}
        showFirstButton
        showLastButton
        page={dataState?.page}
        onChange={(event: any, value: any) => changeDataState({ page: value })}
        color="primary"
        // renderItem={(item: any) => {
        //   if (item.type === "previous") {
        //     return (
        //       <>
        //         <Button
        //           disabled={dataState?.page === 1}
        //           onClick={() => changeDataState({ page: 1 })}
        //         >
        //           First
        //         </Button>
        //         <Button
        //           disabled={dataState?.page === 1}
        //           onClick={() => changeDataState({ page: dataState?.page - 1 })}
        //         >
        //           Previous
        //         </Button>
        //       </>
        //     );
        //   }
        //   if (item.type === "next") {
        //     return (
        //       <>
        //         <Button
        //           disabled={dataState?.page === paginationData?.totalPages}
        //           onClick={() => changeDataState({ page: dataState?.page + 1 })}
        //         >
        //           Next
        //         </Button>
        //         <Button
        //           disabled={dataState?.page === paginationData?.totalPages}
        //           onClick={() =>
        //             changeDataState({ page: paginationData?.totalPages })
        //           }
        //         >
        //           Last
        //         </Button>
        //       </>
        //     );
        //   }
        //   return (
        //     <PaginationItem
        //       classes={{ selected: "page-selected", root: "page" }}
        //       {...item}
        //     />
        //   );
        // }}
      />
    </>
  );
};

export default PaginationPage;
