import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

interface InputProps {
    dataState?: any;
    changeDataState: (newState: Partial<any>) => void;
}

const NavbarIconUI: React.FC<InputProps> = ({ dataState, changeDataState }) => {

    return (
        <Tooltip title={`${dataState?.menuShowStatus ? "Hide" : "Show"} NavBar`}>
            <FontAwesomeIcon
                onClick={() => changeDataState({ menuShowStatus: !dataState?.menuShowStatus })}
                className="h-4 w-4 text-primaryTheme"
                color={dataState?.menuShowStatus ? "#9d1d20" : "black"}
                icon={faList}
            />
        </Tooltip>
    )
}

export default NavbarIconUI